import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Evaluation from "./components/Evaluation";
import Services from "./components/Services";
import Contact from "./components/Contact";
import { AppContext } from "./components/AppContext";
import firebase from "./config/fbConfig";
import Loading from "./components/Loading";
import "./App.css";
import About from "./components/About";
import Admin from "./components/Admin";
import WebForm from "./components/WebForm";
import EditWebForms from "./components/EditWebForms";
import MentalBudget from "./components/MentalBudget";
import MentalBudgetEN from "./components/MentalBudgetEN";
import GPI from "./components/GPI";
import JPI from "./components/JPI";
import Egenskapsmatis from "./components/Egenskapsmatris";
import EgenskapsmatisEN from "./components/EgenskapsmatrisEN";
import Preloader from "./components/Preloader";

import tempdoc from "./components/TempDoc";

function App() {
  const [languageSE, setLanguageSE] = useState(window.location.href.includes("strong") ? false : true);
  var [loading, setLoading] = useState(true);
  var [pageContent, setPageContent] = useState({});
  var [pageContentSE, setPageContentSE] = useState({});
  var [pageContentEN, setPageContentEN] = useState({});
  var [signedIn, setSignedIn] = useState(false);
  var [onAdminPage, setOnAdminPage] = useState(false);
  var [adminNewFormId, setAdminNewFormId] = useState("");
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);


  //const firebase = require("firebase");
  const db = firebase.firestore();
  const auth = firebase.auth();

  useEffect(() => {
    

    const unsubscribe = auth.onAuthStateChanged((user) => {
      // detaching the listen
      if (user) {
        //console.log(user);
        setSignedIn(true);
      } else {
        // No user is signed in...code to handle unauthenticated users.
        setSignedIn(false);
      }
    });
    return () => unsubscribe(); // unsubscribing from the listener when the component is unmounting.
  }, []);

  //Loading SE and EN content
  useEffect(() => {
    if (loading) {
      var contentSE = db.collection("contentSE").doc("pageOneTitle");
      var contentEN = db.collection("contentEN").doc("pageOneTitle");
 
      contentSE
        .get()
        .then((text) => {
          var tempSE = text.data();
          setPageContentSE({ ...tempSE });
          if(languageSE){
            setPageContent({ ...tempSE });
          }
           
        })
        .catch((e) => {
          console.log("error: " + e);
        });
        
      contentEN
        .get()
        .then((text) => {
          var tempEN = text.data();
          setPageContentEN({ ...tempEN });
          if(!languageSE){
            setPageContent({ ...tempEN });
          }
          setFirebaseLoaded(true)
        })
        .catch((e) => {
          console.log("error: " + e);
          //document.reload();
        });
    }

  }, []);

  //Updates active language
  useEffect(() => {
    if (!loading) {
      if (languageSE) {
        setPageContent({ ...pageContentSE });
        
      } else {
        setPageContent({ ...pageContentEN });
        
      }
    }
    if(languageSE){
      document.title = "Starka Resultat"
    }else{
      document.title = "Strong Results"
    }
  }, [languageSE]);


  useEffect(()=>{
    if(imagesLoaded && firebaseLoaded){
      setLoading(false);
    }
  },[imagesLoaded, firebaseLoaded])

  /*
  useEffect(() => {
    let url = window.location.href;
    if (url.includes("strong")) {
      setLanguageSE(false);
      console.log("url:", url);
    }
  }, []);
*/
  return (
    <div className="App">
      
      <div>
        <AppContext.Provider
          value={{
            languageSE,
            setLanguageSE,
            db,
            auth,
            pageContent,
            signedIn,
            onAdminPage,
            setOnAdminPage,
            loading,
            adminNewFormId,
            setAdminNewFormId,
          }}
        >           
        <Preloader imagesLoaded={imagesLoaded} setImagesLoaded={setImagesLoaded}/>
          {loading ? (
            <Loading />
          ) : (
            <BrowserRouter>
              {onAdminPage ? "" : <Navbar />}
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/services" component={Services} />
                <Route path="/varvtalsraknaren" component={Evaluation} />
                <Route path="/contact" component={Contact} />
                <Route path="/artiklar" component={Artiklar} />
                <Route path="/om" component={About} />
                <Route exact path="/admin" component={Admin} />
                <Route path="/mentalbudget" component={MentalBudget} />
                <Route path="/en/mentalbudget" component={MentalBudgetEN} />
                <Route path="/egenskapsmatrisen" component={Egenskapsmatis} />
                <Route path="/karaktärsmatrisen" component={Egenskapsmatis} />
                <Route path="/charactermatrix" component={EgenskapsmatisEN} />
                <Route path="/GPI" component={GPI} />
                <Route path="/JPI" component={JPI} />
                <Route path="/tempdoc/:form" component={tempdoc} />
                <Route path="/form/:form" component={WebForm} />
                <Route path="/admin/edit/:form">
                  {signedIn ? <EditWebForms /> : "Access denied"}
                </Route>
                <Route component={Home} />
              </Switch>
            </BrowserRouter>
          )}
        </AppContext.Provider>
      </div>
    </div>
  );
}

export default App;

function Artiklar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <h2>Artiklar</h2>;
}
