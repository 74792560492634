import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "./AppContext";
import "./egenskapsmatris.css";
import logo from "../images/starkaresultat-logo.png";
import logoPrint from "../images/starkaresultat-logo-black.png";

function Egenskapsmatis() {
  const { setOnAdminPage } = useContext(AppContext);
  const [quizDone, setQuizDone] = useState(false);
  const [formName, setFormName] = useState("Mitt namn");
  const [formFieldOne, setFormFieldOne] = useState("Min huvudlinje");
  const [formFieldTwo, setFormFieldTwo] = useState("Min kompletteringsline 1");
  const [formFieldThree, setFormFieldThree] = useState(
    "Min kompletteringsline 2"
  );
  const [formFieldFour, setFormFieldFour] = useState(
    "Min kompletteringsline 3"
  );
  const [formFieldFive, setFormFieldFive] = useState("Min Negativa linje");

  const [formExampleMain, setFromExampleMain] = useState();
  const [formExampleCompOne, setFromExampleCompOne] = useState();
  const [formExampleCompTwo, setFromExampleCompTwo] = useState();
  const [formExampleCompThree, setFromExampleCompThree] = useState();

  const dataListOne = [
    "Ansvarsfull",
    "Kompetent",
    "Energi",
    "Envis",
    "Mod",
    "Omtänksam",
    "Engagemang",
    "Ansvar",
    "Lojal",
    "Entreprenör",
    "Glädje",
    "Tillväxt",
    "Utveckling",
    "Kraftfull",
    "Styrka",
    "Hängiven",
    "Entusiasmerande",
    "Kreativ / Nytänkande / Innovativ",
    "Långsiktig",
    "Lönsamhets fokuserad",
  ];

  const dataListTwo = [
    "Noggrann",
    "Analytisk",
    "Stabil",
    "Modig",
    "Omsorgsfull",
    "Lösningsorienterad",
    "Rättvis",
    "Kapacitet",
    "Kraft",
    "Social",
    "Humanistisk",
    "Kunskap",
    "Flexibel",
    "Effektivitet",
    "Rationell",
    "Snabb",
    "Pålitlig",
    "Lönsamhet",
    "Produktiv",
    "Konkurrenskraftig",
    "Energi",
    "Enkelhet",
  ];

  const dataListThree = [
    "Otålighet",
    "Envis",
    "Okänslig",
    "Kontrollbehov",
    "Chanstagare",
    "Noggrannhet",
    "Saktfärdig",
    "Förvaltande",
    "Misstänksam",
    "Obeslutsam",
  ];

  useEffect(() => {
    //window.scrollTo(0, 0);
    setOnAdminPage(true);
  }, []);

  function handleChange(e, setfunction) {
    setfunction(e.target.value);
  }

  useEffect(() => {
    if (quizDone) {
      document
        .getElementById("formDoneContainer")
        .classList.remove("hide-print");
      document
        .getElementById("formEditingContainer")
        .classList.add("hide-print");
    } else {
      document.getElementById("formDoneContainer").classList.add("hide-print");
      document
        .getElementById("formEditingContainer")
        .classList.remove("hide-print");
    }
  }, [quizDone]);

  function handleNameChange(e) {
    setFormName(e.target.value);
  }

  function createOptions(list) {
    var options = list.map((value, index) => {
      return <option value={value} key={index} />;
    });
    return options;
  }

  function handleDone() {}
  return (
    <div className="form-container">
      <div className="form-page">
        <div className="logo-container">
          <img id="logo-white" src={logo} alt="Starka Resultat" />
          <img id="logo-black" src={logoPrint} alt="Starka Resultat" />
        </div>
        <div className="title-container">Karaktärsmatrisen</div>
        <div className="form-instructions-container">
          Att bygga en stark och trovärdig karaktär kräver att du är ärlig mot
          dig själv och din omgivning. En identitet som konstrueras kring
          långsiktigt hållbara principer. En kraftfull karaktär som står upp i
          alla väder. Din karaktär styr ditt varumärke. Ditt varumärke styr din
          karaktär. Precis som framgångsrika företags väl genomtänkta
          varumärkesstrategier som de inte viker en millimeter från Välj ut de
          ord som passar bäst för dig på respektive linje, eller välj ett eget.
          Fyll sedan kort ut hur ditt beteende i vardagen står i samsvar med
          detta. Tillkommer en ”negativ linje” som vi alla har. (Kan även tolkas
          som en positiv del)
        </div>

        <div id="formEditingContainer" className="form-field-container">
          <form>
            <label>Mitt Namn</label>
            <br />
            <input
              type="text"
              placeholder="Mitt Namn"
              onChange={(e) => {
                handleNameChange(e);
              }}
            ></input>
            <br />
            <div className="form-spacer" />
            <label>Huvudlinje</label>
            <br />
            <input
              className="form-text-field-type-one"
              list="huvudlinjer"
              id="huvudlinjeId"
              placeholder="Min huvudlinje"
              onChange={(e) => {
                handleChange(e, setFormFieldOne);
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
            />
            <textarea
              className="form-text-field-type-two"
              type="textarea"
              placeholder="Mitt beteende i vardagen som visar detta:"
              onChange={(e) => {
                handleChange(e, setFromExampleMain);
              }}
            />
            <datalist id="huvudlinjer">{createOptions(dataListOne)}</datalist>

            <div className="form-spacer" />
            <br />
            <label>Kompletteringslinje 1</label>
            <br />
            <input
              className="form-text-field-type-one"
              list="kompOne"
              id="kompOneId"
              placeholder="Min första kompletteringslinje"
              onChange={(e) => {
                handleChange(e, setFormFieldTwo);
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
            />
            <textarea
              className="form-text-field-type-two"
              type="textarea"
              placeholder="Mitt beteende i vardagen som visar detta:"
              onChange={(e) => {
                handleChange(e, setFromExampleCompOne);
              }}
            />
            <datalist id="kompOne">{createOptions(dataListTwo)}</datalist>

            <br />
            <label>Kompletteringslinje 2</label>
            <br />
            <input
              className="form-text-field-type-one"
              list="kompTwo"
              id="kompTwoId"
              placeholder="Min andra kompletteringslinje"
              onChange={(e) => {
                handleChange(e, setFormFieldThree);
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
            />
            <textarea
              className="form-text-field-type-two"
              type="textarea"
              placeholder="Mitt beteende i vardagen som visar detta:"
              onChange={(e) => {
                handleChange(e, setFromExampleCompTwo);
              }}
            />
            <datalist id="kompTwo">{createOptions(dataListTwo)}</datalist>

            <br />
            <label>Kompletteringslinje 3</label>
            <br />
            <input
              className="form-text-field-type-one"
              list="kompThree"
              id="kompThreeId"
              placeholder="Min tredje kompletteringslinje"
              onChange={(e) => {
                handleChange(e, setFormFieldFour);
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
            />
            <textarea
              className="form-text-field-type-two"
              type="textarea"
              placeholder="Mitt beteende i vardagen som visar detta:"
              onChange={(e) => {
                handleChange(e, setFromExampleCompThree);
              }}
            />
            <datalist id="kompThree">{createOptions(dataListTwo)}</datalist>
            <div className="form-spacer" />
            <br />
            <label>Negative linje</label>
            <br />
            <input
              className="form-text-field-type-one"
              list="negativLinje"
              id="negativeLinjeId"
              placeholder="Min negative linje"
              onChange={(e) => {
                handleChange(e, setFormFieldFive);
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
            />
            <datalist id="negativLinje">
              {createOptions(dataListThree)}
            </datalist>
          </form>
          <div className="form-spacer" />
          <div
            className="form-print-button button-done"
            onClick={() => {
              setQuizDone(true);
            }}
          >
            Klar
          </div>
        </div>
        <div
          id="formDoneContainer"
          className="form-done-container-top show-print hide-print"
        >
          <div className="form-done-container">
            <div className="done-container-one">{formName}</div>
            <div className="done-container-header done-container-two">
              <div className="form-done-title">Min Huvudlinje</div>
              <div className="form-result-one">{formFieldOne}</div>
              <div className="form-daily-example">({formExampleMain})</div>
            </div>
            <div className="done-container-three">
              <div className="done-three-line" />
              <div className="done-three-line" />
              <div className="done-three-line" />
            </div>

            <div className="done-container-four">
              <div className="form-done-title ">Mina kompletteringsliner</div>
              <div className="done-container-four-b">
                <div className="done-comp-container">
                  <div className="done-comp-word">{formFieldTwo}</div>
                  <div className="form-daily-example">
                    ({formExampleCompOne})
                  </div>
                </div>
                <div className="done-comp-container">
                  <div className="done-comp-word">{formFieldThree}</div>
                  <div className="form-daily-example">
                    ({formExampleCompTwo})
                  </div>
                </div>
                <div className="done-comp-container">
                  <div className="done-comp-word">{formFieldFour}</div>
                  <div className="form-daily-example">
                    ({formExampleCompThree})
                  </div>
                </div>
              </div>
            </div>
            <div className="done-container-five">
              <div className="done-five-line" />
            </div>
            <div className="done-container-header done-container-six">
              <div className="form-done-title">Min negativa linje</div>
              <div className="form-result-five">{formFieldFive}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={quizDone ? "form-print-button" : "form-print-button hide-print"}
        onClick={() => {
          window.print();
        }}
      >
        Skriv ut
      </div>
    </div>
  );
}

export default Egenskapsmatis;
