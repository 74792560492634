import React, { useState, useContext } from "react";
import { AppContext } from "./AppContext";
import QuestionsInResults from "./QuestionsInResults";

function Results(props) {
  const [answersExpanded, setAnswersExpanded] = useState(false);
  var { languageSE } = useContext(AppContext);
  function sumArray(array) {
    return 4 * array.reduce((a, b) => a + b, 0);
  }
  function getSpan() {
    var span = sumArray(props.answers);
    if (span < 25) {
      return " (0-24)";
    }
    if (span > 24 && span < 50) {
      return " (25-49)";
    }
    if (span > 49 && span < 75) {
      return " (50-74)";
    }
    if (span > 74 && span < 90) {
      return " (75-89)";
    }
    if (span > 89) {
      return " (90-100)";
    }
  }
  function getSpanIndex() {
    var span = sumArray(props.answers);
    if (span < 25) {
      return 0;
    }
    if (span > 24 && span < 50) {
      return 1;
    }
    if (span > 49 && span < 75) {
      return 2;
    }
    if (span > 74 && span < 90) {
      return 3;
    }
    if (span > 89) {
      return 4;
    }
  }

  function getExpansionText() {
    if (languageSE) {
      if (answersExpanded) {
        return "Dölj mina svar";
      } else {
        return "Visa mina svar";
      }
    } else {
      if (answersExpanded) {
        return "Hide my answers";
      } else {
        return "Show my answers";
      }
    }
  }

  function returnNewArray(array) {
    var newStr = array[0];
    newStr = newStr.split("\n");
    return (
      <div>
        {newStr.map((line, index) => {
          return (
            <div key={index}>
              {line} <br />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className="quiz-results-container"
      style={{ backgroundColor: props.colors.secondary }}
    >
      <div className="quiz-results-text-container">
        <div className="quiz-results-title">
          {props.title} {getSpan()}
        </div>
        <div>{returnNewArray(props.resultsText[getSpanIndex()])}</div>
      </div>
      <div className="quiz-results-point-container">
        <div className="quiz-results-points">
          <div className="quiz-results-points-tot">
            {sumArray(props.answers)}
          </div>
          <div className="quiz-results-points-max">/100</div>
        </div>
      </div>
      <div className="results-answer-container">
        <div
          style={{
            height: "1px",
            width: "70%",
            backgroundColor: "white",
            marginTop: "20px",
            justifySelf: "center",
          }}
        />
        <div
          id="quiz-results-answers"
          className="quiz-results-answers-container"
        >
          <QuestionsInResults
            quizProgress={props.quizProgress}
            quizQuestions={props.quizQuestions}
            quizQuestionsStart={1}
            setQuizProgress={props.setQuizProgress}
            setAnswer={props.setAnswer}
            answers={props.answers}
            colors={props.colors}
            questionIndex={0}
          />
          <QuestionsInResults
            quizProgress={props.quizProgress}
            quizQuestions={props.quizQuestions}
            quizQuestionsStart={1}
            setQuizProgress={props.setQuizProgress}
            setAnswer={props.setAnswer}
            answers={props.answers}
            colors={props.colors}
            questionIndex={1}
          />
          <QuestionsInResults
            quizProgress={props.quizProgress}
            quizQuestions={props.quizQuestions}
            quizQuestionsStart={1}
            setQuizProgress={props.setQuizProgress}
            setAnswer={props.setAnswer}
            answers={props.answers}
            colors={props.colors}
            questionIndex={2}
          />
          <QuestionsInResults
            quizProgress={props.quizProgress}
            quizQuestions={props.quizQuestions}
            quizQuestionsStart={1}
            setQuizProgress={props.setQuizProgress}
            setAnswer={props.setAnswer}
            answers={props.answers}
            colors={props.colors}
            questionIndex={3}
          />
          <QuestionsInResults
            quizProgress={props.quizProgress}
            quizQuestions={props.quizQuestions}
            quizQuestionsStart={1}
            setQuizProgress={props.setQuizProgress}
            setAnswer={props.setAnswer}
            answers={props.answers}
            colors={props.colors}
            questionIndex={4}
          />
        </div>
        <div
          className="quiz-results-answers-button"
          onClick={() => {
            document
              .getElementById("quiz-results-answers")
              .classList.toggle("quiz-results-answers-container-show");
            answersExpanded
              ? setAnswersExpanded(false)
              : setAnswersExpanded(true);
          }}
        >
          {getExpansionText()}
        </div>
      </div>
    </div>
  );
}

export default Results;
