import React, {useEffect, useState, useContext} from 'react'
import { AppContext } from "./AppContext";
import "./BrainzPromter.css"
import BrainzCover from "../images/Henrik-Pettersson-Brainz-Cover.jpg"

function BrainzPromter() {

var [closeSignUp, setCloseSignUp] = useState(false);
var [userEmail, setUserEmail] = useState("");
var { languageSE, db, pageContent } = useContext(AppContext);

useEffect(()=>{
    document.addEventListener('scroll', scrollListener)
    if(closeSignUp){
        document.getElementById("signupContainer").classList.add("slideOut-brainz");
        document.getElementById("signupContainer").classList.remove("slideIn-brainz");
    }
    return ()=> document.removeEventListener('scroll', scrollListener)
},[closeSignUp])

/*
function addEmailToFirebase(){
    if(userEmail !== ""){
        db.collection("emails")
    .add({email: userEmail})
    .then(()=>{
        setCloseSignUp(true)
    })
    .catch((e)=>{
        console.log(e)
    })
    }
}
*/
const scrollListener = () =>{
    var scrollY = window.scrollY || window.pageYOffset;
        if(!closeSignUp){  
            if(scrollY > window.innerHeight*0.5-200){
                document.getElementById("signupContainer").classList.add("slideOut-brainz");
                document.getElementById("signupContainer").classList.remove("slideIn-brainz");
            } else{
                document.getElementById("signupContainer").classList.remove("slideOut-brainz");
                document.getElementById("signupContainer").classList.add("slideIn-brainz");
            }
        }
    
}

function handleChange(e){
    setUserEmail(e.target.value);
    console.log(userEmail);
}

function handleSubmit(e){
    e.preventDefault()
    //addEmailToFirebase()
}
        

    return (
        <div className="signup-container-brainz slideIn-brainz" id="signupContainer">
            <div className="close-popup-brainz" onClick={()=>{setCloseSignUp(true)}}>x</div>
            <div className="singup-info-brainz">
                <img className="cover-image" src={BrainzCover} alt="Henrik-Pettersson-Brainz-Cover"/>
                </div>
                <div className="signup-title-brainz">7 KEYS TO A POSITIVE MINDSET</div>
            <div className="signup-button-container-brainz">
                <div className="signup-button-brainz signup-primary-brainz" onClick={()=>{
                    document.getElementById("signupContainer").classList.add("slideOut-brainz");
                    document.getElementById("signupContainer").classList.remove("slideIn-brainz");
                    window.open("https://www.brainzmagazine.com/post/7-keys-to-a-positive-mindset", '_blank')
                    setCloseSignUp(true)
                }}>{pageContent.brainzCover01}</div>
               
            </div>
        </div>
    )
}

export default BrainzPromter