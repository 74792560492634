import React, { useEffect, useState, useContext } from 'react'
import { AppContext } from "./AppContext";
import "./BookPreview.css"

function BookPreview(props) {

    var { languageSE, db, pageContent } = useContext(AppContext);

    const [showPreview, setShowPreview] = useState(false)


    const preview = {
        title: `Från sammanfattningen av Henriks bok ”Programmera dig till framgång”`
    }

    const previewEN = {
        title: `From the summary of Henrik's book "Program yourself to success"`
    }
  return (
    <div onClick={()=>{props.setShowBookPreview(false)}} className={props.showBookPreview ? 'book-container' : 'book-container book-container-hide'}>
        <div className='book-content'> 
            <div className='book-title'>{pageContent.about011}</div>
            <div className='book-sub-title'>{pageContent.about012}</div>
            <br />

            <div className='book-text-bold'>{pageContent.about013}</div>
            <div className='book-text-italic'>{pageContent.about014}</div>
            <div>{pageContent.about015}</div>
            <div>{pageContent.about016}</div>
            <br />

            <div className='book-text-bold'>{pageContent.about017}</div>
            <div className='book-text-italic'>{pageContent.about018}</div>
            <div>{pageContent.about019}</div>
            <br />

            <div className='book-text-bold'>{pageContent.about020}</div>
            <div className='book-text-italic'>{pageContent.about021}</div>
            <div>{pageContent.about022}</div>
            <br />

            <div className='book-text-bold'>{pageContent.about023}</div>
            <div className='book-text-italic'>{pageContent.about024}</div>
            <div>{pageContent.about025}</div>
            <br />

            <div className='book-text-bold'>{pageContent.about026}</div>
            <div className='book-text-italic'>{pageContent.about027}</div>
            <div>{pageContent.about028}</div>
            <br />

            <div className='book-text-bold'>{pageContent.about029}</div>
            <div className='book-text-italic'>{pageContent.about030}</div>
            <div>{pageContent.about031}</div>
            <br />

            <div className='book-text-bold'>{pageContent.about032}</div>
            <div>
            <ul>
              <li>{pageContent.about033}</li>
              <li>{pageContent.about034}</li>
              <li>{pageContent.about035}</li>
            </ul>
            <div>{pageContent.about036}</div>
          </div>
          <br />

          <div className='book-text-bold'>{pageContent.about037}</div>
          <div className='book-text-italic'>{pageContent.about038}</div>
            <div>{pageContent.about039}</div>
            <br />

            <div className='book-text-bold'>{pageContent.about040}</div>
          <div className='book-text-italic'>{pageContent.about041}</div>
            <div>{pageContent.about042}</div>
            <div>{pageContent.about043}</div>
            <br />

            <div className='book-text-bold'>{pageContent.about044}</div>
          <div className='book-text-italic'>{pageContent.about045}</div>
            <div>{pageContent.about046}</div>
            <div>{pageContent.about047}</div>
            <div>{pageContent.about048}</div>
            <br />

        </div>
        <div className='book-close-button' onClick={()=>{props.setShowBookPreview(false)}}>Stäng</div>
        
    </div>
  )
}

export default BookPreview