import React, { useContext } from "react";
import { AppContext } from "./AppContext";

import QuestionCheckbox from "./QuestionCheckbox";

function Questions(props) {
  var { languageSE } = useContext(AppContext);
  //console.log(props.quizProgress - props.quizQuestionsStart);
  return (
    <div
      className="quiz-content"
      style={{ backgroundColor: props.colors.secondary }}
    >
      <div
        id="quiz-button-back"
        className="quiz-step-button quiz-step-button-previous"
        style={
          props.quizProgress === 1
            ? {
                backgroundColor: props.colors.disabled,
                color: "rgba(255,255,255,0.7)",
              }
            : { backgroundColor: props.colors.primary }
        }
        onClick={() => {
          if (props.quizProgress !== 1) {
            props.setQuizProgress(props.quizProgress - 1);
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.906"
          height="22.434"
          viewBox="0 0 12.906 22.434"
        >
          <path
            id="Path_92"
            data-name="Path 92"
            d="M-17578.785,15340.4l-8.078,8.388,8.078,8.39"
            transform="translate(17588.863 -15337.568)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </svg>
      </div>
      <div className="question-container">
        <div className="question-title" style={{ color: props.colors.primary }}>
          {languageSE ? "Fråga" : "Question"}{" "}
          {props.quizProgress - props.quizQuestionsStart + 1}
        </div>
        <div className="question-text">
          {props.quizQuestions[props.quizProgress - props.quizQuestionsStart]}
        </div>

        <div className="quiz-checkbox-container">
          <QuestionCheckbox
            number={1}
            setAnswer={props.setAnswer}
            answers={props.answers}
            questionIndex={props.quizProgress - props.quizQuestionsStart}
            colors={props.colors}
          />
          <QuestionCheckbox
            number={2}
            setAnswer={props.setAnswer}
            answers={props.answers}
            questionIndex={props.quizProgress - props.quizQuestionsStart}
            colors={props.colors}
          />
          <QuestionCheckbox
            number={3}
            setAnswer={props.setAnswer}
            answers={props.answers}
            questionIndex={props.quizProgress - props.quizQuestionsStart}
            colors={props.colors}
          />
          <QuestionCheckbox
            number={4}
            setAnswer={props.setAnswer}
            answers={props.answers}
            questionIndex={props.quizProgress - props.quizQuestionsStart}
            colors={props.colors}
          />
          <QuestionCheckbox
            number={5}
            setAnswer={props.setAnswer}
            answers={props.answers}
            questionIndex={props.quizProgress - props.quizQuestionsStart}
            colors={props.colors}
          />
        </div>
      </div>
      <div
        id="quiz-button-next"
        style={
          props.quizProgress === 15 && !props.checkIfDone()
            ? {
                backgroundColor: props.colors.disabled,
                color: "rgba(255,255,255,0.7)",
              }
            : { backgroundColor: props.colors.primary }
        }
        className="quiz-step-button quiz-step-button-next"
        onClick={() => {
          if (!props.checkIfDone() && props.quizProgress === 15) {
            //console.log("Not done", props.quizDone);
          } else {
            props.setQuizProgress(props.quizProgress + 1);
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.906"
          height="22.434"
          viewBox="0 0 12.906 22.434"
        >
          <path
            id="Path_92"
            data-name="Path 92"
            d="M-17578.785,15340.4l-8.078,8.388,8.078,8.39"
            transform="translate(-17575.957 15360.002) rotate(180)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </svg>
      </div>
    </div>
  );
}

export default Questions;
