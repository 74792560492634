import { AppContext } from "./AppContext";
import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";

function TempDoc() {
    var { db, setOnAdminPage } = useContext(AppContext);
    const [docID, setDocID] = useState("");
    const [data, setData] = useState();
    const { form } = useParams();

    useEffect(()=>{
        setOnAdminPage(true)
        db.collection("forms")
        .doc(form)
        .get()
        .then((doc) => {
          setData(doc.data());
          console.log(doc.data())
        })
        .catch((e) => {
          console.log("Error:", e);
        });
    },[])

    useEffect(()=>{
        if(docID != "")
        {
            console.log("id: "+ docID)
        db.collection("forms")
        .doc(docID)
        .set(data)
        .catch((e)=>{
            console.log("Error:", e);
        })
        }
        
    },[docID])

    const handleSubmit = (e) =>{
        e.preventDefault()
        
        setDocID(e.target[0].value)
    }
    return (
        <div>
            <form onSubmit={(e)=>handleSubmit(e)}>
                <input type="text" placeholder={form}/>
            </form>
        </div>
    )
}

export default TempDoc
