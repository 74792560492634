import React, { useContext, useEffect } from "react";
import "./ChangeText.css";
import { AppContext } from "./AppContext";

function ChangeText(props) {
  var { pageContext, signedIn } = useContext(AppContext);
  useEffect(() => {
    console.log("Data-value: " + props.changeID);
    if (signedIn) {
      if (props.changeID === undefined) {
        document.getElementById("edit-window").classList.toggle("hidden");
        console.log("no id");
      } else {
        document.getElementById("edit-window").classList.toggle("hidden");
      }
    }
  }, [props.changeID]);

  return (
    <div
      id="edit-window"
      className="change-text-container hidden"
      onClick={() => {
        props.setChangeID(undefined);
      }}
    >
      <h1>{props.changeID}</h1>
    </div>
  );
}

export default ChangeText;
