import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "./AppContext";
import ChangeText from "./ChangeText";
import ContentOne from "./ContentOne";
import ContentTwo from "./ContentTwo";
import ContentThree from "./ContentThree";
import Services from "./Services";
import Reviews from "./Reviews";
import SignUp from "./signupPromter";
import Brainz from "./BrainzPromter";
import CoachingPrompter from "./CoachPromter";
import CulturePrompter from "./CulturePrompter";
import Footer from "./Footer";
import ScrollIndicator from "./ScrollIndicator";

import "./home.css";
import "./home-mobile.css";
import serviceOne from "../images/service-one.png";
import serviceTwo from "../images/service-two.png";
import serviceThree from "../images/service-three.png";

import companyOne from "../images/companies/company-one.jpg";
import companyTwo from "../images/companies/company-two.jpg";
import companyThree from "../images/companies/company-three.jpg";
import companyFour from "../images/companies/company-four.jpg";

import companyFive from "../images/companies/company-five.jpg";
import companySix from "../images/companies/company-six.jpg";
import companySeven from "../images/companies/company-seven.jpg";
import companyEight from "../images/companies/company-eight.jpg";
import companyNine from "../images/companies/company-nine.jpg";
import companyTen from "../images/companies/company-ten.jpg";
import companyEleven from "../images/companies/company-eleven.jpg";
import companyTwelv from "../images/companies/company-twelv.jpg";
import companyThirteen from "../images/companies/company-thirteen.jpg";

import brainzLogo from "../images/brainz-magazine-logo.png";
import brainz500 from "../images/brainz500.png";
import brainz500c from "../images/brainz500.jpg";
import blogo from "../images/b-logo.jpg";
import ytlogo from "../images/yt-logo.jpg";
import kajabilogo from "../images/kajabi-logo.png"
import book from "../images/book.jpg"
import BookPreview from "./BookPreview";



function Home() {
  let history = useHistory();
  var { pageContent, languageSE } = useContext(AppContext);
  var [changeID, setChangeID] = useState(undefined);
  var [showBookPreview, setShowBookPreview] = useState(false)


  function extendSectionTwo(e) {
    const elements = document.querySelectorAll(".service-container-extended");
    elements.forEach((element) => {
      element.classList.toggle("service-container-extended-expanded");
    });
    const btnText = document.getElementById("open-all").innerText;
    if (btnText === pageContent.sectionTwoMore) {
      document.getElementById("open-all").innerHTML =
        pageContent.sectionTwoLess;
    } else {
      document.getElementById("open-all").innerHTML =
        pageContent.sectionTwoMore;
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleRightClick(e) {
    e.preventDefault();
    setChangeID(e.target.dataset["fbid"]);
    //console.log("Right clicked: " + e.target.id);
  }

  const addAnimation = () => {
    var scrollY = window.scrollY || window.pageYOffset;
    if(scrollY > window.innerHeight*0.3){
      document.removeEventListener('scroll', addAnimation)

      var qElements = document.getElementsByClassName("section-content-question")
      Object.keys(qElements).map((key)=>{
        qElements[key].classList.add("aniInQuestion")
      })
      var sElements = document.getElementsByClassName("section-content-question-sub")
      Object.keys(sElements).map((key)=>{
        sElements[key].classList.add("aniInSub")
      })
      var lElements = document.getElementsByClassName("section-content-question-line")
      Object.keys(lElements).map((key)=>{
        lElements[key].classList.add("aniInLine")
      })

    }
  }
  useEffect(()=>{
    document.addEventListener('scroll', addAnimation) 
  },[])

  //Scroll indiator
  //{window.innerWidth > 900 ? <ScrollIndicator/> : ""}

  //Fix av texter sen
  //<ChangeText changeID={changeID} setChangeID={setChangeID} />

  //<SignUp/> line 85

  // Line 279
  //<div className="section-services">
  //<Services/>
  //</div>
  //<CoachingPrompter/>
  return (
    <div
      className="home-container"
      onContextMenu={(e) => {
        handleRightClick(e);
      }}
    >
      
      {window.innerWidth > 900 ? <ScrollIndicator/> : ""}
      {languageSE ? <CulturePrompter /> : null}
      <BookPreview showBookPreview={showBookPreview} setShowBookPreview={setShowBookPreview}/>
      <div className="section-one">
        <a
          id="brainzLogoLink"
          href="https://www.brainzmagazine.com/executive-contributor/Henrik-Pettersson"
          target="_blank"
        >
          <img
            src={brainzLogo}
            id="brainzLogo"
            alt="Brainz Magazine Executive Contributor"
          />
        </a>
        <div id="brainz500">
          <img 
          className="brainz500Logo"
          id="brainz500Logo"
          src={brainz500}
          alt="Brainz Magazine Executive Contributor"/>
          <div id="brainz500cLink" onClick={()=>{window.open('https://www.brainzmagazine.com/brainz-500-global/henrik-pettersson?utm_source=so&cid=bdb28c4b-224c-48b1-8734-6d987fb13ca5')}}/>
        </div>
        
        <div id="brainz500Cert">
        <img 
          className="brainz500c"
          id="brainz500c"
          src={brainz500c}
          alt="Brainz Magazine Executive Contributor"/>
        </div>


        <div className="section-one-intro">
          <span className="first-word">{languageSE ? "SÄLJ & LEDARSKAPS" : "SALES & LEADERSHIP"}</span>{" "}
          {languageSE ? "UTBILDNINGAR" : "TRAINING"}
        </div>
      </div>
      <div className="section-two">
        <div className="section-two-intro">
          <span className="title-medium" id="test" data-fbid="serviceTitle">
            {pageContent.sectionTwoMainTitle}
          </span>
          <br />
          <span className="title-medium-description">
            {pageContent.sectionTwoSubTitle}
          </span>
          <br />
          <div className="title-medium-devider" />
          <span className="title-medium-description title-medium-bold">
            {pageContent.sectionTwoQ1}
            <br />
            <span className="title-medium-secondary">
              {pageContent.sectionTwoQ11}
            </span>
            <br />
            <br />
            {pageContent.sectionTwoQ2}
            <br />
            <span className="title-medium-secondary">
              {pageContent.sectionTwoQ21}
            </span>
            <br />
            <br />
            {pageContent.sectionTwoQ3}
            <br />
            <span className="title-medium-secondary">
              {pageContent.sectionTwoQ31}
            </span>
          </span>

          <div className="title-medium-devider" />
          <span className="title-medium-description">
            {pageContent.sectionTwoSubTitle2}
          </span>
        </div>
        <div id="service-one" className="service-container">
          <div className="service-image" data-value="ContentOne"
              onClick={(e) => {
                if (
                  document.getElementById("open-all").innerText ===
                  pageContent.sectionTwoMore
                ) {
                  extendSectionTwo(e.target.id);
                }
                //document.getElementById("ContentOne").scrollIntoView();
                const id = "ContentOne";
                const yOffset = -60;
                const element = document.getElementById(id);
                const y =
                  element.getBoundingClientRect().top +
                  window.pageYOffset +
                  yOffset;
                window.scrollTo({ top: y, behavior: "smooth" });
              }}>
            <img
              src={serviceOne}
              alt="Coaching"
              
            />
            <span className="image-title">{pageContent.serviceOneTitle}</span>
          </div>
          <div className="service-image-description">
            <span>{pageContent.serviceOneShort}</span>
          </div>
        </div>
        <div className="service-container">
          <div className="service-image" data-value="ContentTwo"
              onClick={(e) => {
                if (
                  document.getElementById("open-all").innerText ===
                  pageContent.sectionTwoMore
                ) {
                  extendSectionTwo(e.target.id);
                }

                //document.getElementById("ContentTwo").scrollIntoView();
                const id = "ContentTwo";
                const yOffset = -60;
                const element = document.getElementById(id);
                const y =
                  element.getBoundingClientRect().top +
                  window.pageYOffset +
                  yOffset;
                window.scrollTo({ top: y, behavior: "smooth" });
              }}>
            <img
              src={serviceTwo}
              alt="Inspirationsföreläsning"
              
            />
            <span className="image-title">{pageContent.serviceTwoTitle}</span>
          </div>
          <div className="service-image-description">
            <span>{pageContent.serviceTwoShort}</span>
          </div>
        </div>
        <div className="service-container">
          <div className="service-image" data-value="ContentThree"
              onClick={(e) => {
                if (
                  document.getElementById("open-all").innerText ===
                  pageContent.sectionTwoMore
                ) {
                  extendSectionTwo(e.target.id);
                }

                //document.getElementById("ContentThree").scrollIntoView();
                const id = "ContentThree";
                const yOffset = -60;
                const element = document.getElementById(id);
                const y =
                  element.getBoundingClientRect().top +
                  window.pageYOffset +
                  yOffset;
                window.scrollTo({ top: y, behavior: "smooth" });
              }}>
            <img
              src={serviceThree}
              alt="Utbildning - workshops"
              
            />
            <span className="image-title">
              {" "}
              {pageContent.serviceThreeTitle}
            </span>
          </div>
          <div className="service-image-description">
            <span>{pageContent.serviceThreeShort}</span>
          </div>
        </div>
        <div id="extended-content" className="service-container-extended">
          <ContentOne />
          <ContentTwo />
          <ContentThree />
        </div>
        <div className="section-two-outro">
          <div
            className="primary-button"
            onClick={() => {
              history.push("/contact");
            }}
          >
            {pageContent.sectionTwoContactBtn}
          </div>
          <div
            id="open-all"
            className="secondary-button"
            onClick={(e) => {
              extendSectionTwo(e.target.id);
            }}
          >
            {pageContent.sectionTwoMore}
          </div>
        </div>
      </div>
      <div className="section-content-intro">
        <div className="section-content-intro-questions-container">
          <div className="section-content-question-container">
            <div className="section-content-question delay-one">{pageContent.sectionTwoQ1}</div>
            <div className="section-content-question-line  delay-one"/>
            <div className="section-content-question-sub delay-one">{pageContent.sectionTwoQ11}</div>
          </div>
          <div className="section-content-question-container">
            <div className="section-content-question delay-two">{pageContent.sectionTwoQ2}</div>
            <div className="section-content-question-line delay-two"/>
            <div className="section-content-question-sub delay-two">{pageContent.sectionTwoQ21}</div>
          </div><div className="section-content-question-container">
            <div className="section-content-question delay-three">{pageContent.sectionTwoQ3}</div>
            <div className="section-content-question-line delay-three"/>
            <div className="section-content-question-sub delay-three">{pageContent.sectionTwoQ31}</div>
          </div>
        </div>
        <div className="section-content-intro-questions-text">{pageContent.sectionTwoSubTitle} {pageContent.sectionTwoSubTitle2}</div>
      </div>
      <div className="section-content-services">
        <div className="section-content-va-title">
          {pageContent.sectionTwoMainTitle.toUpperCase()}
        </div>
        <div className="section-content-va-sub">{pageContent.servicesMain01}</div>
        <div className="section-content-services-img-container"> 
            <div className="services-img-container">
              <img className="services-img" src={serviceOne}/>
              <div className="services-img-title">Coaching</div>
            </div>
            <div className="services-img-container">
              <img className="services-img" src={serviceTwo}/>
              <div className="services-img-title">{pageContent.serviceTwoTitle}</div>
            </div>
            <div className="services-img-container">
              <img className="services-img" src={serviceThree}/>
              <div className="services-img-title">{pageContent.serviceThreeTitle}</div>
            </div>
        </div>
        <div className="services-fade-div"/>
        <div className="section-content-service-button" onClick={() => {
              history.push("/services");
            }}>
          {pageContent.servicesTitle01}{" "}{pageContent.servicesTitle02}
          </div>
        
      </div>
      <div className="section-content-va">
        <div className="section-content-va-title">{languageSE ? "BOKEN" : "THE BOOK"}</div>
        <div className="section-content-va-sub">{ languageSE ? `Läs dig till Starka Resultat i Henrik Petterssons bok "Programmera dig för framgång"` : `Read your way to Strong Results in Henrik Pettersson's book "Programmera dig för framgång"`}</div>
        <div className="section-content-link"><img alt="Programmera dig till framgång." className="va-img-book" src={book} onClick={()=>{if(languageSE){window.open("https://soderpalm.se/butik/bok/programmera-dig-for-framgang-inte-bara-en-bok/")}else{window.open("https://soderpalm.se/butik/bok/programmera-dig-for-framgang-inte-bara-en-bok/")}}}/></div> 
        <div className="section-content-service-button" onClick={() => {
              window.open("https://soderpalm.se/butik/bok/programmera-dig-for-framgang-inte-bara-en-bok/");
            }}>
          {languageSE ? "KÖP NU" : "BUY NOW"}
          </div>
          <div className="section-content-service-button-secondary" onClick={()=>{setShowBookPreview(true)}}>{languageSE ? "Läs ett utdrag" : "Preview a section"}</div>
      </div>

      <div className="section-content-va">
        <div className="section-content-va-title">{pageContent.servicesMain04}</div>
        <div className="section-content-va-sub">{pageContent.servicesMain05}</div>
        <div className="section-content-links-container">
            <div className="section-content-link"><img className="va-img" src={blogo} onClick={()=>{window.open("https://www.brainzmagazine.com/executive-contributor/Henrik-Pettersson")}}/></div>
            <div className="section-content-link-devider"/>
            <div className="section-content-link"><img className="va-img" src={ytlogo} onClick={()=>{if(languageSE){window.open("https://www.youtube.com/playlist?list=PLt2EkjY5g3QXi_jizYRKMsXLwdJniNuFQ")}else{window.open("https://www.youtube.com/playlist?list=PLt2EkjY5g3QUJnHbAdA5HvJILTTz40TA1")}}}/></div>
        </div>
      </div>
      <div className="section-three">
      </div>
      <div id="section-three-reviews" className="section-three-point-five">
      <Reviews/>
      </div>
      <div className="section-four">
        <img src={companyOne} className="logo-div" alt="logo" />
        <img src={companyTwo} className="logo-div" alt="logo" />
        <img src={companyThree} className="logo-div" alt="logo" />
        <img src={companyFour} className="logo-div" alt="logo" />

        <img src={companyFive} className="logo-div" alt="logo" />
        <img src={companySix} className="logo-div" alt="logo" />
        <img src={companySeven} className="logo-div" alt="logo" />
        <img src={companyEight} className="logo-div" alt="logo" />
        <img src={companyNine} className="logo-div" alt="logo" />
        <img src={companyTen} className="logo-div" alt="logo" />
        <img src={companyEleven} className="logo-div" alt="logo" />
        <img src={companyTwelv} className="logo-div" alt="logo" />
        <img src={companyThirteen} className="logo-div" alt="logo" />
      </div>
      <Footer />
    </div>
  );
}

export default Home;


/*

      <div className="section-content-va">
        <div className="section-content-va-title">{pageContent.servicesMain03}</div> 
          <div className="section-content-va-sub">{pageContent.servicesMain02}</div>

            <div className="section-content-link"><img className="va-img" src={kajabilogo} onClick={()=>{if(languageSE){window.open("https://strongresults.mykajabi.com/pl/218418")}else{window.open("https://strongresults.mykajabi.com/pl/251064")}}}/></div>
            
      </div>
<div className="section-three-intro">
          <span className="title-medium-subtle">
            {pageContent.sectionThree001}
          </span>
        </div>  


<div className="quote">
          <span className="quote-text">{pageContent.sectionThree002}</span>
          <br />
          <span className="quote-by">{pageContent.sectionThree003}</span>
          <br />
          <span className="quote-sign">"</span>
        </div>
        <div className="quote">
          <span className="quote-text">{pageContent.sectionThree004}</span>

          <br />
          <span className="quote-by">{pageContent.sectionThree005}</span>
          <br />
          <span className="quote-sign">"</span>
        </div>
        <div className="quote">
          <span className="quote-text">{pageContent.sectionThree006}</span>

          <br />
          <span className="quote-by">{pageContent.sectionThree007}</span>
          <br />
          <span className="quote-sign">"</span>
        </div>

*/