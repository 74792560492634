import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "./AppContext";
import "./admin.css";
import SignIn from "./SignIn";
import WebForms from "./WebForm";

function Admin() {
  var { db, onAdminPage, setOnAdminPage, auth, signedIn, setAdminNewFormId, adminNewFormId} = useContext(
    AppContext
  );

  const [webForms, setWebForms] = useState([]);
  const [webFormsID, setWebFormsID] = useState([]);
  const history = useHistory();
  const [idOK, setIdOK] = useState(false)
  const [formToCopy, setFormToCopy] = useState(null)
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!onAdminPage) {
      setOnAdminPage(true);
    }
  }, []);

  useEffect(() => {
    db.collection("forms")
    .orderBy("title")
      .get()
      .then((forms) => {
        var temp = [...webForms];
        var tempObj = {};
        forms.forEach((doc) => {
          tempObj.id = doc.id;
          tempObj.data = doc.data();
          temp.push({ ...tempObj });
        });
        setWebForms(temp);
        //console.log("init: ", temp);
      });
  }, []);

  useEffect(() => {
    var temp = [];
    db.collection("forms")
      .get()
      .then((forms) => {
        forms.forEach((aForm) => {
          temp.push(aForm.id);
        });
      })
      .then(() => {
        setWebFormsID(temp);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    webForms.forEach((obj) => {
      //console.log(obj.id, obj.data);
    });
  }, [webForms]);

  function readUpdatedDB() {
    db.collection("forms")
      .get()
      .then((forms) => {
        var temp = [];
        var tempObj = {};
        forms.forEach((doc) => {
          tempObj.id = doc.id;
          tempObj.data = doc.data();
          temp.push({ ...tempObj });
        });
        setWebForms(temp);
      });
  }

  function getCopy(id){
    db.collection("forms")
    .doc(id)
    .get()
    .then((doc)=>{
      var newLink = window.prompt("Ange nytt länknamn. (Använd endast a-z & 0-9)", "new-link-name")

      //setFormToCopy(doc.data())

      if(checkNewLink(newLink) && newLink !== "new-link-name" && newLink !== ""){
        db.collection("forms")
        .doc(newLink.toLowerCase())
        .set(doc.data())
        .then(()=>{
          console.log("copy made!")
          
        }).then(()=>{
          db.collection("forms")
          .doc(id)
          .delete()
          .then(()=>{
            console.log("form changed link")
          })
          .catch((e)=>{
            console.log("link name error", e)
          })
        }).then(()=>{
          //readUpdatedDB()
          window.location.reload()
        })
        .catch((e)=>{
          console.log("Error:", e);
        })
        }else{
          window.alert("Det nya länknamnet är upptaget eller felaktigt!")
        }
    })
    .catch((e)=>{
      console.log("copy error: ", e)
    })
  }
/*
  useEffect(()=>{
    if(formToCopy !== null){
      console.log("formToCopy:", formToCopy)
    }
  },[formToCopy])
*/
  function checkNewLink(newLink){
    var available = true; 
    if(webForms.length > 0){
      webForms.map((f)=>{
        if(f.id === newLink){
          available = false;
          console.log("länknamn upptaget")
        }
      })
    }
    console.log(available)
    return available
  }

  /*
  function handleSubmit(e) {
    e.preventDefault();
    console.log(
      e.target[0].value,
      e.target[1].value,
      e.target[2].value,
      e.target[3].value,
      e.target[4].value,
      e.target.id
    );
    db.collection("forms")
      .doc(e.target.id)
      .set({
        title: e.target[0].value,
        questionOne: e.target[1].value,
        questionTwo: e.target[2].value,
        questionThree: e.target[3].value,
        questionFour: e.target[4].value,
        questionFive: e.target[5].value,
      })
      .then(() => {
        console.log("done");
        readUpdatedDB();
      });
  }
  */
  function handleSignOut() {
    auth
      .signOut()
      .then(() => {
        console.log("signed out");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleNewID(e){
    e.preventDefault()
    setAdminNewFormId(e.target.value.toLowerCase())
  }

  useEffect(()=>{
    if(signedIn){
      document.getElementById("newIDtext").innerHTML = ""
    document.getElementById("newIDtextField").classList.remove("red-text")
    }
    
    
    if(adminNewFormId !== "" && webForms.length > 0 && signedIn){
      setIdOK(true)
      webForms.map((f)=>{
        if(f.id === adminNewFormId){
          console.log("upptaget")
          document.getElementById("newIDtext").innerHTML = "länknamnet upptaget"
          document.getElementById("newIDtextField").classList.add("red-text")
          setIdOK(false)
        }
      })
    }
  },[adminNewFormId])
  /*
  function Content() {
    return (
      <div>
        <div>Web forms</div>
        <br />
        <div>
          {webForms.map((obj) => {
            return (
              <div>
                {obj.data.title}
                <form onSubmit={(e) => handleSubmit(e)} id={obj.id}>
                  <label>Title</label>
                  <input
                    type="text"
                    defaultValue={obj.data.title}
                    id={obj.id + "-title"}
                    key={obj.id + "-title"}
                  />
                  <label>Q1</label>
                  <input
                    type="text"
                    defaultValue={obj.data.questionOne}
                    id={obj.id + "-q1"}
                    key={obj.id + "-q1"}
                  />
                  <label>Q2</label>
                  <input
                    type="text"
                    defaultValue={obj.data.questionTwo}
                    id={obj.id + "-q2"}
                    key={obj.id + "-q2"}
                  />
                  <label>Q3</label>
                  <input
                    type="text"
                    defaultValue={obj.data.questionThree}
                    id={obj.id + "-q3"}
                    key={obj.id + "-q3"}
                  />
                  <label>Q4</label>
                  <input
                    type="text"
                    defaultValue={obj.data.questionFour}
                    id={obj.id + "-q4"}
                    key={obj.id + "-q4"}
                  />
                  <label>Q5</label>
                  <input
                    type="text"
                    defaultValue={obj.data.questionFive}
                    id={obj.id + "-q5"}
                    key={obj.id + "-q5"}
                  />
                  <input type="submit" value="Submit" />
                </form>
                <br />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
*/
  return (
    <div className="admin-page-container">
      <div className="admin-all-forms-container">
        {webForms && signedIn
          ? webForms.map((form) => {
              return (
                <div key={form.id} className="admin-a-form">
                  <div className="admin-form-language" >{form.data.language == "en" ? "🇺🇸" : ""}</div>
                  <div className="admin-a-form-title">{form.data.title}</div>
                  <div className="admin-a-form-link" onClick={() => {
                      window.open(`http://www.starkaresultat.se/form/${form.id}`);
                    }}>{form.data.language == "en" ? `http://www.strongresults.se/form/${form.id}` : `http://www.starkaresultat.se/form/${form.id}`}</div>
                  
                  <button
                  className="admin-edit-form-button"
                    onClick={() => {
                      history.push(`/admin/edit/${form.id}`);
                    }}
                  >
                    Redigera formulär
                  </button>
                  <button
                  className="admin-edit-form-button"
                    onClick={() => {
                      getCopy(form.id)
                    }}
                  >
                    Byt länknamn
                  </button>
                </div>
              );
            })
          : ""}
      </div>
      <div id="newIDtext"></div>
      {signedIn ? 
      (<input id="newIDtextField" className="admin-new-form-id-field" type="text" placeholder="...se/form/Ange-Önskad-Länk" value={adminNewFormId} onChange={(e)=>{handleNewID(e)}}/>):""}
      {signedIn ? (
        <button
        className="admin-new-form-button"
          onClick={() => {
            if(idOK){
              history.push(`/admin/edit/createnewformtypeone`);
            }else{
              alert("Ange ett unikt länknamn till fomuläret")
            }
            
          }}
        >
          Skapa nytt formulär Typ 1
        </button>
      ) : (
        ""
      )}
      <br />
      {signedIn ? (
        <button
        className="admin-new-form-button"
          onClick={() => {
            if(idOK){
              history.push(`/admin/edit/createnewformtypetwo`);
            }else{
              alert("Ange ett unikt länknamn till fomuläret")
            }
          }}
        >
          Skapa nytt formulär Typ 2
        </button>
      ) : (
        ""
      )}
      <br />
      {signedIn ? (
        <button
        className="admin-from-sign-out"
          onClick={() => {
            handleSignOut();
          }}
        >
          Logga ut
        </button>
      ) : (
        ""
      )}

      {signedIn ? "" : <SignIn auth={auth} />}
      <br />
    </div>
  );
}

export default Admin;
