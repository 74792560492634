import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "./AppContext";
import "./varvtalsraknaren.css";
import "./varvtalsraknaren-mobile.css";
import "./meter.css";
import Meter from "./Meter";
import CheckBoxes from "./CheckBoxes";
import Progressbar from "./Progressbar";
import MeterButton from "./MeterButton";
import Questions from "./Questions";
import Results from "./Results";
import Introduction from "./Introduction";

function Varvtalsraknaren() {
  var { languageSE } = useContext(AppContext);
  const [quizProgress, setQuizProgress] = useState(0);

  const [activePart, setActivePart] = useState(0);
  const [activePartAnswer, setActivePartAnswer] = useState(0);

  const [activeQuestion, setActiveQuestion] = useState(0);

  const [isBackActive, setIsBackActive] = useState(false);
  const [isNextActive, setIsNextActive] = useState(true);

  const [partOneResults, setPartOneResults] = useState(0);
  const [partTwoResults, setPartTwoResults] = useState(0);
  const [partThreeResults, setPartThreeResults] = useState(0);

  const [quizDone, setQuizDone] = useState(false);

  const [partOneAnswer, setPartOneAnswer] = useState([0, 0, 0, 0, 0]);
  const [partTwoAnswer, setPartTwoAnswer] = useState([0, 0, 0, 0, 0]);
  const [partThreeAnswer, setPartThreeAnswer] = useState([0, 0, 0, 0, 0]);

  const [partsProp, setPartsProp] = useState([
    { backgroundColor: "#fff", buttonColor: "#8A5994" },
    { backgroundColor: "#AF8CB6", buttonColor: "#8A5994" },
    { backgroundColor: "#7F87A6", buttonColor: "#4A5681" },
    { backgroundColor: "#6E7F89", buttonColor: "#304857" },
  ]);

  const [quizStateColors] = useState([
    { primary: "#8A5994", secondary: "#AF8CB6", disabled: "#9C71A4" },
    { primary: "#4A5681", secondary: "#7F87A6", disabled: "#646E93" },
    { primary: "#304857", secondary: "#6E7F89", disabled: "#4F636F" },
  ]);

  const [quizResults, setQuizResults] = useState([
    { points: "1" },
    { points: "2" },
    { points: "3" },
  ]);

  const [activeQuizResultsIndex, setActiveQuizResultsIndex] = useState(0);

  const questionTitles = ["FRAMGÅNGSTRO", "ENERGI", "MÅLFOKUS"];
  const questionTitlesEN = ["FAITH", "ENERGY", "FOCUS"];

  const quizQuestions = [
    [
      "Jag ser oftare möjligheter än svårigheter, när jag tänker/pratar om framtiden",
      "Jag känner att det jag jobbar med eller emot, skapar/kommer skapa värden för min omgivning",
      "Jag känner att framtiden har mer att ge och jag ser ljust på den",
      "Jag känner att jag själv kan påverka en stor del av min framtid",
      "Jag söker ofta sätt att förbättra mitt lärande för att bli en bättre person i framtiden",
    ],
    [
      'Jag har en "gameplan" runt att ta hand om mig så jag kan vara mitt bästa jag för mig själv och min omgivning',
      "Jag har ett spelsystem och plan runt min kost och följer den oftast",
      "Jag har ett spelsystem och plan runt min träning och följer den oftast",
      "Jag har ett spelsystem och plan runt mina sovvanor och följer den oftast",
      "Jag har ett spelsystem och plan runt mina relationer och följer den oftast",
    ],
    [
      "Jag har ett tydligt svar på varför jag gör det jag gör och jobbar mot det",
      "Jag har minst ett tydligt mål som jag idag jobbar mot",
      "Jag har skrivit ner målet på papper och ser varje dag på det",
      "jag har satt en tydlig målbild i tid när det ska vara klart/uppnått",
      "Jag gör oftast varje arbetsdag en kort lista med de prioritering över de viktigaste sakerna som jag behöver utföra",
    ],
  ];

  const quizQuestionsEN = [
    [
      "When I think / talk about the future, I see opportunities more often than difficulties",
      "I feel that what I work with or against, creates / will create value for my surroundings",
      "I feel that the future has more to offer and I see it brightly",
      "I feel that I can influence a large part of my future, by myself",
      "I often look for ways to improve my learning to become a better person in the future",
    ],
    [
      'I have a "game plan" how to take care of myself, for both me and my surroundings',
      "I have a game plan around my diet and usually follow it",
      "I have a game plan around my training and usually follow it",
      "I have a game plan around my sleeping habits and usually follow it",
      "I have a game plan around my relationships and usually follow it",
    ],
    [
      "I have a clear answer as to why I do what I do and work towards it",
      "I have at least one clear goal that I am working towards today",
      "I have written down the goal on paper and look at it every day",
      "I have set a clear goal picture- in time when it should be ready / achieved",
      "I usually make a list of priorities for the most important things I need to do each workday",
    ],
  ];

  const questions = [
    "Jag ser oftare möjligheter än svårigheter, när jag tänker/pratar om framtiden",
    "Jag känner att det jag jobbar med eller emot, skapar/kommer skapa värden för min omgivning",
    "Jag känner att framtiden har mer att ge och jag ser ljust på den",
    "Jag känner att jag själv kan påverka en stor del av min framtid",
    "Jag söker ofta sätt att förbättra mitt lärande för att bli en bättre person i framtiden",
    'Jag har en "gameplan" runt att ta hand om mig så jag kan vara mitt bästa jag för mig själv och min omgivning',
    "Jag har ett spelsystem och plan runt min kost och följer den oftast",
    "Jag har ett spelsystem och plan runt min träning och följer den oftast",
    "Jag har ett spelsystem och plan runt mina sovvanor och följer den oftast",
    "Jag har ett spelsystem och plan runt mina relationer och följer den oftast",
    "Jag har ett tydligt svar på varför jag gör det jag gör och jobbar mot det",
    "Jag har minst ett tydligt mål som jag idag jobbar mot",
    "Jag har skrivit ner målet på papper och ser varje dag på det",
    "jag har satt en tydlig målbild i tid när det ska vara klart/uppnått",
    "Jag gör oftast varje arbetsdag en kort lista med de prioritering över de viktigaste sakerna som jag behöver utföra",
  ];

  const questionNumber = [
    0,
    1,
    2,
    3,
    4,
    5,
    1,
    2,
    3,
    4,
    5,
    1,
    2,
    3,
    4,
    5,
    0,
    0,
    0,
  ];

  const quizResultsTextEN = [
    [
      [
        `"There is no justified resentment", it said on a wall in a conference room, a message that paves the way for the belief in success. For no matter how badly you have been treated, bitterness is never justified. If you walk around carrying the feeling of being treated unfairly, you are the only loser. No one dies from the snakebite itself, it is the poison that pumps around that hurts you.
        
        Try to shut out people who drain you of energy, and make you think negative thoughts that poison your life.
        
        Forget injustices that steal energy, balance your inner compass for strong results and spend time with people who give you energy, power and hope.`,
      ],
      [
        `Successful people are almost always future-oriented. They have visions and plans for both their careers and life in general. They have target images that they constantly visualize, which gives the belief in success - that frees the ability to take action.

        Get yourself target images - while focusing on them in the morning, which sets the standard for the day. Think joy before anxiety, which is actually nothing but bad leadership over your thoughts. And focus on an upward positive spiral.
        `,
      ],
      [
        `You are on the way to the long-term well-being that is controlled from the inside, while physical pleasure is short-lived.

        You choose the three-lane path to good thoughts that are about gratitude, giving perspective, future prospects and hope for success. And constantly reminds you of what triggers you, what feels good for you.
        
        Things that make you realize that you have a lot to be thankful for. A feeling that shows the way to long-term joy.
        `,
      ],
      [
        `If you want to take the plunge, become successful as a leader, salesperson, marketing manager or parent, do not expect people to cheer along the way. Not everyone will be able to cope with your successes, but will instead find fault. Let it drain like water on a goose.
        
        You do not need the permission of people to do what you are passionate about. You believe in yourself, and are about to become free from what others think and feel about you. You do not have to collect "likes", show what you eat and how you live, what mobile you have or what car you drive.
        
        You get better and better on the road to faith in success at the very highest level.
        `,
      ],
      [
        `You have armed yourself with what you need for strong results; knowledge, skills, education, tools, resources, mental and physical strength.
        
        You have taken off your sacrificial cardigan, avoided the escapes, brushed away the excuses, raised your eyes, shrugged your shoulders and see problems as opportunities.
        
        Keep listening to yourself, then it will always be as you intended.
        `,
      ],
    ],
    [
      [
        `If only I had more energy, more money, and better education. If only there were better times, only I felt a little better, my surroundings were more understanding. If only others understood how good I really am, if I did not have such bad luck, unhappy. If only life were not so unfair, if only I did not have to take care of everything myself. If only I were younger and healthier and had a better boss.

        Evasions that are created so that we do not have to take control of our lives. An habit that many adopt early in life, and which diminishes both ourselves and the environment. And that prevents us from taking action. And that in the long run makes us give in to the slightest problem.
        
        So avoid postponing things and creating reasons not to deliver. Open up to the driving forces and create discipline while putting the excuses in the trash.
        `,
      ],
      [
        `For various reasons, you are drained of energy. A situation and starting point that can be inspiring after all. Most people who are at the top have been at the bottom at some point, until they found their skills and weapons for success. Most who are good have at some point been on the bottom. One thing to refuel your thoughts with.

        What are the best cards you have not yet flipped through? Which together with scheduled routines makes you get better and act more often.
        
        Things get better as you get better. Well done is always better than well said.
        Are the sentences that take you to the next level.
        `,
      ],
      [
        `You are well on your way - wonderful! And can start game plans that you can keep. Aim for 2 to 3 days a week.

        Which you carry out regardless of the weather, seasons, travel, conferences, pick-ups and drop-offs at kindergarten or whatever the excuses may consist of.
        
        You will not get strong results in 3 weeks, but within a year you have my guarantees.
        
        At the same time you do not aim too high. Even the successful financier Warren Buffet recommends sticking to a few things, which you follow up as concentrated as consistently.
        
        Once you have decided to take action, it is easy to do too much, too often, too quickly, and in too many areas. As with physical exercise, this type of "period" usually starts too hard, and it is difficult to keep the line over time.
        
        So choose a few things that you concentrate on, while not forgetting unhealthy food, which you can easily take control of by a total ban Monday through Thursday on everything that has high levels of sugar in it, such as cakes, sweets , ice cream and alcohol. So you eat healthy four days a week.
        For stronger results.
        `,
      ],
      [
        `Good work!
        You are on track with a pace that you can keep up. It's not about being the best at sprint races, but learning to live with habits that you can manage over time
        Tips for maintaining a continued high energy level:
        
        1. Stand instead of sitting. Get up on the bus, on the train and in the waiting room. Take the stairs instead of the elevator.
        
        2. Structure the training so that it works in everyday life and fits in the calendar. Post the first of the week on Monday or Tuesday. Then you get a good start to the week and it will be easier to catch up with two more in the same week.
        
        3. Exercise in the morning. Then you avoid a bad conscience on the way home from work. Present the training clothes the night before and place the shoes at the front door.
        
        4. Eat better. While it is important to treat yourself to goodies, you should be aware that they rob us of energy. But let the days that you think about what you eat increase.
        
        5. Start and end the day with a large glass of water
        `,
      ],
      [
        `You are one of the winners who get into trouble and find solutions. Unlike the losers who get solutions and find problems.
        
        It is not about smartness, strength or creativity, it is about the will to belong to group A who goes before and plows the road, before group B comes after and complains.
        
        It is about taking responsibility instead of seeking evasions, an attitude that sees resistance as opportunities, that chooses to rise before going down.
        
        The difference between flying and falling is a matter of attitude - and an equally clear and long-term game plan for taking care of one's energy. Which YOU have acquired.
        
        Keep riding the wave of success!
        
        If you just keep keeping your high quality, the highest level will be established sooner or later.
        `,
      ],
    ],
    [
      [
        `You lack well-thought-out goals that make you feel that you can make a difference. A relationship you can change by acquiring goals and guidelines that make you aware that you can influence.
        
        A first step may be to find thoughts that help you stay focused on time, through Youtube clips, music, books, vision boards or future images that make it easier to sell yourself to yourself.
        
        And take action.`,
      ],
      [
        `You are in a no-man's land that requires a transition from reactive to proactive, so that you can take the step towards strong results, and dare to take action on things you believe in while planning for strong results.
        
        Start with the morning routine that starts with the tasks that mean something in the long run, before you take your walk, your workout, your healthy breakfast, take the hard call, send the important email, keep the evasions at bay and hold the line.
        
        Success does not come overnight. But if you have a game plan that you keep, strong results will come with time. 
      `,
      ],
      [
        `In the upper intermediate position, you work according to the step-by-step chain: Obtain Information. Charge yourself with Intention. And take Initiative.
        
        We often have the intention to take action when the feeling is at its peak. But if we do not take action, it will slow down. So do not rest on the bearings for too long - drive so it smokes when you find something you believe in. It is up to you to drive your own development, focus on the right things - and take action before time runs out.
        
        Comedian Jerry Seinfeld put a cross in the calendar every day with the phrase "do not break the chain".
        
        A mantra worth playing every day.
        `,
      ],
      [
        `You are well on your way to focusing on what you really want to accomplish. What is worth spending your time on - in the long run, as opposed to efforts as pointless as the rain that falls over the Sahara, and evaporates before it hits the ground.
        
        You do as much right things as you do right. You have understood to plan along the chain of events. Good work!
        `,
      ],
      [
        `A positive attitude sees failures as learning sticks on the steps, which gives you time to get your thoughts in order before trying again.

        Words of wisdom from the successful businessman Clement Stone, who together with the cult film Ghostbusters -  Quitters never win - winners never quit - can stand to be constantly repeated.
        
        At the same time, the competition at the top is not excessive - the view is better, the crowds less and the air cleaner where you are. Congratulations!
        
        Well done is always better than well said. It is always action that separates people.
        `,
      ],
    ],
  ];

  const quizResultsText = [
    [
      [
        `”Det finns ingen motiverad bitterhet”, stod det på en vägg i en utbildningslokal i Umeå, ett budskap som röjer vägen för framgångstron. För oavsett hur illa du blivit bemött är bitterhet aldrig berättigad. Om du går omkring och bär på känslan av att vara orättvist behandlad är du den ende förloraren. Ingen dör av själva ormbettet, det är giftet som pumpar runt som skadar dig. Försök att stänga ute människor som dränerar dig på energi, och får dig att tänka negativa tankar som förgiftar ditt liv. Glöm oförrätter som stjäl energi, balansera din inre kompass för starka resultat och spendera tid med personer som ger dig energi, kraft och hopp.`,
      ],
      [
        `Framgångsrika personer är så gott som alltid framtidsorienterade. De har visioner och planer för såväl sina karriärer som livet i övrigt. De har målbilder som de ständigt visualiserar, som ger framgångstron som frigör förmågan att ta action. Skaffa dig målbilder samtidigt som du fokuserar på morgonen, som sätter standarden för dagen. Tanka glädje framför ångest, som ju inte är någonting annat än dåligt ledarskap över dina tankar. Och fokusera på en uppåtgående positiv spiral.`,
      ],
      [
        `Du är på väg mot det långsiktiga välbefinnande som styrs från insidan, samtidigt som fysisk njutning är kortvarig. Du väljer den trefiliga vägen till goda tankar som handlar om tacksamhet ger perspektiv, framtidsutsikter och hopp om framgång. Och påminner dig ständigt om vad som triggar dig, vad som känns bra för just dig. Saker som får dig inse att du har mycket att vara tacksam för. En känsla som visar vägen till långsiktig glädje.`,
      ],
      [
        `Om du vill ta steget fullt ut, bli framgångsrik som ledare, säljare, marknadschef eller förälder, förvänta dig inte att folk hurrar längs vägen. Alla kommer inte att kunna klara av dina framgångar, utan finner istället fel. Låt det rinna av dig som vatten på en gås. Du behöver inte människors tillåtelse att göra det som du brinner för. Du tror på dig själv, och är på väg att bli fri från vad andra tycker och tänker om dig. Du behöver inte samla ”likes”, visa upp vad du äter och hur du bor, vilken mobil du har eller vilken bil du kör. Du blir bättre och bättre på vägen mot framgångstro på allra högsta nivå.`,
      ],
      [
        `Du har beväpnat dig med vad du behöver för starka resultat; kunskap, kompetens, utbildning, redskap, resurser, mental och fysisk styrka. Du har tagit av dig offerkoftan, undvikit undanflykterna, borstat bort ursäkterna, höjt blicken, dragit tillbaka axlarna och ser problem som möjligheter. Fortsätt lyssna till dig själv, då blir det alltid som du tänkt dig.`,
      ],
    ],
    [
      [
        `Om jag bara hade mer energi, mer pengar, och bättre utbildning. Om det bara var bättre tider, bara jag mådde lite bättre, min omgivning var mer förstående. Om bara andra fattade hur bra jag egentligen är, om jag inte hade sådan otur, oflyt. Om bara inte livet var så orättvist, om jag bara inte behövde sköta allt själv. Om bara jag var yngre och friskare och hade en bättre chef. Undanflykter som skapas för att vi ska slippa ta tag i våra liv. En ovana som många tar till sig tidigt i livet, och som förminskar såväl oss själva som omgivningen. Och som hindrar oss från att ta action. Och som i förlängningen får oss att vika ner oss för minsta problem. Undvik alltså att skjuta upp saker och skapa anledningar att inte leverera. Öppna upp för drivkrafterna och skapa disciplin samtidigt som du stoppar ursäkterna i papperskorgen.`,
      ],
      [
        `Av olika skäl dräneras du på energi. En situation och utgångsläge som trots allt kan vara inspirerande. De flesta som befinner sig på toppen har någon gång varit på botten, tills de fann sina färdigheter och vapen för framgång. De flesta som är bra har någon gång varit på botten. En sak att tanka tankarna med. Vilka är de bästa korten som du ännu inte bläddrat fram? Som tillsammans med schemalagda rutiner får dig att bli bättre och agera oftare. Saker blir bättre när du blir bättre. Bra gjort är alltid bättre än bra sagt. Är meningarna som tar dig till nästa nivå.`,
      ],
      [
        `Du är på god väg – härligt! Och kan sätta igång spelsystem som du klarar av att hålla. Sikta på 2 till 3 dagar i veckan. Som du genomför oavsett väder, årstider, resor, konferenser, hämtningar och lämningar på dagis eller vad nu ursäkterna kan bestå av. Du kommer inte få starka resultat på 3 veckor, men inom ett år har du mina garantier. Samtidigt som du inte siktar för högt. Till och med framgångsrike finansmannen Warren Buffet rekommenderar att man håller sig till ett fåtal saker, som du följer upp lika koncentrerat som konsekvent. När du bestämt dig för att ta action är det lätt att göra för mycket, för ofta, för snabbt, och inom för många områden. Precis som med fysisk träning går den typen av ”periodare” oftast ut för hårt, och får svårt att hålla linjen över tid. Välj alltså ut ett fåtal saker som du koncentrerar dig på, samtidigt som du inte glömmer bort onyttig mat, som man enkelt kan ta kontroll över genom totalförbud måndag till och med torsdag på allt som har höga halter av socker i sig, som kakor, godis, glass och alkohol. Du käkar alltså hälsosamt fyra dagar i veckan. För starkare resultat.`,
      ],
      [
        `Bra jobbat! Du är på spåret med en takt som du klarar att hålla. Det handlar ju inte om att bli bäst på sprinterlopp, utan lära sig att leva med vanor som man klarar av över tid. Tips för att upprätthålla fortsatt hög energinivå: 1.	Stå istället för att sitta. Stå upp på bussen, på tåget och i väntrummet. Ta trappan istället för hissen. 2.	Strukturera träningen så att den funkar i vardagen och får plats i kalendern. Lägg in veckans första på måndag eller tisdag. Då får du en bra start på veckan och det blir lättare att hinna med ytterligare två samma vecka. 3.	Träna på morgonen. Då slipper du dåligt samvete på väg hem från jobbet. Lägg fram träningskläderna kvällen innan och ställ skorna vid ytterdörren. 4.	Ät bättre. Samtidigt som det är viktigt att unna sig godsaker, bör man vara medveten om att de rånar oss på energi. Men låt dagarna som du tänker på vad du äter bli fler. 5.	Börja och avsluta dagen med ett stort glas vatten. Fortsätter du bara hålla din höga kvalitet, kommer högsta nivån etableras förr eller senare.`,
      ],
      [
        `Du tillhör vinnarna som får problem och finner lösningar. Till skillnad mot förlorarna som får lösningar och finner problem. Det handlar inte om smartness, styrka eller kreativitet, det rör sig om viljan att tillhöra grupp A som går före och plöjer vägen, framför grupp B kommer efter och klagar. Det handlar om att ta ansvar istället för att söka undanflykter, en attityd som ser motstånd som möjligheter, som väljer att resa sig framför gå ner sig. Skillnaden mellan att flyga och falla är en fråga om inställning – och ett lika tydligt som långsiktigt spelsystem för att ta hand om sin energi. Som just DU har skaffat dig. Fortsätt rida på framgångsvågen!`,
      ],
    ],
    [
      [
        `Du saknar genomtänkta målsättningen som får dig känna att du kan göra skillnad. Ett förhållande du kan förändra genom att skaffa dig syften och riktlinjer som gör dig medveten om att du kan påverka.

    Ett första steg kan vara att finna tankar som hjälper dig att hålla målfokus över tiden, genom Youtubeklipp, musik, böcker, visionstavlor eller framtidsbilder som gör det lättare att sälja dig själv till dig själv. 
    Och ta action.`,
      ],
      [
        `Du befinner dig ett ingenmansland som kräver omställning från reaktiv till proaktiv, för att du ska kunna ta steget mot starka resultat, och våga ta action kring saker du tror på samtidigt som du planerar för starka resultat.  

      Börja med morgonrutinen som startar med jobben som betyder någonting på sikt, innan du tar din promenad, din träning, din hälsosamma frukost, tar det jobbiga samtalet, skickar det viktiga mailet, håller undanflykterna stången och håller linjen.
      
      Framgång kommer inte över en natt. Men har du en spelplan som du håller kommer starka resultat med tiden. 
      `,
      ],
      [
        `I det övre mellanläget jobbar du efter stegvisa kedjan: Skaffa Information. Ladda dig med Intention. Och ta Initiativ.
      Vi har ofta intentionen att ta action när känslan är på topp. Men om vi inte går över till action kommer den avta. Vila alltså inte för länge på lagrarna – kör så det ryker när du råkar på någonting du tror på. Det är upp till dig att driva din egen utveckling, fokusera på rätt saker – och ta action innan tiden runnit ut.  
      
      Komikern Jerry Seinfeld satte kryss i kalendern varje dag med meningen ”bryt inte kedjan”. 
      Ett mantra värt att spelas upp varje dag. 
      `,
      ],
      [
        `Du är på god väg att fokusera på vad du egentligen vill åstadkomma. Vad som är långsiktigt värt att lägga din tid på, i motsats till insatser lika meningslösa som regnet som faller över Sahara, och förångas före det träffat marken. 

      Du gör lika mycket rätt saker som sakerna rätt. Du har förstått att planera längs händelsekedjan. Bra jobbat!
      `,
      ],
      [
        `En positiv attityd ser misslyckanden som lärande pinnar på stegen, som ger dig tid att få ordning på dina tankar innan du försöker igen. 

      Visdomsord från framgångsrike affärsmannen Clement Stones, som tillsammans med kultfilmen Ghostbusters Quitters never win – winners never quit tål att ständigt upprepas.
      
      Samtidigt är konkurrensen på toppen inte överdriven – utsikten är bättre, trängseln mindre och luften renare där du är. Stort grattis!
      
      Bra gjort är alltid bättre än bra sagt. Det alltid är action som skiljer människor åt. 
      `,
      ],
    ],
  ];

  /*
  useEffect(() => {
    console.log("quizProgress: " + quizProgress);
  }, [quizProgress]);
*/
  useEffect(() => {
    if (
      !partOneAnswer.includes(0) &&
      !partTwoAnswer.includes(0) &&
      !partThreeAnswer.includes(0) &&
      quizProgress > 15
    ) {
      setQuizDone(true);
    }
  }, [quizProgress]);

  function checkIfDone() {
    if (
      !partOneAnswer.includes(0) &&
      !partTwoAnswer.includes(0) &&
      !partThreeAnswer.includes(0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="test-container">
      <div className="meter-container">
        <Meter
          results={partOneResults}
          answers={partOneAnswer}
          colors={partsProp}
          number={0}
          quizDone={quizDone}
          active={
            (quizProgress > 0 && quizProgress < 6) || quizProgress === 16
              ? true
              : false
          }
        />
        <Meter
          results={partTwoResults}
          answers={partTwoAnswer}
          colors={partsProp}
          number={1}
          quizDone={quizDone}
          active={
            (quizProgress > 5 && quizProgress < 11) || quizProgress === 17
              ? true
              : false
          }
        />
        <Meter
          results={partThreeResults}
          answers={partThreeAnswer}
          colors={partsProp}
          number={2}
          quizDone={quizDone}
          active={
            (quizProgress > 10 && quizProgress < 16) || quizProgress === 18
              ? true
              : false
          }
        />
      </div>
      <div className="meter-button-container">
        <MeterButton
          title={languageSE ? questionTitles[0] : questionTitlesEN[0]}
          quizProgress={quizProgress}
          active={
            (quizProgress > 0 && quizProgress < 6) ||
            quizProgress === 16 ||
            quizProgress === 0
              ? true
              : false
          }
          returnQuizProgressTo={[1, 16]}
          setQuizProgress={setQuizProgress}
          quizDone={quizDone}
          colors={quizStateColors[0]}
        />
        <MeterButton
          title={languageSE ? questionTitles[1] : questionTitlesEN[1]}
          quizProgress={quizProgress}
          returnQuizProgressTo={[6, 17]}
          setQuizProgress={setQuizProgress}
          quizDone={quizDone}
          colors={quizStateColors[1]}
          active={
            (quizProgress > 5 && quizProgress < 11) ||
            quizProgress === 17 ||
            quizProgress === 0
              ? true
              : false
          }
        />
        <MeterButton
          title={languageSE ? questionTitles[2] : questionTitlesEN[2]}
          quizProgress={quizProgress}
          returnQuizProgressTo={[11, 18]}
          setQuizProgress={setQuizProgress}
          quizDone={quizDone}
          colors={quizStateColors[2]}
          active={
            (quizProgress > 10 && quizProgress < 16) ||
            quizProgress === 18 ||
            quizProgress === 0
              ? true
              : false
          }
        />
      </div>
      <div className="meter-progressbar-container">
        <Progressbar
          quizProgress={quizProgress}
          answers={partOneAnswer}
          startQuizProgress={1}
          setQuizProgress={setQuizProgress}
          colors={quizStateColors[0]}
        />
        <Progressbar
          quizProgress={quizProgress}
          answers={partTwoAnswer}
          startQuizProgress={6}
          setQuizProgress={setQuizProgress}
          colors={quizStateColors[1]}
        />
        <Progressbar
          quizProgress={quizProgress}
          answers={partThreeAnswer}
          startQuizProgress={11}
          setQuizProgress={setQuizProgress}
          colors={quizStateColors[2]}
        />
      </div>
      <div className="quiz-content-container">
        {quizProgress === 0 ? (
          <Introduction setQuizProgress={setQuizProgress} />
        ) : (
          ""
        )}
        {quizProgress === 16 ? (
          <Results
            title={languageSE ? questionTitles[0] : questionTitlesEN[0]}
            resultsText={languageSE ? quizResultsText[0] : quizResultsTextEN[0]}
            colors={quizStateColors[0]}
            answers={partOneAnswer}
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[0]}
            quizQuestionsStart={1}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartOneAnswer}
            answers={partOneAnswer}
          />
        ) : (
          ""
        )}
        {quizProgress === 17 ? (
          <Results
            title={languageSE ? questionTitles[1] : questionTitlesEN[1]}
            resultsText={languageSE ? quizResultsText[1] : quizResultsTextEN[1]}
            colors={quizStateColors[1]}
            answers={partTwoAnswer}
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[1]}
            quizQuestionsStart={6}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartTwoAnswer}
            answers={partTwoAnswer}
          />
        ) : (
          ""
        )}
        {quizProgress === 18 ? (
          <Results
            title={languageSE ? questionTitles[2] : questionTitlesEN[2]}
            resultsText={languageSE ? quizResultsText[2] : quizResultsTextEN[2]}
            colors={quizStateColors[2]}
            answers={partThreeAnswer}
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[2]}
            quizQuestionsStart={11}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartThreeAnswer}
            answers={partThreeAnswer}
          />
        ) : (
          ""
        )}
        {quizProgress > 0 && quizProgress < 6 ? (
          <Questions
            quizProgress={quizProgress}
            quizQuestions={languageSE ? quizQuestions[0] : quizQuestionsEN[0]}
            quizQuestionsStart={1}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartOneAnswer}
            answers={partOneAnswer}
            colors={quizStateColors[0]}
            checkIfDone={checkIfDone}
          />
        ) : (
          ""
        )}
        {quizProgress > 5 && quizProgress < 11 ? (
          <Questions
            quizProgress={quizProgress}
            quizQuestions={languageSE ? quizQuestions[1] : quizQuestionsEN[1]}
            quizQuestionsStart={6}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartTwoAnswer}
            answers={partTwoAnswer}
            colors={quizStateColors[1]}
            checkIfDone={checkIfDone}
          />
        ) : (
          ""
        )}
        {quizProgress > 10 && quizProgress < 16 ? (
          <Questions
            quizProgress={quizProgress}
            quizQuestions={languageSE ? quizQuestions[2] : quizQuestionsEN[2]}
            quizQuestionsStart={11}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartThreeAnswer}
            answers={partThreeAnswer}
            colors={quizStateColors[2]}
            checkIfDone={checkIfDone}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Varvtalsraknaren;
