import React, {useEffect, useRef, useState} from 'react'
import "./Preloader.css"
function Preloader(props) {

     const [img01, setImg01] = useState(false)
     const [img02, setImg02] = useState(false)
     const [img03, setImg03] = useState(false)
     const [img04, setImg04] = useState(false)
     const [img05, setImg05] = useState(false)
     const [img06, setImg06] = useState(false)

    const imgRef01 = useRef()
    const imgRef02 = useRef()
    const imgRef03 = useRef()
    const imgRef04 = useRef()
    const imgRef05 = useRef()
    const imgRef06 = useRef()

    useEffect(()=>{ 
        checkAllIfLoaded()
    },[])

    const checkAllIfLoaded = () =>{
        if(imgRef01.current.complete){
            setImg01(true)

        }
        if(imgRef02.current.complete){
            setImg02(true)

        }
        if(imgRef03.current.complete){
            setImg03(true)
    
        }
        if(imgRef04.current.complete){
            setImg04(true)
            
        }
        if(imgRef05.current.complete){
            setImg05(true)
           
        }
        if(imgRef06.current.complete){
            setImg06(true)
           
        }
    }

    useEffect(()=>{
        if(img01 && img02 && img03 && img04 && img05 && img06){
            props.setImagesLoaded(true)
            
        }
    },[img01, img02, img03, img04, img05, img06])
    return (
            <div className="preloader">
                <img
                    ref={imgRef01}
                    src={require('../images/home-desktop-1920.jpg')} onLoad={()=>{setImg01(true)}}>
                </img>
                <img
                    ref={imgRef02}
                    src={require('../images/test-desktop-1920.jpg')} onLoad={()=>{setImg02(true)}}>
                </img>
                <img
                    ref={imgRef03}
                    src={require('../images/contact-desktop-1920.jpg')} onLoad={()=>{setImg03(true)}}>
                </img>
                <img
                    ref={imgRef04}
                    src={require('../images/about-desktop-1920.jpg')} onLoad={()=>{setImg04(true)}}>
                </img>
                <img
                    ref={imgRef05}
                    src={require('../images/home-mobile.jpg')} onLoad={()=>{setImg05(true)}}>
                </img>
                <img
                    ref={imgRef06}
                    src={require('../images/Henrik-Pettersson-Brainz-Cover.jpg')} onLoad={()=>{setImg06(true)}}>
                </img>
            </div>
    )
}

export default Preloader
