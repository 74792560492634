import React, { useState, useEffect, useContext } from "react";
import "./varvtalsraknaren.css";
import "./varvtalsraknaren-mobile.css";
import "./meter.css";
import Meter from "./Meter";
import CheckBoxes from "./CheckBoxes";
import Progressbar from "./Progressbar";
import MeterButton from "./MeterButton";
import Questions from "./Questions";
import Results from "./Results";
import IntroductionJPI from "./IntroductionJPI";
import { AppContext } from "./AppContext";

function JPITest() {
  const [quizProgress, setQuizProgress] = useState(0);
  var { setLanguageSE } = useContext(AppContext);

  useEffect(()=>{
      setLanguageSE(false);
  },[])

  //const [activePart, setActivePart] = useState(0);
  //const [activePartAnswer, setActivePartAnswer] = useState(0);

  //const [activeQuestion, setActiveQuestion] = useState(0);

  //const [isBackActive, setIsBackActive] = useState(false);
  //const [isNextActive, setIsNextActive] = useState(true);

  const [partOneResults, setPartOneResults] = useState(0);
  const [partTwoResults, setPartTwoResults] = useState(0);
  const [partThreeResults, setPartThreeResults] = useState(0);

  const [quizDone, setQuizDone] = useState(false);

  const [partOneAnswer, setPartOneAnswer] = useState([0, 0, 0, 0, 0]);
  const [partTwoAnswer, setPartTwoAnswer] = useState([0, 0, 0, 0, 0]);
  const [partThreeAnswer, setPartThreeAnswer] = useState([0, 0, 0, 0, 0]);

  const [partsProp, setPartsProp] = useState([
    { backgroundColor: "#fff", buttonColor: "#8A5994" },
    { backgroundColor: "#AF8CB6", buttonColor: "#8A5994" },
    { backgroundColor: "#7F87A6", buttonColor: "#4A5681" },
    { backgroundColor: "#6E7F89", buttonColor: "#304857" },
  ]);

  const [quizStateColors] = useState([
    { primary: "#8A5994", secondary: "#AF8CB6", disabled: "#9C71A4" },
    { primary: "#4A5681", secondary: "#7F87A6", disabled: "#646E93" },
    { primary: "#304857", secondary: "#6E7F89", disabled: "#4F636F" },
  ]);

  const [quizResults, setQuizResults] = useState([
    { points: "1" },
    { points: "2" },
    { points: "3" },
  ]);

  const [activeQuizResultsIndex, setActiveQuizResultsIndex] = useState(0);

  const quizQuestions = [
    [
      "Gratitude - How often do you feel reminded of what you have to be thankful for?",
      "Diet - Do you have a good plan for your diet and follow it, most days of the week?",
      "Exercise - Do you have a good plan for your exercise and follow it, most days of the week?",
      "Sleep - What is your perception of your sleeping habits at the moment?",
      "Relationships - How good are you at giving first and foremost in your immediate relationship?",
    ],
    [
      `Inspiration - How often do you plan to put yourself in the "right" state of inspiration?`,
      "Do you have a word / mantra that you use today to strengthen yourself?",
      "Breathing - At what level do you estimate you do deep breathing / oxygenation?",
      "Relaxation – At what extent do you to focus on relaxation?",
      "Purpose - I am clear about my mission and focus on it most days of the week?",
    ],
    [
      "Values ​​- I have 3 words I value as a person, and look at them often?",
      `Achievement - I am great at reminding myself of my previous "victories", to stimulate myself?`,
      "Future - I remind myself of what lies ahead of me - to trigger my ambition?",
      "Health / appearance - I often remind myself of what I am happy of, with my body, internally or externally?",
      "Joy - I am great at triggering joy by reminding myself of good experiences?",
    ],
  ];

  const questionNumber = [
    0,
    1,
    2,
    3,
    4,
    5,
    1,
    2,
    3,
    4,
    5,
    1,
    2,
    3,
    4,
    5,
    0,
    0,
    0,
  ];

  const quizResultsText = [
    [
      [
        `Focus on gratitude
        When the rewards are as good as UCLA's survey shows, it's certainly worth remembering what you have to be thankful for. Can you end / start each day by answering the question of what you have to be grateful for, I promise you a positive attitude. Is it that you have your health intact, a developing job, a wonderful partner, a wonderful house, a nice hobby, interests and so on.
        
        Continue to list things you have to be grateful for even after the course is complete.
        
        Focus on relationship.
        If you live in a relationship, start the day by giving them appreciation. Tell him that he is beautiful, amazing, attractive, lovely and that you are grateful to have him near you. Pull the relationship alternately and give each other respect and attention. You will get back more than you think. And a guaranteed better life.
        `,
      ],
      [
        `Focus on gratitude
        When the rewards are as good as UCLA's survey shows, it's certainly worth remembering what you have to be thankful for. Can you end / start each day by answering the question of what you have to be grateful for, I promise you a positive attitude. Is it that you have your health intact, a developing job, a wonderful partner, a wonderful house, a nice hobby, interests and so on.
        
        Continue to list things you have to be grateful for even after the course is complete.
        
        Focus on relationship.
        If you live in a relationship, start the day by giving them appreciation. Tell him that he is beautiful, amazing, attractive, lovely and that you are grateful to have him near you. Pull the relationship alternately and give each other respect and attention. You will get back more than you think. And a guaranteed better life.
        `,
      ],
      [
        `Focus on gratitude
        When the rewards are as good as UCLA's survey shows, it's certainly worth remembering what you have to be thankful for. Can you end / start each day by answering the question of what you have to be grateful for, I promise you a positive attitude. Is it that you have your health intact, a developing job, a wonderful partner, a wonderful house, a nice hobby, interests and so on.
        
        Continue to list things you have to be grateful for even after the course is complete.
        
        Focus on relationship.
        If you live in a relationship, start the day by giving them appreciation. Tell him that he is beautiful, amazing, attractive, lovely and that you are grateful to have him near you. Pull the relationship alternately and give each other respect and attention. You will get back more than you think. And a guaranteed better life.
        `,
      ],
      [
        `Focus on gratitude
        When the rewards are as good as UCLA's survey shows, it's certainly worth remembering what you have to be thankful for. Can you end / start each day by answering the question of what you have to be grateful for, I promise you a positive attitude. Is it that you have your health intact, a developing job, a wonderful partner, a wonderful house, a nice hobby, interests and so on.
        
        Continue to list things you have to be grateful for even after the course is complete.
        
        Focus on relationship.
        If you live in a relationship, start the day by giving them appreciation. Tell him that he is beautiful, amazing, attractive, lovely and that you are grateful to have him near you. Pull the relationship alternately and give each other respect and attention. You will get back more than you think. And a guaranteed better life.
        `,
      ],
      [
        `Focus on gratitude
        When the rewards are as good as UCLA's survey shows, it's certainly worth remembering what you have to be thankful for. Can you end / start each day by answering the question of what you have to be grateful for, I promise you a positive attitude. Is it that you have your health intact, a developing job, a wonderful partner, a wonderful house, a nice hobby, interests and so on.
        
        Continue to list things you have to be grateful for even after the course is complete.
        
        Focus on relationship.
        If you live in a relationship, start the day by giving them appreciation. Tell him that he is beautiful, amazing, attractive, lovely and that you are grateful to have him near you. Pull the relationship alternately and give each other respect and attention. You will get back more than you think. And a guaranteed better life.
        `,
      ],
    ],
    [
      [
        `Focus on the future.
        On your development, your projects, your dreams, your hopes that trigger your ambitions, and get you motivated and successful.
        
        Focus on your values
        What you value, what you believe in and feel you can stand for. The more you repeat your values ​​to yourself, the more they will shape your personality and help you on the path to Strong Results.        
        `,
      ],
      [
        `Focus on the future.
        On your development, your projects, your dreams, your hopes that trigger your ambitions, and get you motivated and successful.
        
        Focus on your values
        What you value, what you believe in and feel you can stand for. The more you repeat your values ​​to yourself, the more they will shape your personality and help you on the path to Strong Results.        
        `,
      ],
      [
        `Focus on the future.
        On your development, your projects, your dreams, your hopes that trigger your ambitions, and get you motivated and successful.
        
        Focus on your values
        What you value, what you believe in and feel you can stand for. The more you repeat your values ​​to yourself, the more they will shape your personality and help you on the path to Strong Results.        
        `,
      ],
      [
        `Focus on the future.
        On your development, your projects, your dreams, your hopes that trigger your ambitions, and get you motivated and successful.
        
        Focus on your values
        What you value, what you believe in and feel you can stand for. The more you repeat your values ​​to yourself, the more they will shape your personality and help you on the path to Strong Results.        
        `,
      ],
      [
        `Focus on the future.
        On your development, your projects, your dreams, your hopes that trigger your ambitions, and get you motivated and successful.
        
        Focus on your values
        What you value, what you believe in and feel you can stand for. The more you repeat your values ​​to yourself, the more they will shape your personality and help you on the path to Strong Results.        
        `,
      ],
    ],
    [
      [
        `Focus on your purpose
        Those who feel that they contribute to the well-being of others also feel better about themselves, because your purposes usually involve other people, and elevate you to something greater than yourself. Therefore, focus primarily on the purpose. Then purpose, goal and visions get wings.
        
        Focus on inspiration.
        A good tip is Youtube to put you in a stimulating state, repeat good behaviors and strengthen yourself. My favorites are Evan Carmichael, who has gathered the best rules of life for successful people.
        Wayne Dyer, who puts perspective on life, and Goalcast, who provide inspiration in everyday life.        
        `,
      ],
      [
        `Focus on your purpose
        Those who feel that they contribute to the well-being of others also feel better about themselves, because your purposes usually involve other people, and elevate you to something greater than yourself. Therefore, focus primarily on the purpose. Then purpose, goal and visions get wings.
        
        Focus on inspiration.
        A good tip is Youtube to put you in a stimulating state, repeat good behaviors and strengthen yourself. My favorites are Evan Carmichael, who has gathered the best rules of life for successful people.
        Wayne Dyer, who puts perspective on life, and Goalcast, who provide inspiration in everyday life.        
        `,
      ],
      [
        `Focus on your purpose
        Those who feel that they contribute to the well-being of others also feel better about themselves, because your purposes usually involve other people, and elevate you to something greater than yourself. Therefore, focus primarily on the purpose. Then purpose, goal and visions get wings.
        
        Focus on inspiration.
        A good tip is Youtube to put you in a stimulating state, repeat good behaviors and strengthen yourself. My favorites are Evan Carmichael, who has gathered the best rules of life for successful people.
        Wayne Dyer, who puts perspective on life, and Goalcast, who provide inspiration in everyday life.        
        `,
      ],
      [
        `Focus on your purpose
        Those who feel that they contribute to the well-being of others also feel better about themselves, because your purposes usually involve other people, and elevate you to something greater than yourself. Therefore, focus primarily on the purpose. Then purpose, goal and visions get wings.
        
        Focus on inspiration.
        A good tip is Youtube to put you in a stimulating state, repeat good behaviors and strengthen yourself. My favorites are Evan Carmichael, who has gathered the best rules of life for successful people.
        Wayne Dyer, who puts perspective on life, and Goalcast, who provide inspiration in everyday life.        
        `,
      ],
      [
        `Focus on your purpose
        Those who feel that they contribute to the well-being of others also feel better about themselves, because your purposes usually involve other people, and elevate you to something greater than yourself. Therefore, focus primarily on the purpose. Then purpose, goal and visions get wings.
        
        Focus on inspiration.
        A good tip is Youtube to put you in a stimulating state, repeat good behaviors and strengthen yourself. My favorites are Evan Carmichael, who has gathered the best rules of life for successful people.
        Wayne Dyer, who puts perspective on life, and Goalcast, who provide inspiration in everyday life.        
        `,
      ],
    ],
  ];

  /*
  useEffect(() => {
    console.log("quizProgress: " + quizProgress);
  }, [quizProgress]);
  */

  useEffect(() => {
    if (
      !partOneAnswer.includes(0) &&
      !partTwoAnswer.includes(0) &&
      !partThreeAnswer.includes(0) &&
      quizProgress > 15
    ) {
      setQuizDone(true);
    }
  }, [quizProgress]);

  function checkIfDone() {
    if (
      !partOneAnswer.includes(0) &&
      !partTwoAnswer.includes(0) &&
      !partThreeAnswer.includes(0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="test-container">
      <div className="meter-container">
        <Meter
          results={partOneResults}
          answers={partOneAnswer}
          colors={partsProp}
          number={0}
          quizDone={quizDone}
          active={
            (quizProgress > 0 && quizProgress < 6) || quizProgress === 16
              ? true
              : false
          }
        />
        <Meter
          results={partTwoResults}
          answers={partTwoAnswer}
          colors={partsProp}
          number={1}
          quizDone={quizDone}
          active={
            (quizProgress > 5 && quizProgress < 11) || quizProgress === 17
              ? true
              : false
          }
        />
        <Meter
          results={partThreeResults}
          answers={partThreeAnswer}
          colors={partsProp}
          number={2}
          quizDone={quizDone}
          active={
            (quizProgress > 10 && quizProgress < 16) || quizProgress === 18
              ? true
              : false
          }
        />
      </div>
      <div className="meter-button-container">
        <MeterButton
          title={"PREPARING"}
          quizProgress={quizProgress}
          active={
            (quizProgress > 0 && quizProgress < 6) ||
            quizProgress === 16 ||
            quizProgress === 0
              ? true
              : false
          }
          returnQuizProgressTo={[1, 16]}
          setQuizProgress={setQuizProgress}
          quizDone={quizDone}
          colors={quizStateColors[0]}
        />
        <MeterButton
          title={"CHARGING"}
          quizProgress={quizProgress}
          returnQuizProgressTo={[6, 17]}
          setQuizProgress={setQuizProgress}
          quizDone={quizDone}
          colors={quizStateColors[1]}
          active={
            (quizProgress > 5 && quizProgress < 11) ||
            quizProgress === 17 ||
            quizProgress === 0
              ? true
              : false
          }
        />
        <MeterButton
          title={"STIMULATING"}
          quizProgress={quizProgress}
          returnQuizProgressTo={[11, 18]}
          setQuizProgress={setQuizProgress}
          quizDone={quizDone}
          colors={quizStateColors[2]}
          active={
            (quizProgress > 10 && quizProgress < 16) ||
            quizProgress === 18 ||
            quizProgress === 0
              ? true
              : false
          }
        />
      </div>
      <div className="meter-progressbar-container">
        <Progressbar
          quizProgress={quizProgress}
          answers={partOneAnswer}
          startQuizProgress={1}
          setQuizProgress={setQuizProgress}
          colors={quizStateColors[0]}
        />
        <Progressbar
          quizProgress={quizProgress}
          answers={partTwoAnswer}
          startQuizProgress={6}
          setQuizProgress={setQuizProgress}
          colors={quizStateColors[1]}
        />
        <Progressbar
          quizProgress={quizProgress}
          answers={partThreeAnswer}
          startQuizProgress={11}
          setQuizProgress={setQuizProgress}
          colors={quizStateColors[2]}
        />
      </div>
      <div className="quiz-content-container">
        {quizProgress === 0 ? (
          <IntroductionJPI setQuizProgress={setQuizProgress} />
        ) : (
          ""
        )}
        {quizProgress === 16 ? (
          <Results
            title={"PREPARING"}
            resultsText={quizResultsText[0]}
            colors={quizStateColors[0]}
            answers={partOneAnswer}
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[0]}
            quizQuestionsStart={1}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartOneAnswer}
            answers={partOneAnswer}
          />
        ) : (
          ""
        )}
        {quizProgress === 17 ? (
          <Results
            title={"CHARGING"}
            resultsText={quizResultsText[2]}
            colors={quizStateColors[1]}
            answers={partTwoAnswer}
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[1]}
            quizQuestionsStart={6}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartTwoAnswer}
            answers={partTwoAnswer}
          />
        ) : (
          ""
        )}
        {quizProgress === 18 ? (
          <Results
            title={"STIMULATING"}
            resultsText={quizResultsText[1]}
            colors={quizStateColors[2]}
            answers={partThreeAnswer}
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[2]}
            quizQuestionsStart={11}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartThreeAnswer}
            answers={partThreeAnswer}
          />
        ) : (
          ""
        )}
        {quizProgress > 0 && quizProgress < 6 ? (
          <Questions
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[0]}
            quizQuestionsStart={1}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartOneAnswer}
            answers={partOneAnswer}
            colors={quizStateColors[0]}
            checkIfDone={checkIfDone}
          />
        ) : (
          ""
        )}
        {quizProgress > 5 && quizProgress < 11 ? (
          <Questions
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[1]}
            quizQuestionsStart={6}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartTwoAnswer}
            answers={partTwoAnswer}
            colors={quizStateColors[1]}
            checkIfDone={checkIfDone}
          />
        ) : (
          ""
        )}
        {quizProgress > 10 && quizProgress < 16 ? (
          <Questions
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[2]}
            quizQuestionsStart={11}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartThreeAnswer}
            answers={partThreeAnswer}
            colors={quizStateColors[2]}
            checkIfDone={checkIfDone}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default JPITest;
