import React, {useState, useEffect, useRef, useContext} from 'react'
import "./Reviews.css"
import { AppContext } from "./AppContext";



function Reviews() {

    var { db, languageSE, pageContent } = useContext(AppContext);

    const [listWithReviews, setListWithReviews] = useState([{name: "loading..."}])
    const [currentReview, setCurrentReview] = useState(0)
    const preReview = useRef(0);
    const [seconds, setSeconds] = useState(12)
    const [isActive, setIsActive] = useState(true)
    const [loadingReviews, setLoadingReviews] = useState(true)
    const [seReviews, setSEReviews] = useState([])
    const [enReviews, setENReviews] = useState([])

    
    function isActiveFalse(){
        setIsActive(false)
        document.getElementById("review-timer").classList.add("pause-timer")
        document.getElementById("review-timer-pause-sign").classList.add("timer-pause-sign-show")
    }

    function isActiveTrue(){
        setIsActive(true)
        document.getElementById("review-timer").classList.remove("pause-timer")
        document.getElementById("review-timer-pause-sign").classList.remove("timer-pause-sign-show")
    }

    useEffect(()=>{
        const reviewDiv = document.getElementById("review-text-contet")
        
        reviewDiv.addEventListener("mouseover", isActiveFalse)
        reviewDiv.addEventListener("mouseout", isActiveTrue)

        reviewDiv.addEventListener("touchstart", isActiveFalse)
        reviewDiv.addEventListener("touchend", isActiveTrue)
        return ()=>{
            setIsActive(true)
            reviewDiv.removeEventListener("mouseover", isActiveFalse)
            reviewDiv.removeEventListener("mouseover", isActiveTrue)
            reviewDiv.removeEventListener("touchstart", isActiveFalse)
            reviewDiv.removeEventListener("touchend", isActiveTrue)
        }
    },[])

    useEffect(()=>{
        let interval = null
        if(isActive && seconds > 0){
            interval = setInterval(()=>{
                setSeconds(seconds => seconds -1)
            }, 1000) 
        }else if(isActive && seconds <= 0){
            handleCurrentChange(1)
            setSeconds(12)
        }
        
        else if(!isActive && seconds !== 0){
            clearInterval(interval)
        }
        return () => {clearInterval(interval)} 
    },[seconds, isActive])
    

    useEffect(()=>{
       var temp =[]
        db.collection("reviewsSE")
        .get()
        .then((reviews)=>{
            reviews.forEach(doc => {
                temp.push(doc.data())
            });
            temp = temp.sort(()=>Math.random()-0.5)
            setSEReviews(temp)
            if(languageSE){
                setListWithReviews(temp)
            }
            
        }).catch((e)=>{
            console.log("error retriving review", e)
        })

        var temp2 =[]
        db.collection("reviewsEN")
        .get()
        .then((reviews)=>{
            reviews.forEach(doc => {
                temp2.push(doc.data())
            });
            setENReviews(temp2)
            if(!languageSE){
                temp2 = temp2.sort(()=>Math.random()-0.5)
                setListWithReviews(temp2)
            }
        }).then(()=>{
            setLoadingReviews(false)
        }).catch((e)=>{
            console.log("error retriving review", e)
        })
    },[])

    useEffect(()=>{
        setCurrentReview(0)
        if(languageSE && !loadingReviews){
            setListWithReviews(seReviews)
        }else if(!languageSE && !loadingReviews){
            setListWithReviews(enReviews)
        }
    },[languageSE])

    /*
    useEffect(()=>{
        seReviews.forEach((d)=>{
            console.log(d.name)
        })
    },[seReviews])
*/
    useEffect(()=>{
        //console.log("new: " + currentReview + " old: ", preReview.current)
        if(preReview !== currentReview){
            preReview.current = currentReview;
        }

        var newID = "activeQ"+currentReview 
        var oldID = "activeQ"+preReview.current   
        //document.getElementById(newID).classList.remove("toCurrrentAnimation")
        document.getElementById(newID).classList.add("toCurrrentAnimation")
        
    },[currentReview])


    function getContentDot(){
        var content = listWithReviews.map((element, index) => {
             return <div key={"qkey"+index}  id={"q"+index} className={index == currentReview ? "dot dot-active" : "dot" }
             onClick={()=>{handelCurrentClickChange(index)}}/>
          })
     
          return content
     }
//https://www.youtube-nocookie.com/embed/
     function getReview(){

        var sw = window.innerWidth - 20
        var sh;
        if(sw < 560){
            sh = sw/(16/9)
        }else{
            sw = 560
            sh = 315
        }
        return <div  key={"activeKey"+currentReview} id={"activeQ"+currentReview} className="review-content-container" 
        onClick={()=>{handleCurrentChange(1)}}> 
        {listWithReviews[currentReview].video ? 
                <div>
                    <iframe width={sw} height={sh} src={"https://www.youtube-nocookie.com/embed/"+listWithReviews[currentReview].videoURL} 
                    frameBorder="0" 
                    allow="encrypted-media; clipboard-write;" 
                    allow="fullscreen">

                    </iframe>
                </div>
            :
                <div id="review-text-id" className="review-text">
                    <span className="review-quote-sign">“</span>
                    {listWithReviews[currentReview].text}
                    <span className="review-quote-sign">”</span>
                </div>
            }
            
            <div className="review-timer-contatiner">
                <div id="review-timer-pause-sign" className="timer-pause-sign">||</div>
                <div id="review-timer" className="review-timer-bar"/> 
            </div>
            <div className="review-name">- {listWithReviews[currentReview].name}</div>
            <div className="review-title">{listWithReviews[currentReview].title}</div>
            
        </div>
     }

    function handleCurrentChange(add){
        var newValue = currentReview + add
        if(newValue >= 0 && newValue < listWithReviews.length){
            setCurrentReview(newValue)
        }else if(newValue<0) {
            setCurrentReview(listWithReviews.length-1)
        }else if(newValue == listWithReviews.length){
            setCurrentReview(0)
        }
        setSeconds(12)
    }
    
    function handelCurrentClickChange(index){
        //setIsActive(false)
        setCurrentReview(index)
        setSeconds(12)
    }

    return (
        <div className="reviews-container">
            <div className="reviews-carousel-container">
            <div className="review-title-text">{pageContent.sectionThree001}</div>
                <div id="review-text-contet">
                {getReview()}     
                </div>       
                <div className="dot-container" >
                    {getContentDot()}
                </div>
            </div>
        </div>
    )
}

export default Reviews

//<div className="review-arrow" onClick={()=>handleCurrentChange(-1)}>{"<"}</div>
//<div className="review-arrow" onClick={()=>handleCurrentChange(1)}>{">"}</div>