import React, { useEffect, useContext } from "react";
import { AppContext } from "./AppContext";
import "./evaluation.css";
import "./evaluation-mobile.css";
import Footer from "./Footer";


import Varvtalsraknaren from "./Varvtalsraknaren";

function Evaluation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var { pageContent, languageSE } = useContext(AppContext);
  return (
    <div>
      <div className="evaluation-section-one">
        <div className="evaluation-section-one-intro">
          <span className="first-word">{pageContent.speed001} </span>
          <span> {pageContent.speed002}</span>
        </div>
      </div>
      <div className="evaluation-section-two">
        <Varvtalsraknaren />
      </div>
      <div className="evaluation-section-three"> 
        <div className="section-three-divider"/>
        <div className="section-three-more-info">{pageContent.speed003}</div>
        <div className="section-three-button" onClick={()=>{
          var url = languageSE ? "https://starkaresultat.se/mentalbudget" : "https://starkaresultat.se/en/mentalbudget";
          window.open(url ,'_blank')}}>{pageContent.speed004}</div>
        <div className="section-three-button" onClick={()=>{
          var url = languageSE ? "https://karaktarsmatrisen.starkaresultat.se" : "https://charactermatrix.strongresults.se"
          window.open(url ,'_blank')}}>{pageContent.speed005}</div>
        <div className="section-three-button" onClick={()=>{
          var url = languageSE ? "http://www.starkaresultat.se/form/attitydindikator" : "http://www.starkaresultat.se/form/attitudeindicator";
          window.open(url ,'_blank')}}>{pageContent.speed006}</div>
      </div>
      
      <Footer />
    </div>
  );
}

export default Evaluation;
