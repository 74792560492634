import React, { useEffect } from "react";
import "./FormMeter.css";

function FormMeter(props) {
  var colors = { backgroundColor: "#6E7F89", buttonColor: "#304857" };

  useEffect(() => {
    if (props.quizDone) {
      handleTransition();
    }
  }, [props.quizDone, props.points]);

  function handleTransition() {
    var sum = props.points;
    //console.log("sum:" + sum);
    var speedFillP = sum;
    var fillValue =
      strokeDashoffsetSVG +
      ((strokeLenghtSVG - strokeDashoffsetSVG) / 100) * (100 - speedFillP);

    //225deg -> 495
    //1% = 2,7deg
    //75% = 202,5
    //135*2*0,75+225

    var needleRotation = 225 + 270 * (sum / 100);

    document.getElementById("FormSpeedFill").style.strokeDashoffset = fillValue;
    document.getElementById(
      "FormNeedle"
    ).style.transform = `rotate(${needleRotation}deg)`;
  }

  var sizeSVG = 300;
  var speedWidthSVG = (sizeSVG * 2) / 15;
  var viewBoxSVG = `0 0 ${sizeSVG} ${sizeSVG}`;
  var transformOriginSVG = `${sizeSVG / 2}px ${sizeSVG / 2}px`;
  var rotateSpeedSVG = `rotate(-225deg)`;
  var strokeLenghtSVG = (sizeSVG - speedWidthSVG) * 3.141592654;
  var strokeDashoffsetSVG = strokeLenghtSVG - strokeLenghtSVG * 0.75;

  var speedFillP = 0;
  var speedFillOffsetSVG =
    strokeDashoffsetSVG +
    ((strokeLenghtSVG - strokeDashoffsetSVG) / 100) * (100 - speedFillP);

  var dSVG = `M ${sizeSVG / 2 - sizeSVG / 120} ${sizeSVG / 120} l ${
    -0.025 * sizeSVG
  } ${sizeSVG / 2 - sizeSVG / 120} c 0 ${sizeSVG / 20} ${sizeSVG / 15} ${
    sizeSVG / 20
  } ${sizeSVG / 15} 0 l ${-0.025 * sizeSVG} -${
    sizeSVG / 2 - sizeSVG / 120
  } c 0 ${-sizeSVG / 120} ${(-sizeSVG / 120) * 2} ${-sizeSVG / 120} ${
    (-sizeSVG / 120) * 2
  } 0 Z`;
  return (
    <div
      className="form-svg-container"
      style={{ width: "100%", height: "100%" }}
    >
      <svg viewBox={viewBoxSVG}>
        <circle
          id="FormSpeed"
          cx={sizeSVG / 2}
          cy={sizeSVG / 2}
          r={sizeSVG / 2 - speedWidthSVG / 2}
          style={{
            transformOrigin: transformOriginSVG,
            transform: rotateSpeedSVG,
            strokeDasharray: strokeLenghtSVG,
            strokeWidth: speedWidthSVG,
            strokeDashoffset: strokeDashoffsetSVG,
          }}
        />
        <rect x={149} y={40} width={2} height={10} fill="#e6e6e6" />
        <rect
          x={149}
          y={40}
          width={2}
          height={10}
          fill="#e6e6e6"
          style={{
            transformOrigin: transformOriginSVG,
            transform: "rotate(-67.5deg)",
          }}
        />
        <rect
          x={149}
          y={40}
          width={2}
          height={10}
          fill="#e6e6e6"
          style={{
            transformOrigin: transformOriginSVG,
            transform: "rotate(67.5deg)",
          }}
        />
        <rect
          x={sizeSVG / 2 - 1}
          y={speedWidthSVG}
          width={sizeSVG / 150}
          height={sizeSVG / 30}
          fill="#e6e6e6"
          style={{
            transformOrigin: transformOriginSVG,
            transform: "rotate(-134.6deg)",
          }}
        />
        <rect
          x={149}
          y={40}
          width={2}
          height={10}
          fill="#e6e6e6"
          style={{
            transformOrigin: transformOriginSVG,
            transform: "rotate(134.6deg)",
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="567.453"
          height="86.846"
          x="80"
          y="100"
          viewBox="0 0  2269.812 347.384"
        >
          <g
            id="Group_184"
            data-name="Group 184"
            transform="translate(-14.375 -40.504)"
          >
            <g id="Group_181" data-name="Group 181">
              <path
                id="Path_94"
                data-name="Path 94"
                d="M15,68.891a.552.552,0,0,1-.624-.624v-1.54A.582.582,0,0,1,15,66.061H41.265c2,0,2.539-.916,2.539-2.082,0-1.29-.5-1.914-2.123-2.539l-22.769-8.7c-3.08-1.166-4.412-3.289-4.412-6.661,0-2.955,2.248-5.577,6.493-5.577H50.381c.416,0,.624.208.624.666v1.5a.552.552,0,0,1-.624.624H25.156c-2,0-2.539.958-2.539,2.082,0,1.373.5,1.956,2.081,2.58l22.811,8.659c3.08,1.207,4.371,3.288,4.371,6.66,0,3-2.207,5.619-6.452,5.619Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_95"
                data-name="Path 95"
                d="M69.241,68.891c-.333,0-.458-.125-.458-.374V43.291H53.923c-.333,0-.458-.083-.458-.333V40.877c0-.25.125-.374.458-.374h38.3c.333,0,.458.124.458.374v2.081c0,.25-.125.333-.458.333H77.4V68.517q0,.374-.5.374Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_96"
                data-name="Path 96"
                d="M128.9,68.891a1.4,1.4,0,0,1-1.249-.583l-19.814-22.02-17.9,19.773h27.015a.594.594,0,0,1,.666.666v1.54c0,.416-.208.624-.666.624H85.437c-.375,0-.666-.208-.666-.541a.933.933,0,0,1,.25-.624l24.6-26.6a1.483,1.483,0,0,1,1.249-.624h1.373a1.627,1.627,0,0,1,1.332.624l24.476,26.932a.661.661,0,0,1,.208.416c0,.25-.166.416-.583.416Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_97"
                data-name="Path 97"
                d="M177.061,68.891a3.214,3.214,0,0,1-1.832-.5L152.876,55.363a.966.966,0,0,1-.458-.916v-.791a.552.552,0,0,1,.624-.624h16.443c3.372,0,5.411-1.457,5.411-5,0-3.5-2.123-4.746-5.369-4.746H149.3V68.267a.552.552,0,0,1-.624.624h-7.326a.582.582,0,0,1-.666-.624V41.585c0-.708.333-1.082,1.04-1.082h33.634c4.7,0,8.159,2.164,8.159,7.534,0,4.953-3.455,7.742-8.159,7.742H163.116l23.436,12.2a.613.613,0,0,1,.374.5c0,.25-.208.416-.583.416Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_98"
                data-name="Path 98"
                d="M190.177,68.891a.582.582,0,0,1-.666-.624v-27.1a.594.594,0,0,1,.666-.666H197.5c.417,0,.625.208.625.666v27.1a.552.552,0,0,1-.625.624Zm39.462,0a4.087,4.087,0,0,1-2.331-.624l-25.891-13.82c-.292-.166-.417-.5-.417-1.04v-.833c0-.541.083-.791.458-.916L228.1,40.836a2.976,2.976,0,0,1,1.5-.333h4.5c.416,0,.582.166.582.416a.537.537,0,0,1-.374.541L208.285,52.033l28.472,15.609a.827.827,0,0,1,.582.75q0,.5-.749.5Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_99"
                data-name="Path 99"
                d="M281.8,68.891a1.4,1.4,0,0,1-1.249-.583l-19.814-22.02-17.9,19.773h27.016a.594.594,0,0,1,.666.666v1.54c0,.416-.208.624-.666.624H238.343c-.375,0-.666-.208-.666-.541a.931.931,0,0,1,.249-.624l24.6-26.6a1.484,1.484,0,0,1,1.249-.624h1.374a1.628,1.628,0,0,1,1.332.624l24.476,26.932a.661.661,0,0,1,.208.416c0,.25-.166.416-.583.416Z"
                fill="#e1e0e0"
              />
            </g>
            <g id="Group_182" data-name="Group 182">
              <path
                id="Path_100"
                data-name="Path 100"
                d="M159.339,121.8a4.811,4.811,0,0,1-2.7-.716l-32.9-18.659a1.374,1.374,0,0,1-.674-1.312V99.981a.8.8,0,0,1,.919-.9h24.2c4.962,0,7.964-2.087,7.964-7.154,0-5.008-3.124-6.8-7.9-6.8H118.476v35.77a.8.8,0,0,1-.919.894H106.774c-.612,0-.98-.3-.98-.894V82.691a1.362,1.362,0,0,1,1.532-1.55h49.5c6.923,0,12.008,3.1,12.008,10.791,0,7.1-5.085,11.089-12.008,11.089H138.816l34.491,17.468a.875.875,0,0,1,.552.714c0,.359-.307.6-.858.6Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_101"
                data-name="Path 101"
                d="M191.811,121.8c-10.783,0-18.624-8.525-18.624-20.329,0-11.685,7.474-20.33,18.624-20.33H230.9c.613,0,.919.3.919.955v2.146a.8.8,0,0,1-.919.894H198.979c-7.475,0-11.7,5.067-11.7,16.335s4.227,16.275,11.7,16.275H231.02a.844.844,0,0,1,.918.954v2.206a.8.8,0,0,1-.918.894Zm1.654-20.21a.844.844,0,0,1-.919-.954V98.49c0-.656.306-.954.919-.954h36.942c.674,0,.98.239.98.656v2.742c0,.418-.306.656-.98.656Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_102"
                data-name="Path 102"
                d="M238.5,121.8a.8.8,0,0,1-.919-.894V118.7a.844.844,0,0,1,.919-.954h38.657c2.941,0,3.737-1.311,3.737-2.981,0-1.848-.735-2.742-3.124-3.637L244.254,98.669c-4.533-1.67-6.494-4.71-6.494-9.539,0-4.233,3.309-7.989,9.557-7.989H290.57c.613,0,.919.3.919.955v2.146a.8.8,0,0,1-.919.894H253.444c-2.941,0-3.737,1.371-3.737,2.981,0,1.967.735,2.8,3.063,3.7l33.573,12.4c4.533,1.729,6.432,4.71,6.432,9.538,0,4.293-3.247,8.049-9.495,8.049Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_103"
                data-name="Path 103"
                d="M316.607,121.8c-13.967,0-19.3-7.273-19.3-18.72V82.1c0-.656.307-.955.919-.955h11.7c.613,0,.919.3.919.955V103.08c0,9.062,3.921,14.666,13.417,14.666h16.051c9.19,0,13.478-6.14,13.478-14.666V82.1c0-.656.307-.955.919-.955h3.921c.674,0,.98.3.98.955V103.08c0,10.433-6.127,18.72-19.3,18.72Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_104"
                data-name="Path 104"
                d="M381.428,121.8c-11.211,0-16.541-7.035-16.541-16.811V82.1c0-.656.245-.955.919-.955h12.007a.846.846,0,0,1,.919.955v23.429c0,7.69,3.186,12.221,10.722,12.221h29.713a.845.845,0,0,1,.919.954v2.206a.8.8,0,0,1-.919.894Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_105"
                data-name="Path 105"
                d="M434.055,121.8c-.491,0-.674-.179-.674-.537V85.136H411.51c-.49,0-.674-.12-.674-.477V81.678c0-.358.184-.537.674-.537h56.362c.49,0,.674.179.674.537v2.981c0,.357-.184.477-.674.477h-21.81v36.127q0,.537-.735.537Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_106"
                data-name="Path 106"
                d="M521.849,121.8a2.079,2.079,0,0,1-1.838-.835L490.849,89.428l-26.344,28.318h39.761a.863.863,0,0,1,.98.954v2.206c0,.6-.307.894-.98.894H457.889c-.552,0-.981-.3-.981-.775a1.321,1.321,0,0,1,.368-.9l36.207-38.095a2.206,2.206,0,0,1,1.838-.894h2.022a2.426,2.426,0,0,1,1.96.894l36.023,38.573a.937.937,0,0,1,.307.6c0,.359-.245.6-.858.6Z"
                fill="#e1e0e0"
              />
              <path
                id="Path_107"
                data-name="Path 107"
                d="M547.337,121.8c-.49,0-.674-.179-.674-.537V85.136H524.792c-.49,0-.674-.12-.674-.477V81.678c0-.358.184-.537.674-.537h56.362c.491,0,.674.179.674.537v2.981c0,.357-.183.477-.674.477H559.345v36.127q0,.537-.736.537Z"
                fill="#e1e0e0"
              />
            </g>
            <g id="Group_183" data-name="Group 183">
              <circle
                id="Ellipse_90"
                data-name="Ellipse 90"
                cx="25.624"
                cy="25.624"
                r="25.624"
                transform="translate(46.374 74.602)"
                fill="none"
                stroke="#e1e0e0"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <circle
                id="Ellipse_91"
                data-name="Ellipse 91"
                cx="16.106"
                cy="16.106"
                r="16.106"
                transform="translate(55.893 84.121)"
                fill="none"
                stroke="#e1e0e0"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <circle
                id="Ellipse_92"
                data-name="Ellipse 92"
                cx="7.212"
                cy="7.212"
                r="7.212"
                transform="translate(64.786 93.014)"
                fill="none"
                stroke="#e1e0e0"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
            </g>
          </g>
        </svg>

        <circle
          id="FormSpeedFill"
          cx={sizeSVG / 2}
          cy={sizeSVG / 2}
          r={sizeSVG / 2 - speedWidthSVG / 2}
          style={{
            transformOrigin: transformOriginSVG,
            transform: rotateSpeedSVG,
            strokeDasharray: strokeLenghtSVG,
            strokeWidth: speedWidthSVG,
            strokeDashoffset: speedFillOffsetSVG,
            stroke: colors.backgroundColor,
          }}
        />
        <text
          x={sizeSVG * 0.7}
          y={sizeSVG * 0.72}
          fill="#e6e6e6"
          fontSize="15"
          textAnchor="middle"
          fontWeight="bold"
        >
          100
        </text>
        <text
          x={sizeSVG * 0.3}
          y={sizeSVG * 0.72}
          fill="#e6e6e6"
          fontSize="15"
          textAnchor="middle"
          fontWeight="bold"
        >
          0
        </text>

        <path
          id="FormNeedle"
          d={dSVG}
          style={{
            strokeWidth: "1",
            transformOrigin: transformOriginSVG,
            fill: colors.buttonColor,
          }}
        />

        <text
          x={sizeSVG / 2}
          y={sizeSVG * 0.85}
          fill={colors.buttonColor}
          fontSize="80"
          textAnchor="middle"
          fontWeight="bold"
        >
          {props.quizDone ? props.points : "-"}
        </text>
      </svg>
    </div>
  );
}

export default FormMeter;
