import React, { useContext } from "react";
import { AppContext } from "./AppContext";

function Introduction(props) {
  var { languageSE } = useContext(AppContext);
  return (
    <div className="quiz-intro-container">
      <div className="quiz-intro-title">
        {languageSE
          ? "Varvtalsräknaren skapar förutsättningar för Starka resultat"
          : "The speed indicator evaluate conditions for strong results"}
      </div>
      <div className="quiz-intro-instructions">
        {languageSE
          ? "För varje av de 3 kommande delarna, välj din självskattningspoäng (lägst 1, högst 5). Det kommer ge dig en poäng utifrån 100%. Oavsett din poäng, försök att förbättra dig själv på dessa områden för de har en stor påverkan på din framtida framgång och leverans av starka resultat."
          : "For each of the 3 parts below, add your self-assessment score and multiply by 4 (minimum 1, maximum 5). It will give you a score based on 100%. Regardless of your score, try to improve yourself in these areas because they have a huge impact on your future success and delivery of strong results."}
      </div>
      <div
        className="quiz-intro-button"
        onClick={() => props.setQuizProgress(1)}
      >
        {languageSE ? "Starta" : "Start"}
      </div>
    </div>
  );
}

export default Introduction;
