import React, { useContext } from "react";
import { AppContext } from "./AppContext";
import "./ContentOne.css";
import strategiSession from "./documents/Strategi Session - Frågeformulär.pdf";
import strategiSessionEN from "./documents/Strategy-Session-Questionnaire.pdf";

function ContentOne() {
  var { pageContent, languageSE } = useContext(AppContext);
  return (
    <div id="ContentOne" className="contentone-container">
      <div>
        <div className="content-main-title">
          {pageContent.contentOneMainTitle}
        </div>
        <div className="content-sub-title">{pageContent.contentOne001}</div>
        <div className="content-sub-title">{pageContent.contentOne002}</div>
        <div className="content-running-text">{pageContent.contentOne003}</div>
        <br />
        <div className="content-running-text">{pageContent.contentOne004}</div>
        <br />
        <div className="content-running-text">{pageContent.contentOne005}</div>
        <ul>
          <li>{pageContent.contentOne006}</li>
          <li>{pageContent.contentOne007}</li>
          <li>{pageContent.contentOne008}</li>
          <li>{pageContent.contentOne009}</li>
          <li>{pageContent.contentOne010}</li>
        </ul>
        <br />
        <div className="content-sub-title">{pageContent.contentOne011}</div>
        <div>{pageContent.contentOne012}</div>
        <br />
        <div className="content-bold">{pageContent.contentOne013}</div>
        <br />
        <div className="content-running-text">{pageContent.contentOne014}</div>
        <br />
        <div className="content-bold">{pageContent.contentOne015}</div>

        <div>
          <span className="content-bold">{pageContent.contentOne016}</span>{" "}
          {pageContent.contentOne017}
        </div>
        <br />
        <div className="content-main-title">{pageContent.contentOne018}</div>

        <div className="content-sub-title">{pageContent.contentOne019}</div>
        <div className="content-running-text">{pageContent.contentOne020}</div>
        <br />
        <div className="content-quote">{pageContent.contentOne021}</div>
        <div className="content-quote-auth">{pageContent.contentOne022}</div>
        <br />
        <div className="content-sub-information">
          {pageContent.contentOne023}
        </div>
        <a
          href={languageSE ? strategiSession : strategiSessionEN}
          download
          rel="noopener noreferrer"
        >
          <div className="button-download">
            {pageContent.contentOne024}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.803"
              height="17.991"
              viewBox="0 0 13.803 17.991"
              id="svg-download"
            >
              <g
                id="Group_179"
                data-name="Group 179"
                transform="translate(23981.501 13339.522)"
              >
                <line
                  id="Line_14"
                  data-name="Line 14"
                  y2="10.914"
                  transform="translate(-23974.598 -13338.522)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_15"
                  data-name="Line 15"
                  x1="11.803"
                  transform="translate(-23980.5 -13322.531)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_93"
                  data-name="Path 93"
                  d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                  transform="translate(-1.042 -6.535)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </div>
        </a>
      </div>
    </div>
  );
}

export default ContentOne;
