import React from "react";

function IntroductionGPI(props) {
  return (
    <div className="quiz-intro-container">
      <div className="quiz-intro-title">
        Glädje Preparerings Indikatorn för Starka Resultat (GPI)
      </div>
      <div className="quiz-intro-instructions">
        Instrumentet för att identifiera delarna som skapar glädje, samtidigt
        som du tar reda på ditt nuläge och förbättringspotential. För varje
        område, lägg till din självskattningspoäng och multiplicera med 4 (lägst
        1, högst 5). Det kommer ge dig en poäng utifrån 100 %.
      </div>
      <div
        className="quiz-intro-button"
        onClick={() => props.setQuizProgress(1)}
      >
        Starta
      </div>
    </div>
  );
}

export default IntroductionGPI;
