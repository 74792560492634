import "./ContentTwo.css";
import React, { useContext } from "react";
import { AppContext } from "./AppContext";
import vinnandeTeam from "./documents/Vinnande Team.pdf";
import flyDinaUndanflykter from "./documents/Fly dina undanflykter.pdf";
import vinnandeFörsäljning from "./documents/Vinnande Försäljning.pdf";

import vinnandeTeamEN from "./documents/Winning Team.pdf";
import flyDinaUndanflykterEN from "./documents/Escape your evasions.pdf";
import vinnandeFörsäljningEN from "./documents/Winning sales.pdf";
function ContentTwo() {
  var { pageContent, languageSE } = useContext(AppContext);
  return (
    <div id="ContentTwo" className="contenttwo-container">
      <div className="content-main-title">{pageContent.contentTwo001}</div>
      <br />
      <div className="content-sub-title">{pageContent.contentTwo002}</div>
      <ul>
        <li>{pageContent.contentTwo003}</li>
        <div className="content-list-extra">
          <a
            href={languageSE ? flyDinaUndanflykter : flyDinaUndanflykterEN}
            download
            rel="noopener noreferrer"
          >
            <div className="button-download-two">
              {pageContent.contentTwoDownloadPdf}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.803"
                height="17.991"
                viewBox="0 0 13.803 17.991"
                className="svg-download-two"
              >
                <g
                  id="Group_179"
                  data-name="Group 179"
                  transform="translate(23981.501 13339.522)"
                >
                  <line
                    id="Line_14"
                    data-name="Line 14"
                    y2="10.914"
                    transform="translate(-23974.598 -13338.522)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_15"
                    data-name="Line 15"
                    x1="11.803"
                    transform="translate(-23980.5 -13322.531)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                    transform="translate(-1.042 -6.535)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
          </a>{" "}
        </div>
        <br />
        <li>{pageContent.contentTwo004}</li>
        <div className="content-list-extra">
          {pageContent.contentTwo005}
          <a
            href={languageSE ? vinnandeTeam : vinnandeFörsäljningEN}
            download
            rel="noopener noreferrer"
          >
            <div className="button-download-two">
              {pageContent.contentTwoDownloadPdf}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.803"
                height="17.991"
                viewBox="0 0 13.803 17.991"
                className="svg-download-two"
              >
                <g
                  id="Group_179"
                  data-name="Group 179"
                  transform="translate(23981.501 13339.522)"
                >
                  <line
                    id="Line_14"
                    data-name="Line 14"
                    y2="10.914"
                    transform="translate(-23974.598 -13338.522)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_15"
                    data-name="Line 15"
                    x1="11.803"
                    transform="translate(-23980.5 -13322.531)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                    transform="translate(-1.042 -6.535)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
          </a>{" "}
        </div>
        <br />
        <li>{pageContent.contentTwo006}</li>
        <div className="content-list-extra">
          {pageContent.contentTwo005}
          <a
            href={languageSE ? vinnandeFörsäljning : vinnandeFörsäljningEN}
            download
            rel="noopener noreferrer"
          >
            <div className="button-download-two">
              {pageContent.contentTwoDownloadPdf}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.803"
                height="17.991"
                viewBox="0 0 13.803 17.991"
                className="svg-download-two"
              >
                <g
                  id="Group_179"
                  data-name="Group 179"
                  transform="translate(23981.501 13339.522)"
                >
                  <line
                    id="Line_14"
                    data-name="Line 14"
                    y2="10.914"
                    transform="translate(-23974.598 -13338.522)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_15"
                    data-name="Line 15"
                    x1="11.803"
                    transform="translate(-23980.5 -13322.531)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                    transform="translate(-1.042 -6.535)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
          </a>
          <br />
        </div>
      </ul>
      <div className="content-list-extra">{pageContent.contentTwo007}</div>
      <div className="content-list-extra">{pageContent.contentTwo008}</div>
      <br />
    </div>
  );
}

export default ContentTwo;
