import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "./AppContext";
import "./WebForm.css";
import logo from "../images/strongresults-logo.png";
import logoPrint from "../images/strongresults-logo-black.png";
import FormMeterMentalBudget from "./FormMeterMentalBudget";

function MentalBudgetEN() {
  const { setOnAdminPage } = useContext(AppContext);
  const [formAnswers, setFormAnswers] = useState([9, 9, 9, 9, 9, 9, 9]);
  const [quizDone, setQuizDone] = useState(false);
  const [quizResults, setQuizResults] = useState(0);

  useEffect(() => {
    //window.scrollTo(0, 0);
    setOnAdminPage(true);
  });

  useEffect(() => {
    //formAnswers.includes(0);
    if (!formAnswers.includes(9)) {
      var points = getResults();
      setQuizDone(true);
      setQuizResults(points);
    }

    /*
if (!formAnswers.includes(0)) {
      var points = getResults();
      setQuizDone(true);
      setQuizResults(points);
    }
    */
  }, [formAnswers]);

  function getResults() {
    var temp = [...formAnswers];
    var sum = temp.reduce((a, b) => a + b, 0);
    var MaxPoints = 2 * formAnswers.length;
    var points = Math.round((sum / MaxPoints) * 50);
    //var points = Math.round(100 * (sum / (5 * formAnswers.length)));
    //console.log(points, formAnswers);
    return points;
  }

  function CheckBoxes(index, min, middle, max) {
    return (
      <div className="form-checkbox-container">
        {CheckBox(-2, min, formAnswers[index] === -2, index)}
        {CheckBox(-1, "", formAnswers[index] === -1, index)}
        {CheckBox(0, middle, formAnswers[index] === 0, index)}
        {CheckBox(1, "", formAnswers[index] === 1, index)}
        {CheckBox(2, max, formAnswers[index] === 2, index)}
      </div>
    );
  }
  function CheckBox(questionNumber, scale, value, questionIndex = 0) {
    return (
      <div className="form-checkbox">
        <div
          className="form-circle"
          onClick={(e) => {
            //console.log("funk: ", questionNumber, questionIndex);
            handleCheck(e);
          }}
          data-questionindex={questionIndex}
          data-value={questionNumber}
        >
          <div
            className={value ? "form-checked" : "form-checked form-unchecked"}
          />
        </div>
        <div>{scale}</div>
      </div>
    );
  }
  function handleCheck(e) {
    var temp = [...formAnswers];
    temp[e.currentTarget.dataset.questionindex] = parseInt(
      e.currentTarget.dataset.value
    );
    setFormAnswers(temp);
  }

  return (
    <div className="form-container">
      <div className="form-page">
        <div className="logo-container">
          <img id="logo-white" src={logo} alt="Starka Resultat" />
          <img id="logo-black" src={logoPrint} alt="Starka Resultat" />
        </div>
        <div className="title-container">Mental Budget</div>
        <div className="form-instructions-container">
        The tool for identifying the parts that build mental strength, while finding out about your current situation and potential for improvement.
          <br style={{ marginTop: "0.5em" }} />
          For each area, add your self-assessment score to five levels. We find the balance in the middle, the Credit page (-) on the left, and the Debit page (+) on the right.
          <br style={{ marginTop: "0.5em" }} />
          It will give you a score based on balance on the plus (green) or minus side (red). Regardless of your score, try to improve yourself in the following areas, all of which have a significant impact on future success. And the delivery of strong results.
          <br style={{ marginTop: "0.5em" }} />
          As I experience and feel today, I estimate myself on the following parts, on the scale:
        </div>

        <div className="form-all-questions-container">
          <div className="form-devider" />
          <div className="form-question-container">
            <div className="form-question-title">
            Exercise (including walking)
            </div>
            <div className="form-question-text">
            How many times a week do you usually do any type of physical activity?
            </div>
            {CheckBoxes(0, "0", "4", "5+")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">Sleep</div>
            <div className="form-question-text">
            How many hours per night do you sleep on average during a week?
            </div>
            {CheckBoxes(1, "0-4", "7", "8+")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">Diet</div>
            <div className="form-question-text">
            To what extent do you plan your diet?
            </div>
            {CheckBoxes(2, "Not at all", "Sometimes", "Often")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">People</div>
            <div className="form-question-text">
            To what extent do you associate with people who lift you up, versus pull you down?
            </div>
            {CheckBoxes(3, "Not at all", "Sometimes", "Often")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">
            Competence - See / listen / read
            </div>
            <div className="form-question-text">
            To what extent do you invest competence in yourself on average per day?
            </div>
            {CheckBoxes(4, "0h", "30min", "1h+")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">Relationship</div>
            <div className="form-question-text">
            To what extent do you feel that you give your closest relationship appreciation?
            </div>
            {CheckBoxes(5, "Never", "Occasionally", "Every day")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">Action / Procrastination</div>
            <div className="form-question-text">
            To what extent do you find yourself action-oriented / action-packed, versus postponing things until later?
            </div>
            {CheckBoxes(6, "Low", "Average", "High")}
          </div>
        </div>

        <div className="form-meter-container">
          <div className="form-meter-size">
            <FormMeterMentalBudget points={quizResults} quizDone={quizDone} />
          </div>
        </div>
      </div>
      <div
        className="form-print-button"
        onClick={() => {
          window.print();
        }}
      >
        Print
      </div>
    </div>
  );
}

export default MentalBudgetEN;

/*

 <div className="form-question-container">
            <div className="form-question-title">Träning (inklusive promenader)</div>
            <div className="form-question-text">1</div>
            {CheckBoxes(0)}
          </div>

*/
