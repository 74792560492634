import React, {useState} from "react";
import "./admin.css";
import logo from "../images/starkaresultat-logo-black.png";
//import { AppContext } from "./AppContext";

function SignIn(props) {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  /*
  function handleSignIn(e) {
    e.preventDefault();
    props.auth
      .signInWithEmailAndPassword(e.target[0].value, e.target[1].value)
      .then((cred) => {
        //console.log(cred.user);
      })
      .catch((e) => {
        console.log("error:", e);
        document.getElementById("status-message").innerHTML =
          "inloggningen misslyckades";
      });
  }

  */

 function handleSignIn(e) {
  e.preventDefault();
  props.auth
    .signInWithEmailAndPassword(email, password)
    .then((cred) => {
      //console.log(cred.user);
    })
    .catch((e) => {
      console.log("error:", e);
      document.getElementById("status-message").innerHTML =
      e.message;
    });
}

  const forgotPassword = (e) =>{
    e.preventDefault()
    document.getElementById("status-message").innerHTML =
      "";
      var answer = window.confirm("Are you sure you want to reset the password for: " + email+" ?")
      if(answer){
        props.auth.sendPasswordResetEmail(email).then(function() {
          document.getElementById("status-message").innerHTML = "An email with instructions has been sent to your inbox"
        }).catch(function(error) {
          document.getElementById("status-message").innerHTML = e.message;
        });
      }else{
      }

  }
  /*
  function handleSignOut() {
    props.auth
      .signOut()
      .then(() => {
        console.log("signed out");
      })
      .catch((e) => {
        console.log(e);
      });
  }
  */
  return ( 
    <div className="admin-sign-in-fields">
      <form className="admin-sign-in-form">
      <img className="admin-sign-in-logo" src={logo}/>
        <input className="admin-sign-in-form-field" type="text" placeholder="name@mail.com" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
        <input className="admin-sign-in-form-field" type="password" placeholder="password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
        <input className="admin-sign-in-form-button" type="button" value="Sign in"  onClick={(e) => handleSignIn(e)} />
        <button className="admin-sign-in-form-button-forgot" onClick={(e)=>{forgotPassword(e)}}>Reset password</button>
        <h5 id="status-message"></h5>
      </form>
    </div>
  );
}

export default SignIn;

/*

<button
        onClick={() => {
          handleSignOut();
        }}
      >
        Sign out
      </button>

*/
