import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "./AppContext";
import "./WebForm.css";
import logo from "../images/starkaresultat-logo.png";
import logoPrint from "../images/starkaresultat-logo-black.png";
import FormMeterMentalBudget from "./FormMeterMentalBudget";

function MentalBudget() {
  const { setOnAdminPage } = useContext(AppContext);
  const [formAnswers, setFormAnswers] = useState([9, 9, 9, 9, 9, 9, 9]);
  const [quizDone, setQuizDone] = useState(false);
  const [quizResults, setQuizResults] = useState(0);

  useEffect(() => {
    //window.scrollTo(0, 0);
    setOnAdminPage(true);
  });

  useEffect(() => {
    //formAnswers.includes(0);
    if (!formAnswers.includes(9)) {
      var points = getResults();
      setQuizDone(true);
      setQuizResults(points);
    }

    /*
if (!formAnswers.includes(0)) {
      var points = getResults();
      setQuizDone(true);
      setQuizResults(points);
    }
    */
  }, [formAnswers]);

  function getResults() {
    var temp = [...formAnswers];
    var sum = temp.reduce((a, b) => a + b, 0);
    var MaxPoints = 2 * formAnswers.length;
    var points = Math.round((sum / MaxPoints) * 50);
    //var points = Math.round(100 * (sum / (5 * formAnswers.length)));
    //console.log(points, formAnswers);
    return points;
  }

  function CheckBoxes(index, min, middle, max) {
    return (
      <div className="form-checkbox-container">
        {CheckBox(-2, min, formAnswers[index] === -2, index)}
        {CheckBox(-1, "", formAnswers[index] === -1, index)}
        {CheckBox(0, middle, formAnswers[index] === 0, index)}
        {CheckBox(1, "", formAnswers[index] === 1, index)}
        {CheckBox(2, max, formAnswers[index] === 2, index)}
      </div>
    );
  }
  function CheckBox(questionNumber, scale, value, questionIndex = 0) {
    return (
      <div className="form-checkbox">
        <div
          className="form-circle"
          onClick={(e) => {
            //console.log("funk: ", questionNumber, questionIndex);
            handleCheck(e);
          }}
          data-questionindex={questionIndex}
          data-value={questionNumber}
        >
          <div
            className={value ? "form-checked" : "form-checked form-unchecked"}
          />
        </div>
        <div>{scale}</div>
      </div>
    );
  }
  function handleCheck(e) {
    var temp = [...formAnswers];
    temp[e.currentTarget.dataset.questionindex] = parseInt(
      e.currentTarget.dataset.value
    );
    setFormAnswers(temp);
  }

  return (
    <div className="form-container">
      <div className="form-page">
        <div className="logo-container">
          <img id="logo-white" src={logo} alt="Starka Resultat" />
          <img id="logo-black" src={logoPrint} alt="Starka Resultat" />
        </div>
        <div className="title-container">Mental Budget</div>
        <div className="form-instructions-container">
          Instrumentet för att identifiera delarna som bygger mental styrka,
          samtidigt som du tar reda på ditt nuläge och förbättringspotential.
          <br style={{ marginTop: "0.5em" }} />
          För varje område, lägg till din självskattningspoäng i fem nivåer.
          Balansen hittar vi i mitten, Kreditsidan (-) till vänster, och
          Debetsidan (+) till höger.
          <br style={{ marginTop: "0.5em" }} />
          Det kommer ge dig en poäng utifrån balans på plus (grönt) eller minus
          sidan (rött). Oavsett din poäng, försök att förbättra dig själv på
          följande områden, som samtliga har en betydande påverkan på framtida
          framgångar. Och leverans av starka resultat.
          <br style={{ marginTop: "0.5em" }} />
          Som jag upplever och känner det idag så själv skattar jag mig själv på
          följande delar, i skalan:
        </div>

        <div className="form-all-questions-container">
          <div className="form-devider" />
          <div className="form-question-container">
            <div className="form-question-title">
              Träning (inklusive promenader)
            </div>
            <div className="form-question-text">
              Hur många gånger per vecka utför du någon typ av fysisk aktivitet?
            </div>
            {CheckBoxes(0, "0", "4", "5+")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">Sömn</div>
            <div className="form-question-text">
              Hur många timmar per natt sover du i snitt under en vecka?
            </div>
            {CheckBoxes(1, "0-4", "7", "8+")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">Kost</div>
            <div className="form-question-text">
              I vilken utsträckning planerar du din kost?
            </div>
            {CheckBoxes(2, "Inte alls", "Ibland", "Mycket väl")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">Umgänge</div>
            <div className="form-question-text">
              I vilken grad umgås du med personer som lyfter dig upp, kontra
              drar dig ned?
            </div>
            {CheckBoxes(3, "Inte alls", "Ibland", "Ofta")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">
              Kompetens – Se/lyssna/läsa
            </div>
            <div className="form-question-text">
              I vilken grad investerar du kompetens i dig själv i snitt per dag?
            </div>
            {CheckBoxes(4, "0h", "30min", "1h+")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">Relation</div>
            <div className="form-question-text">
              I vilken grad upplever du att du ger din närmsta relation
              uppskattning?
            </div>
            {CheckBoxes(5, "Aldrig", "Då & då", "Varje dag")}
            <div className="form-devider" />
          </div>
          <div className="form-question-container">
            <div className="form-question-title">Action/Prokrastrinering</div>
            <div className="form-question-text">
              I vilken grad upplever du dig själv
              actionorienterad/handlingskraftig, kontra skjuta upp saker till
              senare?
            </div>
            {CheckBoxes(6, "Låg", "Medel", "Hög")}
          </div>
        </div>

        <div className="form-meter-container">
          <div className="form-meter-size">
            <FormMeterMentalBudget points={quizResults} quizDone={quizDone} />
          </div>
        </div>
      </div>
      <div
        className="form-print-button"
        onClick={() => {
          window.print();
        }}
      >
        Skriv ut
      </div>
    </div>
  );
}

export default MentalBudget;

/*

 <div className="form-question-container">
            <div className="form-question-title">Träning (inklusive promenader)</div>
            <div className="form-question-text">1</div>
            {CheckBoxes(0)}
          </div>

*/
