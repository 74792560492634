//import * as firebase from "firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBfj4rubChI53qvHdaqVbOPR0rbDyG64Rw",
  authDomain: "starkaresultat.firebaseapp.com",
  databaseURL: "https://starkaresultat.firebaseio.com",
  projectId: "starkaresultat",
  storageBucket: "starkaresultat.appspot.com",
  messagingSenderId: "690699203927",
  appId: "1:690699203927:web:14e3ec9550a66f4cba7a12",
  measurementId: "G-L1F0DD05M9",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.firestore().settings(settings);

export default firebase;
