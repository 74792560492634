import React, { useEffect, useContext } from "react";
import { AppContext } from "./AppContext";
import "./About.css";
import "./About-mobile.css";
import Footer from "./Footer";
import profilePicture from "../images/henrik-pettersson-starka-resultat.png";
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var { pageContent, languageSE } = useContext(AppContext);

  const content = {
    title: 'Henrik Pettersson',
    info01: `Med erfarenheter från drygt tjugo års coaching och hantering av förändringsprocesser – utbildad/examinerad bland annat `,
    info02: `Internationellt Licensierad Coach`,
    info03: ` – arbetar Henrik enligt modellen `,
    info04: `Framgångstro – Energi – Målfokus`,
    info05: `. Principer som bildar basen i nyligen utgivna boken `,
    info06: `Programmera dig för Framgång.`,
    info07: `Med unika koncept som `,
    info08: `Mental Verkningsgrad, Mental Budget`,
    info09: ` och `,
    info10: `Mentala Kompasser `,
    info11: `skiljer sig HP från övriga mentala coacher, genom nytänkande, omedelbar nytta och direkt tillämpbara begrepp.`,
    info12: `Sammanfattningsvis HP:s `,
    info13: `främsta egenskaper`,
    info14: `; förmågan att skapa engagemang, snabba, effektiva och hållbara förflyttningar.
    Med tillämpbar tydlighet i fokus.`,
    listTitle: `Urval av genomförda uppdrag:`,
    item01a: `Volvo `,
    item01b: `Säljledning, Säljträning, Ledarskap och Förhandlingsteknik`,
    item02a: `Stockholms Handelskammare `,
    item02b: `Ledarskap och Coaching`,
    item03a: `Bukowskis `,
    item03b: `Sälj– och Servicekultur`,
    item04a: `Nordic Wellness `,
    item04b: `Coachande Ledarskap`,
    item05a: `Agila `,
    item05b: `Säljledning och Säljcoaching`,
    item06a: `Avis `,
    item06b: `Professionellt Kundbemötande`,
    item07a: `Kappahl `,
    item07b: `Säljledarskap`,
    item08a: `Era `,
    item08b: `Säljledning och Målstyrning`,
    item09a: `Din Bil `,
    item09b: `Säljledning och Coachande Säljledarskap`,
    item10a: `Plantagen `,
    item10b: `Butiksförsäljning`,
    item11a: `Chefsakademin `,
    item11b: `Coachande Ledarskap`,
    item12a: `Ryska Posten Bemanning `,
    item12b: `Säljträning och Säljledarskap`,
    item13a: `SATS `,
    item13b: `Säljledning och Målstyrning`,
    item14a: `Technogym `,
    item14b: `Ledarskap och Målstyrning`,
    item15a: `Helix Capital `,
    item15b: `Förhandlingsteknik`,
    item16a: `Citycon `,
    item16b: `Strategisk Försäljning`,
    item17a: `Svenska Kyrkan `,
    item17b: `Kultur och Värderingar`,
    item18a: `Destination Trysil `,
    item18b: `Coachande Ledarskap`,
    item19a: `Bevego `,
    item19b: `Säljkultur`,
    item20a: `Fiskarhedenvillan  `,
    item20b: `Säljkultur`,
    item21a: `Moelven `,
    item21b: `Säljträning`,
    item22a: `Eleiko `,
    item22b: `Ledarskapsutveckling och Coaching`
  }

  const contentEN = {
    title: 'Henrik Pettersson',
    info01: `With experience from more than twenty years of coaching and managing change processes - educated / examined, including `,
    info02: `International licensed Coach`,
    info03: `- Henrik works according to the model `,
    info04: `Faith - Energy - Focus`,
    info05: `. Principles that form the basis of the recently published book `,
    info06: `Programming for Success.`,
    info07: `With unique concepts such as `,
    info08: `Mental Efficiency, Mental Budget`,
    info09: ` and `,
    info10: `Mental Compasses`,
    info11: `, HP differs from other mental coaches, through innovative thinking, immediate benefit and directly applicable concepts.`,
    info12: `In summary, HP's `,
    info13: `top features`,
    info14: `; the ability to create commitment, fast, efficient and with sustainable movements.
    With applicable clarity in focus.`,
    listTitle: `Selection of completed assignments :`,
    item01a: `Volvo `,
    item01b: `Sales Management, Sales Training, Leadership and Negotiation Techniques`,
    item02a: `Stockholms Handelskammare `,
    item02b: `Leadership and Coaching`,
    item03a: `Bukowskis `,
    item03b: `Sell and Service Culture`,
    item04a: `Nordic Wellness `,
    item04b: `Coaching Leadership`,
    item05a: `Agila `,
    item05b: `Sales Management and Sales Coaching`,
    item06a: `Avis `,
    item06b: `Customer Experience Management`,
    item07a: `Kappahl `,
    item07b: `Coaching Leadership`,
    item08a: `Era `,
    item08b: `Sales Management and Goal Governance`,
    item09a: `Din Bil `,
    item09b: `Sales Management and Coaching Sales Leadership`,
    item10a: `Plantagen `,
    item10b: `Store Sale Coaching`,
    item11a: `Chefsakademin `,
    item11b: `Coaching Leadership`,
    item12a: `Ryska Posten Bemanning `,
    item12b: `Sales Training and Sales Leadership`,
    item13a: `SATS `,
    item13b: `Sales Management And Goal Governance`,
    item14a: `Technogym `,
    item14b: `Leadership and Goal Governance`,
    item15a: `Helix Capital `,
    item15b: `Negotiation Techniques`,
    item16a: `Citycon `,
    item16b: `Strategic Sales`,
    item17a: `Svenska Kyrkan `,
    item17b: `Culture & Values`,
    item18a: `Destination Trysil `,
    item18b: `Coaching Leadership`,
    item19a: `Bevego `,
    item19b: `Sales Culture`,
    item20a: `Fiskarhedenvillan  `,
    item20b: `Sales Culture`,
    item21a: `Moelven `,
    item21b: `Sales Training`,
    item22a: `Eleiko `,
    item22b: `Leadership Development And Coaching`
  }


  return (
    <div>
      <div className="about-section-one">
        <div className="about-section-one-intro">
          <span className="about-first-word">{pageContent.about001}</span>{" "}
          <span> {pageContent.about002}</span>
        </div>
        
      </div>
      <div className="about-section-two">
      <div className="about-section-two-text">
          <div className="about-title-medium">{languageSE ? content.title : contentEN.title}</div>
          
          <div>
             {languageSE ? content.info01 : contentEN.info01}
             <span className="about-section-two-bold">{languageSE ? content.info02 : contentEN.info02}</span>
             {languageSE ? content.info03 : contentEN.info03}
             <span className="about-section-two-bold">{languageSE ? content.info04 : contentEN.info04}</span>
             {languageSE ? content.info05 : contentEN.info05}
             <span className="about-section-two-book-link" onClick={() => {
              window.open("https://soderpalm.se/butik/bok/programmera-dig-for-framgang-inte-bara-en-bok/");
            }}>{languageSE ? content.info06 : contentEN.info06}</span>
            <br /><br/>

            {languageSE ? content.info07 : contentEN.info07}
             <span className="about-section-two-bold">{languageSE ? content.info08 : contentEN.info08}</span>
             {languageSE ? content.info09 : contentEN.info09}
             <span className="about-section-two-bold">{languageSE ? content.info10 : contentEN.info10}</span>
             {languageSE ? content.info11 : contentEN.info11}
             <br /><br/>

             {languageSE ? content.info12 : contentEN.info12}
             <span className="about-section-two-bold">{languageSE ? content.info13 : contentEN.info13}</span>
             {languageSE ? content.info14 : contentEN.info14}
             <br/><br/>
          </div>

          <div className="about-section-two-bold">
                {languageSE ? content.listTitle : contentEN.listTitle }
              </div>
          <div className="section-two-merits">

            <div>
              
              <ul className="section-two-col-one">
                <li><span className="about-section-two-bold">{languageSE ? content.item01a : contentEN.item01a }</span>{languageSE ? content.item01b : contentEN.item01b }</li>
                <li><span className="about-section-two-bold">{languageSE ? content.item02a : contentEN.item02a }</span>{languageSE ? content.item02b : contentEN.item02b }</li>
                <li><span className="about-section-two-bold">{languageSE ? content.item03a : contentEN.item03a }</span>{languageSE ? content.item03b : contentEN.item03b }</li>
                <li><span className="about-section-two-bold">{languageSE ? content.item04a : contentEN.item04a }</span>{languageSE ? content.item04b : contentEN.item04b }</li>
                <li><span className="about-section-two-bold">{languageSE ? content.item05a : contentEN.item05a }</span>{languageSE ? content.item05b : contentEN.item05b }</li>
                <li><span className="about-section-two-bold">{languageSE ? content.item06a : contentEN.item06a }</span>{languageSE ? content.item06b : contentEN.item06b }</li>
                <li><span className="about-section-two-bold">{languageSE ? content.item07a : contentEN.item07a }</span>{languageSE ? content.item07b : contentEN.item07b }</li>
                <li><span className="about-section-two-bold">{languageSE ? content.item08a : contentEN.item08a }</span>{languageSE ? content.item08b : contentEN.item08b }</li>
                <li><span className="about-section-two-bold">{languageSE ? content.item09a : contentEN.item09a }</span>{languageSE ? content.item09b : contentEN.item09b }</li>
                <li><span className="about-section-two-bold">{languageSE ? content.item10a : contentEN.item10a }</span>{languageSE ? content.item10b : contentEN.item10b }</li>
                <li><span className="about-section-two-bold">{languageSE ? content.item11a : contentEN.item11a }</span>{languageSE ? content.item11b : contentEN.item11b }</li>
              </ul>
            </div>
            <div>
              <ul className="section-two-col-two">
              <li><span className="about-section-two-bold">{languageSE ? content.item12a : contentEN.item12a }</span>{languageSE ? content.item12b : contentEN.item12b }</li>
              <li><span className="about-section-two-bold">{languageSE ? content.item13a : contentEN.item13a }</span>{languageSE ? content.item13b : contentEN.item13b }</li>
              <li><span className="about-section-two-bold">{languageSE ? content.item14a : contentEN.item14a }</span>{languageSE ? content.item14b : contentEN.item14b }</li>
              <li><span className="about-section-two-bold">{languageSE ? content.item15a : contentEN.item15a }</span>{languageSE ? content.item15b : contentEN.item15b }</li>
              <li><span className="about-section-two-bold">{languageSE ? content.item16a : contentEN.item16a }</span>{languageSE ? content.item16b : contentEN.item16b }</li>
              <li><span className="about-section-two-bold">{languageSE ? content.item17a : contentEN.item17a }</span>{languageSE ? content.item17b : contentEN.item17b }</li>
              <li><span className="about-section-two-bold">{languageSE ? content.item18a : contentEN.item18a }</span>{languageSE ? content.item18b : contentEN.item18b }</li>
              <li><span className="about-section-two-bold">{languageSE ? content.item19a : contentEN.item19a }</span>{languageSE ? content.item19b : contentEN.item19b }</li>
              <li><span className="about-section-two-bold">{languageSE ? content.item20a : contentEN.item20a }</span>{languageSE ? content.item20b : contentEN.item20b }</li>
              <li><span className="about-section-two-bold">{languageSE ? content.item21a : contentEN.item21a }</span>{languageSE ? content.item21b : contentEN.item21b }</li>
              <li><span className="about-section-two-bold">{languageSE ? content.item22a : contentEN.item22a }</span>{languageSE ? content.item22b : contentEN.item22b }</li>

              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;

/*

<div className="about-bold">På  plats:</div>
          <div className="about-cursive">
            Tacksamhet slår bitterhet, varje dag i veckan.
          </div>
          <div>
            Om du ska kunna uppleva lycka, förutsätter det att du känner dig
            fri. Och för att kunna känna dig fri, behöver du påminna dig om vad
            du är tacksam över. Oavsett hur jobbigt du har det finns alltid
            något vi har att vara tacksamma för, vad är du tacksam för?
          </div>
          <br />

*/
/*
<div className="profile-picture-container">
          <img
            className="about-profile-picture"
            src={profilePicture}
            alt="Henrik Pettersson"
          />
        </div>

*/

/*

<div className="about-section-two-intro">
          <div className="about-title-medium">{pageContent.about003}</div>
        </div>

        <div
          className="about-section-two-about-text"
          style={{ textAlign: "left" }}
        >
          {pageContent.about004}
          <br />
          <br />
          {pageContent.about005}
          <br />
          <br />
          {pageContent.about006} <br />
          <br />
          {pageContent.about007}
          <br />
          <br />
          {pageContent.about008}
          <br />
          <br />
          
          <span style={{ fontWeight: "bold" }}>Henrik Pettersson.</span> <br />
          Certified High Performance Coach.
        </div>

        BOOK

        <div className="about-border" />
        <div className="about-content-main-title about-max765">
          {pageContent.about011}
        </div>
        <div className="about-running-text about-max765">
          {pageContent.about012}
        </div>
        <br />
        <div className="about-book">
          <div className="about-bold">{pageContent.about013}</div>
          <div className="about-cursive">{pageContent.about014}</div>
          <div>{pageContent.about015}</div>
          <br />
          <div>{pageContent.about016}</div>
          <br />
          <div className="about-bold">{pageContent.about017}</div>
          <div className="about-cursive">{pageContent.about018}</div>
          <div>{pageContent.about019}</div>
          <br />
          <div className="about-bold">{pageContent.about020}</div>
          <div className="about-cursive">{pageContent.about021}</div>
          <div>{pageContent.about022}</div>
          <br />
          <div className="about-bold">{pageContent.about023}</div>
          <div className="about-cursive">{pageContent.about024}</div>
          <div>{pageContent.about025}</div>
          <br />
          <div className="about-bold">{pageContent.about026}</div>
          <div className="about-cursive">{pageContent.about027}</div>
          <div>{pageContent.about028}</div>
          <br />
          <div className="about-bold">{pageContent.about029}</div>
          <div className="about-cursive">{pageContent.about030}</div>
          <div>{pageContent.about031}</div>
          <br />
          <div className="about-bold">{pageContent.about032}</div>
          <div>
            <ul>
              <li>{pageContent.about033}</li>
              <li>{pageContent.about034}</li>
              <li>{pageContent.about035}</li>
            </ul>
            <div>{pageContent.about036}</div>
          </div>
          <br />
          <div className="about-bold">{pageContent.about037}</div>
          <div className="about-cursive">{pageContent.about038}</div>
          <div>{pageContent.about039}</div>
          <br />
          <div className="about-bold">{pageContent.about040}</div>
          <div className="about-cursive">{pageContent.about041}</div>
          <div>{pageContent.about042}</div>
          <br />
          <div>{pageContent.about043}</div>
          <br />
          <div className="about-bold">{pageContent.about044}</div>
          <div className="about-cursive">{pageContent.about045}</div>
          <div>{pageContent.about046}</div>
          <br />
          <div>{pageContent.about047}</div>
          <br />
          <div>{pageContent.about048}</div>
          <br />
        </div>
*/