import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "./AppContext";
import "./egenskapsmatris.css";
import logo from "../images/strongresults-logo.png";
import logoPrint from "../images/strongresults-logo-black.png";

function EgenskapsmatisEN() {
  const { setOnAdminPage } = useContext(AppContext);
  const [quizDone, setQuizDone] = useState(false);
  const [formName, setFormName] = useState("My Name");
  const [formFieldOne, setFormFieldOne] = useState("My main line");
  const [formFieldTwo, setFormFieldTwo] = useState("My completion line 1");
  const [formFieldThree, setFormFieldThree] = useState(
    "My completion line 2"
  );
  const [formFieldFour, setFormFieldFour] = useState(
    "My completion line 3"
  );
  const [formFieldFive, setFormFieldFive] = useState("Min negative line");

  const [formExampleMain, setFromExampleMain] = useState();
  const [formExampleCompOne, setFromExampleCompOne] = useState();
  const [formExampleCompTwo, setFromExampleCompTwo] = useState();
  const [formExampleCompThree, setFromExampleCompThree] = useState();

  const dataListOne = [
    "Responsible",
"Competent",
"Energy",
"Stubborn",
"Courage",
"Thoughtful",
"Committed",
"Responsible",
"Loyal",
"Entrepreneur",
"Joyful",
"Growth",
"Development",
"Powerful",
"Strength",
"Dedicated",
"Enthusiastic",
"Creative / Innovative ",
"Long term",
"Profitability focused",

  ];

  const dataListTwo = [
    "Meticulous",
"Analytical",
"Stable",
"Brave",
"Careful",
"Solution-oriented",
"Equitable",
"Capacity",
"Force",
"Social",
"Humanistic",
"Knowledge",
"Flexible",
"Efficiency",
"Rational",
"Fast",
"Reliable",
"Profitability",
"Productive",
"Competitive",
"Energy",
"Simplicity",

  ];

  const dataListThree = [
    "Impatience",
"Stubborn",
"Insensitive",
"Need for control",
"Opportunity",
"Accuracy",
"Slow down",
"Administrative",
"Suspicious",
"Indecisive",

  ];

  useEffect(() => {
    //window.scrollTo(0, 0);
    setOnAdminPage(true);
  }, []);

  function handleChange(e, setfunction) {
    setfunction(e.target.value);
  }

  useEffect(() => {
    if (quizDone) {
      document
        .getElementById("formDoneContainer")
        .classList.remove("hide-print");
      document
        .getElementById("formEditingContainer")
        .classList.add("hide-print");
    } else {
      document.getElementById("formDoneContainer").classList.add("hide-print");
      document
        .getElementById("formEditingContainer")
        .classList.remove("hide-print");
    }
  }, [quizDone]);

  function handleNameChange(e) {
    setFormName(e.target.value);
  }

  function createOptions(list) {
    var options = list.map((value, index) => {
      return <option value={value} key={index} />;
    });
    return options;
  }

  function handleDone() {}
  return (
    <div className="form-container">
      <div className="form-page">
        <div className="logo-container">
          <img id="logo-white" src={logo} alt="Starka Resultat" />
          <img id="logo-black" src={logoPrint} alt="Starka Resultat" />
        </div>
        <div className="title-container">Character Matrix</div>
        <div className="form-instructions-container">
        Building a strong and credible character requires that you be honest with yourself and your surroundings. 
        An identity that is constructed around long-term sustainable principles. 
        A powerful character who stands up in all circumstances. Your character controls your brand. 
        Your brand controls your character. 
        Just like successful companies' well-thought-out brand strategies that they do not deviate a millimeter from. 
        Choose the words that are best for you on each line, or choose your own. 
        Then briefly fill in how your behavior in everyday life is in accordance with this. 
        Adds a "negative line" that we all have. (Can also be interpreted as a positive part)
        </div>

        <div id="formEditingContainer" className="form-field-container">
          <form>
            <label>My Name</label>
            <br />
            <input
              type="text"
              placeholder="My Name"
              onChange={(e) => {
                handleNameChange(e);
              }}
            ></input>
            <br />
            <div className="form-spacer" />
            <label>Main line</label>
            <br />
            <input
              className="form-text-field-type-one"
              list="huvudlinjer"
              id="huvudlinjeId"
              placeholder="My main line"
              onChange={(e) => {
                handleChange(e, setFormFieldOne);
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
            />
            <textarea
              className="form-text-field-type-two"
              type="textarea"
              placeholder="My behavior in everyday life that shows this:"
              onChange={(e) => {
                handleChange(e, setFromExampleMain);
              }}
            />
            <datalist id="huvudlinjer">{createOptions(dataListOne)}</datalist>

            <div className="form-spacer" />
            <br />
            <label>Completion line 1 1</label>
            <br />
            <input
              className="form-text-field-type-one"
              list="kompOne"
              id="kompOneId"
              placeholder="My first completion Line"
              onChange={(e) => {
                handleChange(e, setFormFieldTwo);
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
            />
            <textarea
              className="form-text-field-type-two"
              type="textarea"
              placeholder="My behavior in everyday life that shows this:"
              onChange={(e) => {
                handleChange(e, setFromExampleCompOne);
              }}
            />
            <datalist id="kompOne">{createOptions(dataListTwo)}</datalist>

            <br />
            <label>Completion line 2</label>
            <br />
            <input
              className="form-text-field-type-one"
              list="kompTwo"
              id="kompTwoId"
              placeholder="My second completion Line"
              onChange={(e) => {
                handleChange(e, setFormFieldThree);
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
            />
            <textarea
              className="form-text-field-type-two"
              type="textarea"
              placeholder="My behavior in everyday life that shows this:"
              onChange={(e) => {
                handleChange(e, setFromExampleCompTwo);
              }}
            />
            <datalist id="kompTwo">{createOptions(dataListTwo)}</datalist>

            <br />
            <label>Completion line 3</label>
            <br />
            <input
              className="form-text-field-type-one"
              list="kompThree"
              id="kompThreeId"
              placeholder="My third completion Line"
              onChange={(e) => {
                handleChange(e, setFormFieldFour);
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
            />
            <textarea
              className="form-text-field-type-two"
              type="textarea"
              placeholder="My behavior in everyday life that shows this:"
              onChange={(e) => {
                handleChange(e, setFromExampleCompThree);
              }}
            />
            <datalist id="kompThree">{createOptions(dataListTwo)}</datalist>
            <div className="form-spacer" />
            <br />
            <label>Negative line</label>
            <br />
            <input
              className="form-text-field-type-one"
              list="negativLinje"
              id="negativeLinjeId"
              placeholder=" My Negative Line"
              onChange={(e) => {
                handleChange(e, setFormFieldFive);
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
            />
            <datalist id="negativLinje">
              {createOptions(dataListThree)}
            </datalist>
          </form>
          <div className="form-spacer" />
          <div
            className="form-print-button button-done"
            onClick={() => {
              setQuizDone(true);
            }}
          >
            Ready
          </div>
        </div>
        <div
          id="formDoneContainer"
          className="form-done-container-top show-print hide-print"
        >
          <div className="form-done-container">
            <div className="done-container-one">{formName}</div>
            <div className="done-container-header done-container-two">
              <div className="form-done-title">My main line</div>
              <div className="form-result-one">{formFieldOne}</div>
              <div className="form-daily-example">({formExampleMain})</div>
            </div>
            <div className="done-container-three">
              <div className="done-three-line" />
              <div className="done-three-line" />
              <div className="done-three-line" />
            </div>

            <div className="done-container-four">
              <div className="form-done-title ">My completion lines</div>
              <div className="done-container-four-b">
                <div className="done-comp-container">
                  <div className="done-comp-word">{formFieldTwo}</div>
                  <div className="form-daily-example">
                    ({formExampleCompOne})
                  </div>
                </div>
                <div className="done-comp-container">
                  <div className="done-comp-word">{formFieldThree}</div>
                  <div className="form-daily-example">
                    ({formExampleCompTwo})
                  </div>
                </div>
                <div className="done-comp-container">
                  <div className="done-comp-word">{formFieldFour}</div>
                  <div className="form-daily-example">
                    ({formExampleCompThree})
                  </div>
                </div>
              </div>
            </div>
            <div className="done-container-five">
              <div className="done-five-line" />
            </div>
            <div className="done-container-header done-container-six">
              <div className="form-done-title"> My negative line</div>
              <div className="form-result-five">{formFieldFive}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={quizDone ? "form-print-button" : "form-print-button hide-print"}
        onClick={() => {
          window.print();
        }}
      >
        Print
      </div>
    </div>
  );
}

export default EgenskapsmatisEN;
