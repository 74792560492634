import React, { useState, useEffect } from "react";
import "./varvtalsraknaren.css";
import "./varvtalsraknaren-mobile.css";
import "./meter.css";
import Meter from "./Meter";
import CheckBoxes from "./CheckBoxes";
import Progressbar from "./Progressbar";
import MeterButton from "./MeterButton";
import Questions from "./Questions";
import Results from "./Results";
import IntroductionGPI from "./IntroductionGPI";

function GPITest() {
  const [quizProgress, setQuizProgress] = useState(0);

  //const [activePart, setActivePart] = useState(0);
  //const [activePartAnswer, setActivePartAnswer] = useState(0);

  //const [activeQuestion, setActiveQuestion] = useState(0);

  //const [isBackActive, setIsBackActive] = useState(false);
  //const [isNextActive, setIsNextActive] = useState(true);

  const [partOneResults, setPartOneResults] = useState(0);
  const [partTwoResults, setPartTwoResults] = useState(0);
  const [partThreeResults, setPartThreeResults] = useState(0);

  const [quizDone, setQuizDone] = useState(false);

  const [partOneAnswer, setPartOneAnswer] = useState([0, 0, 0, 0, 0]);
  const [partTwoAnswer, setPartTwoAnswer] = useState([0, 0, 0, 0, 0]);
  const [partThreeAnswer, setPartThreeAnswer] = useState([0, 0, 0, 0, 0]);

  const [partsProp, setPartsProp] = useState([
    { backgroundColor: "#fff", buttonColor: "#8A5994" },
    { backgroundColor: "#AF8CB6", buttonColor: "#8A5994" },
    { backgroundColor: "#7F87A6", buttonColor: "#4A5681" },
    { backgroundColor: "#6E7F89", buttonColor: "#304857" },
  ]);

  const [quizStateColors] = useState([
    { primary: "#8A5994", secondary: "#AF8CB6", disabled: "#9C71A4" },
    { primary: "#4A5681", secondary: "#7F87A6", disabled: "#646E93" },
    { primary: "#304857", secondary: "#6E7F89", disabled: "#4F636F" },
  ]);

  const [quizResults, setQuizResults] = useState([
    { points: "1" },
    { points: "2" },
    { points: "3" },
  ]);

  const [activeQuizResultsIndex, setActiveQuizResultsIndex] = useState(0);

  const quizQuestions = [
    [
      "Tacksamhet - Hur ofta upplever du att du påminner dig om vad du har att vara tacksam för?",
      "Kost – Har du en bra plan för din kost och följer den, de flesta dagarna i veckan?",
      "Träning - Har du en bra plan för din träning och följer den, de flesta dagarna i veckan?",
      "Sömn - Vad är din uppfattning om dina sovvanor för tillfället?",
      "Relationer – Hur bra är du på att först och främst ge, i din närmaste relation?",
    ],
    [
      "Inspiration - Hur ofta planerar du att sätta dig i ”rätt” tillstånd av inspiration?",
      "Mantra - Har du ett ord/mantra som du idag använder för att stärka dig?",
      "Andning - På vilken nivå uppskattar du att du djupandas/syresätter?",
      "Avslappning - Hur duktig är du att frekvent fokusera på avslappning?",
      "Syfte - Jag är klar över min mission och fokuserar på den mesta delen av veckan?",
    ],
    [
      "Värderingar – Jag vet tydligt vilka 3 ord jag står för som person, och ser på de ofta?",
      "Prestation -  Jag är bra på att påminna mig om mina tidigare ”segrar”, för att stimulera mig själv?",
      "Framtid – Jag påminner mig om det som ligger framför mig – för att trigga min ambition?",
      "Hälsa/utseende – Jag påminner mig ofta om det jag är nöjd med min kropp, internt eller externt?",
      "Glädje – Jag är bra på att trigga glädjen genom att påminna mig om goda upplevelser?",
    ],
  ];

  const questionNumber = [
    0,
    1,
    2,
    3,
    4,
    5,
    1,
    2,
    3,
    4,
    5,
    1,
    2,
    3,
    4,
    5,
    0,
    0,
    0,
  ];

  const quizResultsText = [
    [
      [
        `Fokus på tacksamhet
        När belöningarna är så goda som UCLA’s undersökning visar, visst är det värt att påminna sig om vad du har att vara tacksam för. Kan du avsluta/starta varje dag med att svara på frågan vad du har att vara tacksam för, lovar jag dig ett positivare sinnelag. Är det att du har hälsan i behåll, ett utvecklande arbete, en underbar partner, härligt hus, en trevlig hobby, intressen och så vidare. 
        
        Fortsätta lista saker du har att vara tacksamhet för även efter kursen är klar. 
        
        Fokus på relation. 
        Om du lever i en relation, börja dagen med att ge hen uppskattning. Berätta för hen att hen är vacker, fantastisk, attraktiv, härlig och att du är tacksam att du har hen nära dig. Dra förhållandet växelvis och ge varandra respekt och uppmärksamhet. Du kommer att få tillbaka mer än du tror. Och ett garanterat bättre liv`,
      ],
      [
        `Fokus på tacksamhet
        När belöningarna är så goda som UCLA’s undersökning visar, visst är det värt att påminna sig om vad du har att vara tacksam för. Kan du avsluta/starta varje dag med att svara på frågan vad du har att vara tacksam för, lovar jag dig ett positivare sinnelag. Är det att du har hälsan i behåll, ett utvecklande arbete, en underbar partner, härligt hus, en trevlig hobby, intressen och så vidare. 
        
        Fortsätta lista saker du har att vara tacksamhet för även efter kursen är klar. 
        
        Fokus på relation. 
        Om du lever i en relation, börja dagen med att ge hen uppskattning. Berätta för hen att hen är vacker, fantastisk, attraktiv, härlig och att du är tacksam att du har hen nära dig. Dra förhållandet växelvis och ge varandra respekt och uppmärksamhet. Du kommer att få tillbaka mer än du tror. Och ett garanterat bättre liv`,
      ],
      [
        `Fokus på tacksamhet
        När belöningarna är så goda som UCLA’s undersökning visar, visst är det värt att påminna sig om vad du har att vara tacksam för. Kan du avsluta/starta varje dag med att svara på frågan vad du har att vara tacksam för, lovar jag dig ett positivare sinnelag. Är det att du har hälsan i behåll, ett utvecklande arbete, en underbar partner, härligt hus, en trevlig hobby, intressen och så vidare. 
        
        Fortsätta lista saker du har att vara tacksamhet för även efter kursen är klar. 
        
        Fokus på relation. 
        Om du lever i en relation, börja dagen med att ge hen uppskattning. Berätta för hen att hen är vacker, fantastisk, attraktiv, härlig och att du är tacksam att du har hen nära dig. Dra förhållandet växelvis och ge varandra respekt och uppmärksamhet. Du kommer att få tillbaka mer än du tror. Och ett garanterat bättre liv`,
      ],
      [
        `Fokus på tacksamhet
        När belöningarna är så goda som UCLA’s undersökning visar, visst är det värt att påminna sig om vad du har att vara tacksam för. Kan du avsluta/starta varje dag med att svara på frågan vad du har att vara tacksam för, lovar jag dig ett positivare sinnelag. Är det att du har hälsan i behåll, ett utvecklande arbete, en underbar partner, härligt hus, en trevlig hobby, intressen och så vidare. 
        
        Fortsätta lista saker du har att vara tacksamhet för även efter kursen är klar. 
        
        Fokus på relation. 
        Om du lever i en relation, börja dagen med att ge hen uppskattning. Berätta för hen att hen är vacker, fantastisk, attraktiv, härlig och att du är tacksam att du har hen nära dig. Dra förhållandet växelvis och ge varandra respekt och uppmärksamhet. Du kommer att få tillbaka mer än du tror. Och ett garanterat bättre liv`,
      ],
      [
        `Fokus på tacksamhet
        När belöningarna är så goda som UCLA’s undersökning visar, visst är det värt att påminna sig om vad du har att vara tacksam för. Kan du avsluta/starta varje dag med att svara på frågan vad du har att vara tacksam för, lovar jag dig ett positivare sinnelag. Är det att du har hälsan i behåll, ett utvecklande arbete, en underbar partner, härligt hus, en trevlig hobby, intressen och så vidare. 
        
        Fortsätta lista saker du har att vara tacksamhet för även efter kursen är klar. 
        
        Fokus på relation. 
        Om du lever i en relation, börja dagen med att ge hen uppskattning. Berätta för hen att hen är vacker, fantastisk, attraktiv, härlig och att du är tacksam att du har hen nära dig. Dra förhållandet växelvis och ge varandra respekt och uppmärksamhet. Du kommer att få tillbaka mer än du tror. Och ett garanterat bättre liv`,
      ],
    ],
    [
      [
        `Fokus på framtiden. 
        På din utveckling, dina projekt, dina drömmar, dina förhoppningar som triggar dina ambitioner, och får dig motiverad och framgångstroende.
        
        Fokus på dina värderingar
        Det du värdesätter, det du tror på och känner att du kan stå för. Ju mer du repeterar dina värderingar för dig själv, desto mer kommer de att prägla din personlighet och hjälpa dig på vägen mot Starka Resultat.
        `,
      ],
      [
        `Fokus på framtiden. 
        På din utveckling, dina projekt, dina drömmar, dina förhoppningar som triggar dina ambitioner, och får dig motiverad och framgångstroende.
        
        Fokus på dina värderingar
        Det du värdesätter, det du tror på och känner att du kan stå för. Ju mer du repeterar dina värderingar för dig själv, desto mer kommer de att prägla din personlighet och hjälpa dig på vägen mot Starka Resultat.
        `,
      ],
      [
        `Fokus på framtiden. 
        På din utveckling, dina projekt, dina drömmar, dina förhoppningar som triggar dina ambitioner, och får dig motiverad och framgångstroende.
        
        Fokus på dina värderingar
        Det du värdesätter, det du tror på och känner att du kan stå för. Ju mer du repeterar dina värderingar för dig själv, desto mer kommer de att prägla din personlighet och hjälpa dig på vägen mot Starka Resultat.
        `,
      ],
      [
        `Fokus på framtiden. 
        På din utveckling, dina projekt, dina drömmar, dina förhoppningar som triggar dina ambitioner, och får dig motiverad och framgångstroende.
        
        Fokus på dina värderingar
        Det du värdesätter, det du tror på och känner att du kan stå för. Ju mer du repeterar dina värderingar för dig själv, desto mer kommer de att prägla din personlighet och hjälpa dig på vägen mot Starka Resultat.
        `,
      ],
      [
        `Fokus på framtiden. 
        På din utveckling, dina projekt, dina drömmar, dina förhoppningar som triggar dina ambitioner, och får dig motiverad och framgångstroende.
        
        Fokus på dina värderingar
        Det du värdesätter, det du tror på och känner att du kan stå för. Ju mer du repeterar dina värderingar för dig själv, desto mer kommer de att prägla din personlighet och hjälpa dig på vägen mot Starka Resultat.
        `,
      ],
    ],
    [
      [
        `Fokus på ditt syfte
        De som känner att de bidrar till andras välmående, mår också bättre för egen del, eftersom dina syften oftast involverar andra människor, och lyfter dig till någonting som är större än dig själv. Fokusera därför i första hand på syftet. Då får syftet, målet och visioner vingar.
        
        Fokus på inspiration.
        Ett bra tips är Youtube för att sätta dig i stimulerande tillstånd, repetera goda beteenden och stärka dig själv. Mina favoriter är Evan Carmichael, som samlat framgångsrika människors bästa livsregler. 
        Wayne Dyer, som sätter perspektiv på livet, och Goalcast, som ger inspiration i det dagliga.
        `,
      ],
      [
        `Fokus på ditt syfte
        De som känner att de bidrar till andras välmående, mår också bättre för egen del, eftersom dina syften oftast involverar andra människor, och lyfter dig till någonting som är större än dig själv. Fokusera därför i första hand på syftet. Då får syftet, målet och visioner vingar.
        
        Fokus på inspiration.
        Ett bra tips är Youtube för att sätta dig i stimulerande tillstånd, repetera goda beteenden och stärka dig själv. Mina favoriter är Evan Carmichael, som samlat framgångsrika människors bästa livsregler. 
        Wayne Dyer, som sätter perspektiv på livet, och Goalcast, som ger inspiration i det dagliga.
        `,
      ],
      [
        `Fokus på ditt syfte
        De som känner att de bidrar till andras välmående, mår också bättre för egen del, eftersom dina syften oftast involverar andra människor, och lyfter dig till någonting som är större än dig själv. Fokusera därför i första hand på syftet. Då får syftet, målet och visioner vingar.
        
        Fokus på inspiration.
        Ett bra tips är Youtube för att sätta dig i stimulerande tillstånd, repetera goda beteenden och stärka dig själv. Mina favoriter är Evan Carmichael, som samlat framgångsrika människors bästa livsregler. 
        Wayne Dyer, som sätter perspektiv på livet, och Goalcast, som ger inspiration i det dagliga.
        `,
      ],
      [
        `Fokus på ditt syfte
        De som känner att de bidrar till andras välmående, mår också bättre för egen del, eftersom dina syften oftast involverar andra människor, och lyfter dig till någonting som är större än dig själv. Fokusera därför i första hand på syftet. Då får syftet, målet och visioner vingar.
        
        Fokus på inspiration.
        Ett bra tips är Youtube för att sätta dig i stimulerande tillstånd, repetera goda beteenden och stärka dig själv. Mina favoriter är Evan Carmichael, som samlat framgångsrika människors bästa livsregler. 
        Wayne Dyer, som sätter perspektiv på livet, och Goalcast, som ger inspiration i det dagliga.
        `,
      ],
      [
        `Fokus på ditt syfte
        De som känner att de bidrar till andras välmående, mår också bättre för egen del, eftersom dina syften oftast involverar andra människor, och lyfter dig till någonting som är större än dig själv. Fokusera därför i första hand på syftet. Då får syftet, målet och visioner vingar.
        
        Fokus på inspiration.
        Ett bra tips är Youtube för att sätta dig i stimulerande tillstånd, repetera goda beteenden och stärka dig själv. Mina favoriter är Evan Carmichael, som samlat framgångsrika människors bästa livsregler. 
        Wayne Dyer, som sätter perspektiv på livet, och Goalcast, som ger inspiration i det dagliga.
        `,
      ],
    ],
  ];

  /*
  useEffect(() => {
    console.log("quizProgress: " + quizProgress);
  }, [quizProgress]);
  */

  useEffect(() => {
    if (
      !partOneAnswer.includes(0) &&
      !partTwoAnswer.includes(0) &&
      !partThreeAnswer.includes(0) &&
      quizProgress > 15
    ) {
      setQuizDone(true);
    }
  }, [quizProgress]);

  function checkIfDone() {
    if (
      !partOneAnswer.includes(0) &&
      !partTwoAnswer.includes(0) &&
      !partThreeAnswer.includes(0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="test-container">
      <div className="meter-container">
        <Meter
          results={partOneResults}
          answers={partOneAnswer}
          colors={partsProp}
          number={0}
          quizDone={quizDone}
          active={
            (quizProgress > 0 && quizProgress < 6) || quizProgress === 16
              ? true
              : false
          }
        />
        <Meter
          results={partTwoResults}
          answers={partTwoAnswer}
          colors={partsProp}
          number={1}
          quizDone={quizDone}
          active={
            (quizProgress > 5 && quizProgress < 11) || quizProgress === 17
              ? true
              : false
          }
        />
        <Meter
          results={partThreeResults}
          answers={partThreeAnswer}
          colors={partsProp}
          number={2}
          quizDone={quizDone}
          active={
            (quizProgress > 10 && quizProgress < 16) || quizProgress === 18
              ? true
              : false
          }
        />
      </div>
      <div className="meter-button-container">
        <MeterButton
          title={"PREPARERANDE"}
          quizProgress={quizProgress}
          active={
            (quizProgress > 0 && quizProgress < 6) ||
            quizProgress === 16 ||
            quizProgress === 0
              ? true
              : false
          }
          returnQuizProgressTo={[1, 16]}
          setQuizProgress={setQuizProgress}
          quizDone={quizDone}
          colors={quizStateColors[0]}
        />
        <MeterButton
          title={"LADDANDE"}
          quizProgress={quizProgress}
          returnQuizProgressTo={[6, 17]}
          setQuizProgress={setQuizProgress}
          quizDone={quizDone}
          colors={quizStateColors[1]}
          active={
            (quizProgress > 5 && quizProgress < 11) ||
            quizProgress === 17 ||
            quizProgress === 0
              ? true
              : false
          }
        />
        <MeterButton
          title={"STIMULERANDE"}
          quizProgress={quizProgress}
          returnQuizProgressTo={[11, 18]}
          setQuizProgress={setQuizProgress}
          quizDone={quizDone}
          colors={quizStateColors[2]}
          active={
            (quizProgress > 10 && quizProgress < 16) ||
            quizProgress === 18 ||
            quizProgress === 0
              ? true
              : false
          }
        />
      </div>
      <div className="meter-progressbar-container">
        <Progressbar
          quizProgress={quizProgress}
          answers={partOneAnswer}
          startQuizProgress={1}
          setQuizProgress={setQuizProgress}
          colors={quizStateColors[0]}
        />
        <Progressbar
          quizProgress={quizProgress}
          answers={partTwoAnswer}
          startQuizProgress={6}
          setQuizProgress={setQuizProgress}
          colors={quizStateColors[1]}
        />
        <Progressbar
          quizProgress={quizProgress}
          answers={partThreeAnswer}
          startQuizProgress={11}
          setQuizProgress={setQuizProgress}
          colors={quizStateColors[2]}
        />
      </div>
      <div className="quiz-content-container">
        {quizProgress === 0 ? (
          <IntroductionGPI setQuizProgress={setQuizProgress} />
        ) : (
          ""
        )}
        {quizProgress === 16 ? (
          <Results
            title={"PREPARERANDE"}
            resultsText={quizResultsText[0]}
            colors={quizStateColors[0]}
            answers={partOneAnswer}
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[0]}
            quizQuestionsStart={1}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartOneAnswer}
            answers={partOneAnswer}
          />
        ) : (
          ""
        )}
        {quizProgress === 17 ? (
          <Results
            title={"LADDANDE"}
            resultsText={quizResultsText[1]}
            colors={quizStateColors[1]}
            answers={partTwoAnswer}
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[1]}
            quizQuestionsStart={6}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartTwoAnswer}
            answers={partTwoAnswer}
          />
        ) : (
          ""
        )}
        {quizProgress === 18 ? (
          <Results
            title={"STIMULERANDE"}
            resultsText={quizResultsText[2]}
            colors={quizStateColors[2]}
            answers={partThreeAnswer}
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[2]}
            quizQuestionsStart={11}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartThreeAnswer}
            answers={partThreeAnswer}
          />
        ) : (
          ""
        )}
        {quizProgress > 0 && quizProgress < 6 ? (
          <Questions
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[0]}
            quizQuestionsStart={1}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartOneAnswer}
            answers={partOneAnswer}
            colors={quizStateColors[0]}
            checkIfDone={checkIfDone}
          />
        ) : (
          ""
        )}
        {quizProgress > 5 && quizProgress < 11 ? (
          <Questions
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[1]}
            quizQuestionsStart={6}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartTwoAnswer}
            answers={partTwoAnswer}
            colors={quizStateColors[1]}
            checkIfDone={checkIfDone}
          />
        ) : (
          ""
        )}
        {quizProgress > 10 && quizProgress < 16 ? (
          <Questions
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[2]}
            quizQuestionsStart={11}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartThreeAnswer}
            answers={partThreeAnswer}
            colors={quizStateColors[2]}
            checkIfDone={checkIfDone}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default GPITest;
