import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "./AppContext";
import { useParams, useHistory } from "react-router-dom";
import "./EditWebForms.css";

function EditWebForms() {
  var { db, setOnAdminPage, adminNewFormId } = useContext(AppContext);
  const history = useHistory();
  const [newForm, setNewForm] = useState(false);
  const { form } = useParams();
  const [currentWebFormContent, setCurrentWebFormContent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [savePending, setSavePending] = useState(false);

  useEffect(()=>{

    if(form === adminNewFormId && adminNewFormId !== ""){
      window.location.reload()
    }
  },[form])

  //checks if a new document should be created or reads current data 
  useEffect(() => {
    window.scrollTo(0, 0);
    setOnAdminPage(true);
    //console.log("read: ", form);

    if (form === "createnewformtypeone" || form === "createnewformtypetwo") {
      if(adminNewFormId === ""){
        history.push("/admin")
      }else{
        setNewForm(true);
      }

    } else {
      //setNewForm(false);
      db.collection("forms")
        .doc(form)
        .get()
        .then((doc) => {
          setCurrentWebFormContent(doc.data());
        })
        .then(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          console.log("Error:", e);
        });
    }
  }, []);

  //creates  new document and redirects
  useEffect(() => {
    if (newForm) {
      if (form === "createnewformtypeone") {
        db.collection("forms")
        .doc(adminNewFormId)
          .set({
            title: "New Form",
            questions: ["fråga1", "fråga2", "fråga3", "fråga4", "fråga5"],
            titles: ["title1", "title2", "title3", "title4", "title5"],
            type: "one",
            language: "se",
            instructions: "För varje fråga, lägg till din självskattningspoäng (lägst 1, högst 5). Det kommer ge dig en poäng utifrån 100%. Oavsett din poäng, försök att förbättra dig själv på dessa områden för de har en stor påverkan på din framtida framgång och leverans av starka resultat. (Handen på hjärtat)."
          })
          .then(() => {
            history.push(`/admin/edit/${adminNewFormId}`);
            console.log("klart!")
          })
          .catch((e) => {
            console.log("creation error:", e);
          });
      } else {
        db.collection("forms")
        .doc(adminNewFormId)
          .set({
            title: "New Form Type 2",
            questions: ["fråga1", "fråga2", "fråga3", "fråga4", "fråga5"],
            instructions: "Intro",
            type: "two",
            language: "sv",
          })
          .then((doc) => {
            history.push(`/admin/edit/${adminNewFormId}`);
          })
          .catch((e) => {
            console.log("creation error:", e);
          });
      }
    }
  }, [newForm]);

  useEffect(() => {
    if (savePending) {
      saveData();
    }
  }, [savePending]);

  function ContentTypeOne() {
    return (
      <div className="edit-webform-container">
        <form onSubmit={(e) => handleSubmitOne(e)} id="webFormID">
          <label>Titel på formulär</label>
          <br />
          <input
            type="text"
            defaultValue={currentWebFormContent.title}
            id="title"
            data-form-type="form-title"
          />
          <br />
          <label>Språk (skriv "se" eller "en")</label>
          <br />
          <input
            type="text"
            defaultValue={currentWebFormContent.language}
            id="language"
            data-form-type="form-language"
          />
          <br />
          <label>Instruktioner</label>
          <textarea
            type="text"
            defaultValue={currentWebFormContent.instructions}
            id={"instruction"}
            data-form-type="instructions"
          />
          <br />
          {currentWebFormContent.questions.map((value, index) => {
            return (
              <div className="edit-form-question-container">
                <label>Fråga {index + 1}</label>
                <br />
                <input
                  type="text"
                  defaultValue={currentWebFormContent.titles[index]}
                  id="question-title-one"
                  data-form-type="question-title"
                />

                <br />
                <textarea
                  type="text"
                  defaultValue={value}
                  id={"question-" + index}
                  data-index={index}
                  data-form-type="question"
                />
                <br />
                <button onClick={(e) => handleDeleteQuestion(e)} value={index}>
                  remove question
                </button>
                <br />
                <br />
              </div>
            );
          })}
          <button
            onClick={() => {
              handleAddQuestion();
            }}
          >
            Lägg till en fråga +
          </button>
          <br />
          <button type="submit">Spara</button>
          <br />
          <button
            onClick={() => {
              history.push(`/admin`);
            }}
          >
            Tillbaka till admin
          </button>
          <br />
          <button
            onClick={(e) => {
              e.preventDefault();
              handleDeleteForm();
            }}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Ta bort formulär!
          </button>
        </form>
      </div>
    );
  }

  function handleSubmitTwo(e) {
    e.preventDefault();
    //var numbElm = document.getElementById("webFormID").elements.length;

    //Get questions text
    var newQuestions = [];
    var elements = document
      .getElementById("webFormID")
      .querySelectorAll("[data-form-type='question']");
    elements.forEach((element) => {
      newQuestions.push(element.value);
    });

    // Get form title
    var newFormTitle = e.target[0].value;

    // Get instructions
    var newInstructions = e.target[1].value;

    // Get language 
    var newLanguage = e.target[2].value;

    setCurrentWebFormContent({
      ...currentWebFormContent,
      questions: newQuestions,
      instructions: newInstructions,
      title: newFormTitle,
      language: newLanguage
    });
    setSavePending(true);
  }

  function handleDeleteQuestionTwo(e) {
    e.preventDefault(e);

    var tempArrayQuestions = [...currentWebFormContent.questions];
    //console.log("temp before:", temp);
    tempArrayQuestions.splice(e.target.value, 1);

    //var tempArrayTitles = [...currentWebFormContent.titles];
    //tempArrayTitles.splice(e.target.value, 1);
    //console.log("temp after:", temp);
    //console.log(currentWebFormContent);
    setCurrentWebFormContent({
      ...currentWebFormContent,
      questions: tempArrayQuestions,
    });
    setSavePending(true);
  }

  function handleAddQuestionTwo() {
    var tempArrayQuestions = [...currentWebFormContent.questions];
    tempArrayQuestions.push("Skriv frågan här...");
    setCurrentWebFormContent({
      ...currentWebFormContent,
      questions: tempArrayQuestions,
    });
  }

  function ContentTypeTwo() {
    return (
      <div className="edit-webform-container">
        <form onSubmit={(e) => handleSubmitTwo(e)} id="webFormID">
          <label>Titel på formulär</label>
          <br />
          <input
            type="text"
            defaultValue={currentWebFormContent.title}
            id="title"
            data-form-type="form-title"
          />
          <br />
          <label>Instruktioner</label>
          <textarea
            type="text"
            defaultValue={currentWebFormContent.instructions}
            id={"instruction"}
            data-form-type="instructions"
          />
          <br />
          <label>Språk (skriv "se" eller "en")</label>
          <br />
          <input
            type="text"
            defaultValue={currentWebFormContent.language}
            id="language"
            data-form-type="form-language"
          />
          <br />
          <br />
          <br />
          {currentWebFormContent.questions.map((value, index) => {
            return (
              <div className="edit-form-question-container">
                <label>Fråga {index + 1}</label>
                <br />
                <br />
                <textarea
                  type="text"
                  defaultValue={value}
                  id={"question-" + index}
                  data-index={index}
                  data-form-type="question"
                />
                <br />
                <button
                  onClick={(e) => handleDeleteQuestionTwo(e)}
                  value={index}
                >
                  remove question
                </button>
                <br />
                <br />
              </div>
            );
          })}
          <button
            onClick={() => {
              handleAddQuestionTwo();
            }}
          >
            Lägg till en fråga +
          </button>
          <br />
          <button type="submit">Spara</button>
          <br />
          <button
            onClick={() => {
              history.push(`/admin`);
            }}
          >
            Tillbaka till admin
          </button>
          <br />
          <button
            onClick={(e) => {
              e.preventDefault();
              handleDeleteForm();
            }}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Ta bort formulär!
          </button>
        </form>
      </div>
    );
  }

  function handleAddQuestion() {
    var tempArrayQuestions = [...currentWebFormContent.questions];
    var tempArrayTitles = [...currentWebFormContent.titles];
    tempArrayQuestions.push("Insert Title");
    tempArrayTitles.push("Insert Question");
    setCurrentWebFormContent({
      ...currentWebFormContent,
      questions: tempArrayQuestions,
      titles: tempArrayTitles,
    });
  }

  /*
  function handleChangeQuestions(e) {
    e.preventDefault();
    console.log(e.target.value, e.target.dataset["index"]);
    var tempArray = [...currentWebFormContent.questions];
    tempArray[e.target.dataset["index"]] = e.target.value;
    var temp = { ...currentWebFormContent, questions: tempArray };
    setCurrentWebFormContent(temp);
  }
  */

  function handleSubmitOne(e) {
    e.preventDefault();


    //Get questions text
    var newQuestions = [];
    var elements = document
      .getElementById("webFormID")
      .querySelectorAll("[data-form-type='question']");
    elements.forEach((element) => {
      newQuestions.push(element.value);
    });

    //Get question titles
    var newQuestionTitles = [];
    var elements = document
      .getElementById("webFormID")
      .querySelectorAll("[data-form-type='question-title']");
    elements.forEach((element) => {
      newQuestionTitles.push(element.value);
    });

    // Get form title
    var newFormTitle = e.target[0].value;

    // Get language 
    var newLanguage = e.target[1].value;

    // Get instructions
    var newInstructions = e.target[2].value;

    setCurrentWebFormContent({
      ...currentWebFormContent,
      questions: newQuestions,
      titles: newQuestionTitles,
      title: newFormTitle,
      language: newLanguage,
      instructions: newInstructions,
    });
    setSavePending(true);
  }

  function handleDeleteForm() {
    if (window.confirm("Är du säker på att du vill ta bort formuläret?")) {
      db.collection("forms")
        .doc(form)
        .delete()
        .then(() => {
          console.log("form deleted");
          history.push("/admin");
        })
        .catch((e) => {
          console.log("error: ", e);
        });
    }
  }

  function handleDeleteQuestion(e) {
    e.preventDefault(e);

    var tempArrayQuestions = [...currentWebFormContent.questions];
    //console.log("temp before:", temp);
    tempArrayQuestions.splice(e.target.value, 1);

    var tempArrayTitles = [...currentWebFormContent.titles];
    tempArrayTitles.splice(e.target.value, 1);
    //console.log("temp after:", temp);
    //console.log(currentWebFormContent);
    setCurrentWebFormContent({
      ...currentWebFormContent,
      questions: tempArrayQuestions,
      titles: tempArrayTitles,
    });
    setSavePending(true);
  }

  function saveData() {
    if (currentWebFormContent.type === "one") {
      db.collection("forms")
        .doc(form)
        .set({
          title: currentWebFormContent.title,
          questions: currentWebFormContent.questions,
          titles: currentWebFormContent.titles,
          type: "one",
          language: currentWebFormContent.language,
          instructions: currentWebFormContent.instructions
        })
        .then(() => {
          alert("Ändringarna har sparats!");
          setSavePending(false);
        })
        .catch((e) => {
          alert("Något gick fel, ändringarna sparades ej!");
          console.log("error: ", e);
        });
    }

    if (currentWebFormContent.type === "two") {
      db.collection("forms")
        .doc(form)
        .set({
          title: currentWebFormContent.title,
          questions: currentWebFormContent.questions,
          instructions: currentWebFormContent.instructions,
          type: "two",
          language: currentWebFormContent.language,
        })
        .then(() => {
          alert("Ändringarna har sparats!");
          setSavePending(false);
        })
        .catch((e) => {
          alert("Något gick fel, ändringarna sparades ej!");
          console.log("error: ", e);
        });
    }
  }
  function returnFormType() {
    if(currentWebFormContent !== undefined){
      if (currentWebFormContent.type === "one") {
        console.log("typeOne:", currentWebFormContent.type);
        return <ContentTypeOne />;
      }
      if (currentWebFormContent.type === "two") {
        return <ContentTypeTwo />;
      } else {
        return "error";
      }
    }else{
      return "laddar..."
    }
    
  }
  return (
    <div>
      {isLoading ? "loading..." : returnFormType()}

      <br />
      <h6>ID: {form}</h6>
      <br />
    </div>
  );
}

export default EditWebForms;
