import React, {useContext} from "react";
import "./Footer.css";
import { AppContext } from "./AppContext";
import instagramIcon from "../images/instagramIcon.png"
import youtubeIcon from "../images/youtubeIcon.png"
import linkedinIcon from "../images/linkedinIcon.png"
function Footer() {
  var { languageSE } = useContext(AppContext);
  return (
    <div className="footer">
      <div className="footer-col-one">
        <div className="footer-bold">Starka Resultat AB</div>
        <div>Hagviksstrand 38 B</div>
        <div>746 91 Bålsta</div>
      </div>
      <div className="footer-border" />
      <div className="footer-col-two">
        <div className="footer-bold">Henrik Pettersson</div>
        <div>henrik@starkaresultat.se</div>
        <div>+46 70 5554888 </div>
      </div>
      <div className="footer-icon-container">
        <img src={instagramIcon} onClick={()=>{window.open("https://www.instagram.com/starkaresultatab")}}/>
        <img src={youtubeIcon} onClick={()=>{if(languageSE){window.open("https://www.youtube.com/playlist?list=PLt2EkjY5g3QXi_jizYRKMsXLwdJniNuFQ")}else{window.open("https://www.youtube.com/playlist?list=PLt2EkjY5g3QUJnHbAdA5HvJILTTz40TA1")}}}/>
        <img src={linkedinIcon} onClick={()=>{window.open("https://www.linkedin.com/in/henrik-pettersson-8ab8202")}}/>
      </div>
    </div>
  );
}

export default Footer;
