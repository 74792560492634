import React from "react";

function QuestionCheckbox(props) {
  return (
    <div
      className="checkbox-container"
      onClick={() => {
        var temp = [...props.answers];
        temp[props.questionIndex] = props.number;
        props.setAnswer(temp);
      }}
    >
      <div className="checkbox" style={{ borderColor: props.colors.primary }}>
        <div
          style={{ backgroundColor: props.colors.primary }}
          className={
            props.answers[props.questionIndex] === props.number
              ? "checkbox-fill"
              : ""
          }
        />
      </div>
      {props.number}
    </div>
  );
}

export default QuestionCheckbox;
