import React, { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import "./navbar.css";
import "./navbar-mobile.css";
import Logo from "../images/starkaresultat-logo.png";
import LogoEn from "../images/strongresults-logo.png";
import { AppContext } from "./AppContext";

function Navbar() {
  var { languageSE, setLanguageSE, pageContent } = useContext(AppContext);

  useEffect(() => {
    var navbarContainer = document.getElementById("navbar-container");
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 100) {
        navbarContainer.classList.add("navbar-scrolled");
      } else {
        navbarContainer.classList.remove("navbar-scrolled");
      }
    });
  });

  useEffect(()=>{
    if(!languageSE){
      setClasses()
    }
  },[])



  function setClasses() {
    document
      .getElementById("navbar-language-se")
      .classList.toggle("navbar-language-selected");
    document
      .getElementById("navbar-language-en")
      .classList.toggle("navbar-language-selected");
  }

  /*

<div
          onClick={() => {
            document.getElementById("menu-list").classList.contains("menu-open")
              ? document
                  .getElementById("menu-list")
                  .classList.remove("menu-open")
              : document.getElementById("menu-list").classList.add("menu-open");
          }}
          id="menu-button-div"
        >

  */

  function toggleMenu() {
    document.getElementById("menu-list").classList.toggle("menu-open");
    document.getElementById("dark-menu-bg").classList.toggle("dark-open");
    document
      .getElementById("menu-bar-one")
      .classList.toggle("menu-bar-one-close");
    document
      .getElementById("menu-bar-two")
      .classList.toggle("menu-bar-two-close");
    document
      .getElementById("menu-bar-three")
      .classList.toggle("menu-bar-three-close");
  }

  function scrollToTop() {
    document.documentElement.scrollTop = 0;
  }

  return (
    <div id="navbar-container" className="navbar-container-default">
      <div className="navbar-logo">
        <img
          src={languageSE ? Logo : LogoEn}
          alt="Starka Resultat"
          id="navbar-logo"
          onClick={() => scrollToTop()}
        />
      </div>

      <nav id="menu-list" className="navbar-default">
        <div
          id="dark-menu-bg"
          className="dark-closed"
          onClick={() => toggleMenu()}
        />
        <div onClick={() => toggleMenu()} id="menu-button-div">
          <div id="menu-bar-one" className="menu-bar menu-bar-one"></div>
          <div id="menu-bar-two" className="menu-bar menu-bar-two"></div>
          <div id="menu-bar-three" className="menu-bar menu-bar-three"></div>
        </div>

        <ul>
          <li onClick={() => toggleMenu()}>
            <NavLink
              to="/"
              className="inactive-link"
              activeClassName="active-link"
              exact
            >
              COACHING
            </NavLink>
          </li>
          <li onClick={() => toggleMenu()}>
            <NavLink
              to="/services"
              className="inactive-link"
              activeClassName="active-link"
              exact
            >
              {pageContent.sectionTwoMainTitle.toUpperCase()}
            </NavLink>
          </li>
          <li onClick={() => toggleMenu()}>
            <NavLink
              to="/varvtalsraknaren"
              className="inactive-link"
              activeClassName="active-link"
              exact
            >
              {pageContent.navbarPageLinkSpeedIndicator}
            </NavLink>
          </li>

          <li onClick={() => toggleMenu()}>
            <NavLink
              to="/om"
              className="inactive-link"
              activeClassName="active-link"
              exact
            >
              {pageContent.navbarPageLinkAbout}
            </NavLink>
          </li>
          <li onClick={() => toggleMenu()}>
            <NavLink
              to="/contact"
              className="inactive-link"
              activeClassName="active-link"
              exact
            >
              {pageContent.navbarPageLinkContact}
            </NavLink>
          </li>
          <div className="navbar-language-line" />
          <li
            className="navbar-language-link"
            onClick={() => {
              languageSE ? setLanguageSE(false) : setLanguageSE(true);
              setClasses();
              //console.log(languageSE);
            }}
          >
            <span
              id="navbar-language-se"
              className="navbar-language-text navbar-language-selected"
            >
              SE
            </span>
            <span id="navbar-language-en" className="navbar-language-text">
              EN
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;

/*
   <li>
            <NavLink
              to="/artiklar"
              className="inactive-link"
              activeClassName="active-link"
              exact
            >
              {pageContent.navbarPageLinkMonthly}
            </NavLink>
          </li>
*/

/* Språk, lägg till senare

<div className="navbar-language-line" />
          <li
            className="navbar-language-link"
            onClick={() => {
              languageSE ? setLanguageSE(false) : setLanguageSE(true);
              setClasses();
              console.log(languageSE);
            }}
          >
            <span
              id="navbar-language-se"
              className="navbar-language-text navbar-language-selected"
            >
              SE
            </span>
            <span id="navbar-language-en" className="navbar-language-text">
              EN
            </span>
          </li>


*/
