import React, { useContext } from "react";
import { AppContext } from "./AppContext";
import "./ContentThree.css";
import ettCoachandeLedarskap from "./documents/Ett Coachande Ledarskap.pdf";
import vinnandeFörsäljning from "./documents/Vinnande försäljning ws.pdf";
import ettCoachandeLedarskapEN from "./documents/Coaching in Leadership.pdf";
import vinnandeFörsäljningEN from "./documents/Winning sales-2.pdf";
function ContentThree() {
  var { pageContent, languageSE } = useContext(AppContext);
  return (
    <div id="ContentThree" className="contentthree-container">
      <div className="content-main-title">{pageContent.contentThree001}</div>
      <br />
      <div className="content-sub-title">{pageContent.contentThree002}</div>
      <br />
      <div className="content-sub-title">{pageContent.contentThree003}</div>

      <div class-name="content-quote">{pageContent.contentThree004}</div>
      <br />
      <div className="content-sub-title">{pageContent.contentThree005}</div>

      <div class-name="content-quote">{pageContent.contentThree006}</div>
      <br />
      <div className="content-sub-title">{pageContent.contentThree007}</div>
      <ul>
        <li>{pageContent.contentThree008}</li>
        <div className="content-list-extra ">
          <a
            href={languageSE ? ettCoachandeLedarskap : ettCoachandeLedarskapEN}
            download
            rel="noopener noreferrer"
          >
            <div className="button-download-three">
              {pageContent.contentTwoDownloadPdf}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.803"
                height="17.991"
                viewBox="0 0 13.803 17.991"
                className="svg-download-three"
              >
                <g
                  id="Group_179"
                  data-name="Group 179"
                  transform="translate(23981.501 13339.522)"
                >
                  <line
                    id="Line_14"
                    data-name="Line 14"
                    y2="10.914"
                    transform="translate(-23974.598 -13338.522)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_15"
                    data-name="Line 15"
                    x1="11.803"
                    transform="translate(-23980.5 -13322.531)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                    transform="translate(-1.042 -6.535)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
          </a>
        </div>
        <br />
        <div className="content-bold">{pageContent.contentThree009}</div>
        <br />
        <li>{pageContent.contentThree010}</li>
        <div className="content-list-extra ">
          <a
            href={languageSE ? vinnandeFörsäljning : vinnandeFörsäljningEN}
            download
            rel="noopener noreferrer"
          >
            <div className="button-download-three">
              {pageContent.contentTwoDownloadPdf}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.803"
                height="17.991"
                viewBox="0 0 13.803 17.991"
                className="svg-download-three"
              >
                <g
                  id="Group_179"
                  data-name="Group 179"
                  transform="translate(23981.501 13339.522)"
                >
                  <line
                    id="Line_14"
                    data-name="Line 14"
                    y2="10.914"
                    transform="translate(-23974.598 -13338.522)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_15"
                    data-name="Line 15"
                    x1="11.803"
                    transform="translate(-23980.5 -13322.531)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                    transform="translate(-1.042 -6.535)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
          </a>
        </div>
      </ul>
    </div>
  );
}

export default ContentThree;
