import React, { useContext, useEffect } from "react";
import { AppContext } from "./AppContext";
import JPITest from "./JPITest";
import "./gpi.css";

function JPI() {
  const { setOnAdminPage } = useContext(AppContext);
  useEffect(() => {
    //window.scrollTo(0, 0);
    setOnAdminPage(true);
  });
  return (
    <div className="gpi-container">
      <JPITest />
    </div>
  );
}

export default JPI;
