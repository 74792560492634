import React from "react";
import "./Progressbar.css";

function Progressbar(props) {
  function questionDone(question) {
    if (props.quizProgress === 16) {
      //document.querySelectorAll(".progress-arrow").forEach((arrow) => {
      //  arrow.classList.add("progress-arrow-show");
      //});
      document.getElementById("arrow-1").classList.add("progress-arrow-show");
      document
        .getElementById("arrow-6")
        .classList.remove("progress-arrow-show");
      document
        .getElementById("arrow-11")
        .classList.remove("progress-arrow-show");
    }
    if (props.quizProgress === 17) {
      document.getElementById("arrow-6").classList.add("progress-arrow-show");
      document
        .getElementById("arrow-1")
        .classList.remove("progress-arrow-show");
      document
        .getElementById("arrow-11")
        .classList.remove("progress-arrow-show");
    }
    if (props.quizProgress === 18) {
      document.getElementById("arrow-11").classList.add("progress-arrow-show");
      document
        .getElementById("arrow-1")
        .classList.remove("progress-arrow-show");
      document
        .getElementById("arrow-6")
        .classList.remove("progress-arrow-show");
    }
    if (props.answers[question] !== 0) {
      return (
        <div
          className={
            props.quizProgress === 0 || props.quizProgress > 15
              ? "meter-progressbar-bar"
              : "meter-progressbar-bar-show"
          }
          style={{ backgroundColor: props.colors.primary }}
          onClick={() =>
            props.setQuizProgress(props.startQuizProgress + question)
          }
        ></div>
      );
    } else {
      return (
        <div
          className={
            props.quizProgress === 0 || props.quizProgress > 15
              ? "meter-progressbar-bar"
              : "meter-progressbar-bar-show"
          }
          style={{ backgroundColor: props.colors.secondary }}
          onClick={() => {
            if (props.quizProgress !== 0) {
              props.setQuizProgress(props.startQuizProgress + question);
            }
          }}
        />
      );
    }
  }
  return (
    <div className="meter-progressbar">
      {questionDone(0)}
      {questionDone(1)}
      {questionDone(2)}
      {questionDone(3)}
      {questionDone(4)}
      <div
        id={`arrow-${props.startQuizProgress}`}
        className="progress-arrow"
        style={{ borderBottom: `1.5em solid ${props.colors.secondary}` }}
      ></div>
    </div>
  );
}

export default Progressbar;

/*

  <svg
          xmlns="http://www.w3.org/2000/svg"
          height="15px"
          viewBox="0 0 54 27"
          style={{ stroke: props.colors.secondary }}
        >
          <path
            id="Path_112"
            data-name="Path 112"
            d="M4446.3-15484.03l20,15,20-15"
            transform="translate(-4439.303 15491.03)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="10"
          ></path>
        </svg>
      
*/
