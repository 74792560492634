import React, { useContext, useEffect } from "react";
import { AppContext } from "./AppContext";
import GPITest from "./GPITest";
import "./gpi.css";

function GPI() {
  const { setOnAdminPage } = useContext(AppContext);
  useEffect(() => {
    //window.scrollTo(0, 0);
    setOnAdminPage(true);
  });
  return (
    <div className="gpi-container">
      <GPITest />
    </div>
  );
}

export default GPI;
