import React from "react";

function MeterButton(props) {
  function checkButtonState() {
    var stylestate;
    var classStyle;
    if (props.active) {
      if (window.innerWidth < 600) {
        styleState = {
          backgroundColor: props.colors.secondary,
          color: "rgba(255,255,255,0.8)",
          //border: `1px solid ${props.colors.primary}`,
        };
        classStyle = "meter-button meter-button-active";
      } else {
        styleState = {
          backgroundColor: props.colors.secondary,
          color: "rgba(255,255,255,0.8)",
          //border: `2px solid ${props.colors.primary}`,
        };
        classStyle = "meter-button meter-button-active";
      }
    } else {
      var styleState = {
        backgroundColor: props.colors.primary,
        color: "white",
      };
      classStyle = "meter-button";
    }

    return (
      <div
        className={classStyle}
        style={styleState}
        onClick={() => {
          if (props.quizProgress !== 0) {
            if (props.quizDone) {
              props.setQuizProgress(props.returnQuizProgressTo[1]);
            } else {
              props.setQuizProgress(props.returnQuizProgressTo[0]);
            }
          }
        }}
      >
        {props.title}
      </div>
    );
  }
  return checkButtonState();
}

export default MeterButton;
