import React, { useContext } from "react";
import { AppContext } from "./AppContext";
import QuestionCheckbox from "./QuestionCheckbox";

/*
<Questions
            quizProgress={quizProgress}
            quizQuestions={quizQuestions[0]}
            quizQuestionsStart={1}
            setQuizProgress={setQuizProgress}
            setAnswer={setPartOneAnswer}
            answers={partOneAnswer}
            colors={quizStateColors[0]}
          />
*/

function QuestionsInResults(props) {
  var { languageSE } = useContext(AppContext);
  return (
    <div
      className="quiz-content-results"
      style={{ backgroundColor: props.colors.secondary }}
    >
      <div className="question-container-results">
        <div className="question-title" style={{ color: props.colors.primary }}>
          {languageSE ? "Fråga" : "Question"} {props.questionIndex + 1}
        </div>
        <div className="question-text">
          {props.quizQuestions[props.questionIndex]}
        </div>

        <div className="quiz-checkbox-container">
          <QuestionCheckbox
            number={1}
            setAnswer={props.setAnswer}
            answers={props.answers}
            questionIndex={props.questionIndex}
            colors={props.colors}
          />
          <QuestionCheckbox
            number={2}
            setAnswer={props.setAnswer}
            answers={props.answers}
            questionIndex={props.questionIndex}
            colors={props.colors}
          />
          <QuestionCheckbox
            number={3}
            setAnswer={props.setAnswer}
            answers={props.answers}
            questionIndex={props.questionIndex}
            colors={props.colors}
          />
          <QuestionCheckbox
            number={4}
            setAnswer={props.setAnswer}
            answers={props.answers}
            questionIndex={props.questionIndex}
            colors={props.colors}
          />
          <QuestionCheckbox
            number={5}
            setAnswer={props.setAnswer}
            answers={props.answers}
            questionIndex={props.questionIndex}
            colors={props.colors}
          />
        </div>
      </div>
    </div>
  );
}

export default QuestionsInResults;
