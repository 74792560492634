import React, { useEffect, useState, useContext } from 'react'
import { AppContext } from "./AppContext";
import "./BrainzPromter.css"
import BrainzCover from "../images/Henrik-Pettersson-Brainz-Cover.jpg"
import CoachVideo from "../images/coachVideo.jpg"
import "./loading.css";

import strategiSession from "./documents/Strategi Session - Frågeformulär.pdf";
import strategiSessionEN from "./documents/Strategy-Session-Questionnaire.pdf";

function CoachPromter() {

    var [closeSignUp, setCloseSignUp] = useState(false);
    var [userEmail, setUserEmail] = useState("");
    var { languageSE, db, pageContent } = useContext(AppContext);
    var [showVideo, setShowVideo] = useState(false)
    const [videoLoaded, setVideoLoaded] = useState(false)
    const [showReviews, setShowReviews] = useState(false)
    const [currentReview, setCurrentReview] = useState(0)

    const reviewsText = [{
        grade: `Betyg på kursen som helhet: 5/5`,
        text: `Ledar coachingen har gett mig så mycket mer än jag initialt förväntade. Mod, som jag ansåg jag
        hade gott om innan, där har jag steppat upp ett antal steg. Det hjälper mig att fejsa mina
        svagheter och agera på dom tydligare, snabbare och effektivare. Henrik har gjort mig till en
        starkare människa - tusen tack.`,
        author: `Magnus Lindmark`
    }, {
        grade: `Betyg på kursen som helhet: 5/5`,
        text: `Ledarcoaching via starka resultat har varit otroligt givande att gå. Henrik Pettersson utmanar dig
        i ditt ledarskap genom att ställa frågor som gör att du själv kommer fram till de verktyg du
        behöver för att nå dina mål och sätta din agenda för att skapa hållbara resultat över tid. Den
        individuella coachningen gav mig konkreta verktyg i vardagen som jag aktivt kommer att arbeta
        med i fortsättningen, både i arbetslivet men också privat. Henrik har en förmåga till att få dig att
        utveckla dig själv. Mitt tips till andra som vill gå utbildningen är att vara absolut transparant mot
        sig själv för att verkligen få de verktyg du behöver för att nå dina resultat. Jag kommer varmt att
        rekommendera Starka resultat vidare!`,
        author: `Lisa Åberg`
    }, {
        grade: `Betyg på kursen som helhet: 5/5`,
        text: `Henrik hjälpte mig att gräva i djupet vem jag är i ledarrollen, varför jag gör som jag gör och vilka
        egenskaper som är viktiga hos en ledare samt vilka egenskaper som jag tänker leda utifrån. Han
        hjälpte mig också att se utifrån en medarbetares perspektiv och hur jag vill uppfattas av teamet.
        Förutom detta hjälpte han mig att bygga en stark bas i mig som människa för att kunna vara en
        starkare ledare.`,
        author: `Pilar Matta`
    }, {
        grade: `Betyg på kursen som helhet: 5/5`,
        text: `Henriks coaching har hjälpt mig förstärka min inre potential och se till de positiva aspekterna av
        den jag är. I dagens stressiga och konkurrensintensiva värld är det enkelt att man fokuserar på
        problemen omkring sig, och i den processen missar möjligheterna. Coachning via Henrik har
        hjälpt mig få ett mer positivt mindset och att lägga fokus på de fina jag har uppnått i livet och vad
        som mer är möjligt, istället för att lägga energi på problem. Numera ser jag mina mindre bra sidor
        som en möjlighet till utveckling snarare än hinder/svagheter.`,
        author: `Nathalie Palmear`
    }]

    const reviewsTextEN = [{
        grade: `Grade on the coaching sessions: 5/5`,
        text: `Coaching for strong results, has given me so much more than I initially expected. Courage,
        which I thought I had plenty of before, there I have stepped up a number of levels. It helps
        me to face my weaknesses and act on them more clearly, faster and more effectively. Henrik
        has made me a stronger person - thank you so much`,
        author: `Magnus Lindmark`
    }, {
        grade: `Grade on the coaching sessions: 5/5`,
        text: `Coaching through strong results has been incredibly rewarding for me. Henrik Pettersson
        challenges you in your leadership by asking questions that allow you to come up with the
        tools you need, to achieve your goals and set your agenda to create sustainable results over
        time. The individual coaching gave me concrete tools in everyday life that I will actively work
        with in the future, both in working life but also privately. Henrik has an ability to make you
        develop yourself. My advice to others who want to attend the training is to be absolutely
        transparent to themselves to really create the mindset you need to achieve your results. I
        will warmly recommend Strong Results further!`,
        author: `Lisa Åberg`
    }, {
        grade: `Grade on the coaching sessions: 5/5`,
        text: `Henrik helped me to dig deep into who I am in the leadership role, why I do as I do, and
        what important qualities leaders require, and what qualities I intend to lead from. He also
        helped me see from an employee's perspective and how I want to be perceived by the team.
        In addition to this, he helped me build a strong base as a human being in order to be a
        stronger leader.`,
        author: `Pilar Matta`
    }, {
        grade: `Grade on the coaching sessions: 5/5`,
        text: `Henrik's coaching has helped me strengthen my inner potential and ensure the positive
        aspects of who I am. In today's stressful and competitive world, it is easy to focus on the
        problems around you, and in that process miss the opportunities. Coaching through Henrik
        has helped me get a more positive mindset and to focus on the good things I have achieved
        in life, and what is more possible, instead of putting energy into problems. Nowadays, I see
        my less good sides as an opportunity for development rather than obstacles / weaknesses.`,
        author: `Nathalie Palmear`
    }]

    useEffect(() => {
        document.addEventListener('scroll', scrollListener)
        if (closeSignUp) {
            document.getElementById("signupContainer").classList.add("slideOut-brainz");
            document.getElementById("signupContainer").classList.remove("slideIn-brainz");
        }
        return () => document.removeEventListener('scroll', scrollListener)
    }, [closeSignUp])

    useEffect(() => {
        if (showReviews || showReviews) {
            document.getElementById("signupContainer").classList.add("slideOut-brainz");
            document.getElementById("signupContainer").classList.remove("slideIn-brainz");
        } else {
            document.getElementById("signupContainer").classList.remove("slideOut-brainz");
            document.getElementById("signupContainer").classList.add("slideIn-brainz");
        }
    }, [showVideo, showReviews])
    /*
    function addEmailToFirebase(){
        if(userEmail !== ""){
            db.collection("emails")
        .add({email: userEmail})
        .then(()=>{
            setCloseSignUp(true)
        })
        .catch((e)=>{
            console.log(e)
        })
        }
    }
    */
    const scrollListener = () => {
        var scrollY = window.scrollY || window.pageYOffset;
        if (!closeSignUp) {
            if (scrollY > window.innerHeight * 0.5 - 200) {
                document.getElementById("signupContainer").classList.add("slideOut-brainz");
                document.getElementById("signupContainer").classList.remove("slideIn-brainz");
            } else {
                document.getElementById("signupContainer").classList.remove("slideOut-brainz");
                document.getElementById("signupContainer").classList.add("slideIn-brainz");
            }
        }

    }

    function handleChange(e) {
        setUserEmail(e.target.value);
        console.log(userEmail);
    }

    function handleSubmit(e) {
        e.preventDefault()
        //addEmailToFirebase()
    }

    const engVideo = () => {
        return (
            <iframe id="ik_player_iframe" onLoad={() => { setVideoLoaded(true) }} width={window.innerWidth * 0.8} height={window.innerWidth > 500 ? window.innerHeight * 0.7 : window.innerHeight * 0.4} src="https://www.youtube-nocookie.com/embed/Zzibld-4_8E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        )
    }

    const seVideo = () => {
        return (
            <iframe id="ik_player_iframe" onLoad={() => { setVideoLoaded(true) }} width={window.innerWidth * 0.8} height={window.innerWidth > 500 ? window.innerHeight * 0.7 : window.innerHeight * 0.4} src="https://www.youtube-nocookie.com/embed/63Kr7w9umjE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        )
    }


    function loading() {
        return (
            <div className="loading-container-video">
                <svg height="100" width="100" viewBox="0 0 250 250">
                    <circle
                        id="loading-circle-one"
                        className="loading-circle"
                        cx="125"
                        cy="125"
                        r="30"
                    />
                    <circle
                        id="loading-circle-two"
                        className="loading-circle"
                        cx="125"
                        cy="125"
                        r="60"
                    />
                    <circle
                        id="loading-circle-three"
                        className="loading-circle"
                        cx="125"
                        cy="125"
                        r="90"
                    />
                </svg>
            </div>
        )
    }

    const seVideoSessionButton = () => {
        return (
            <a
                href={strategiSession}
                download
                rel="noopener noreferrer"
            >
                <div className="video-button-pdf" onClick={(event) => { event.stopPropagation(); }}>Strategi Session
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.803"
                        height="17.991"
                        viewBox="0 0 13.803 17.991"
                        id="svg-download"
                    >
                        <g
                            id="Group_179"
                            data-name="Group 179"
                            transform="translate(23981.501 13339.522)"
                        >
                            <line
                                id="Line_14"
                                data-name="Line 14"
                                y2="10.914"
                                transform="translate(-23974.598 -13338.522)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="2"
                            />
                            <line
                                id="Line_15"
                                data-name="Line 15"
                                x1="11.803"
                                transform="translate(-23980.5 -13322.531)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="2"
                            />
                            <path
                                id="Path_93"
                                data-name="Path 93"
                                d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                                transform="translate(-1.042 -6.535)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </g>
                    </svg>
                </div>
            </a>
        )
    }

    const enVideoSessionButton = () => {
        return (
            <a
                href={strategiSessionEN}
                download
                rel="noopener noreferrer"
            >
                <div className="video-button-pdf" onClick={(event) => { event.stopPropagation(); }}>Strategy Session
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.803"
                        height="17.991"
                        viewBox="0 0 13.803 17.991"
                        id="svg-download"
                    >
                        <g
                            id="Group_179"
                            data-name="Group 179"
                            transform="translate(23981.501 13339.522)"
                        >
                            <line
                                id="Line_14"
                                data-name="Line 14"
                                y2="10.914"
                                transform="translate(-23974.598 -13338.522)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="2"
                            />
                            <line
                                id="Line_15"
                                data-name="Line 15"
                                x1="11.803"
                                transform="translate(-23980.5 -13322.531)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeWidth="2"
                            />
                            <path
                                id="Path_93"
                                data-name="Path 93"
                                d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                                transform="translate(-1.042 -6.535)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </g>
                    </svg>
                </div>
            </a>
        )
    }

    const nextReview = () => {
        if (currentReview == (reviewsText.length - 1)) {
            setCurrentReview(0)
        } else {
            setCurrentReview(currentReview + 1)
        }
    }

    const prevReview = () => {
        if (currentReview == 0) {
            setCurrentReview(reviewsText.length - 1)
        } else {
            setCurrentReview(currentReview - 1)
        }
    }

    const reviews = () => {
        return (
            <div className="signup-review-container" onClick={(event) => { event.stopPropagation(); nextReview() }}>
                <h5 >{languageSE ? reviewsText[currentReview].grade : reviewsTextEN[currentReview].grade}</h5>
                <p className="signup-review-text">{languageSE ? reviewsText[currentReview].text : reviewsTextEN[currentReview].text}</p>
                <div className="signup-review-text-container">
                    <div className="signup-review-arrow signup-review-arrow-left" onClick={(event) => { event.stopPropagation(); prevReview() }}>{"<"}</div>
                    <h5>- {languageSE ? reviewsText[currentReview].author : reviewsTextEN[currentReview].author}</h5>
                    <div className="signup-review-arrow signup-review-arrow-right" onClick={(event) => { event.stopPropagation(); nextReview() }}>{">"}</div>
                </div>

            </div>
        )
    }

    const reviewDots = () => {
        var dots = reviewsText.map((obj, index) => {
            return <div className={index == currentReview ? "review-dot review-dot-active" : "review-dot"}
                onClick={(event) => { event.stopPropagation(); setCurrentReview(index) }}
            />
        })
        return <div className={"review-dots-container"} onClick={(event) => { event.stopPropagation(); nextReview() }}>{dots}</div>
    }

    return (
        <div className="signup-container-brainz slideIn-brainz" id="signupContainer">
            <div className="close-popup-brainz" onClick={() => { setCloseSignUp(true) }}>x</div>
            <div className="singup-info-brainz">
                <img className="video-image" src={CoachVideo} alt="Henrik-Pettersson-Coach-Intro" onClick={() => { setShowVideo(true) }} />
            </div>
            <div className="signup-title-brainz">{languageSE ? "Värde-Baserad Kultur" : "Value-Based Culture"}</div>

            <div className={showVideo ? "signup-container-video-fullscreen" : "hide-video"} onClick={() => { setShowVideo(false) }} >
                {showVideo && videoLoaded == false ? loading() : null}
                {showVideo && languageSE ? seVideo() : null}
                {showVideo && languageSE == false ? engVideo() : null}
                <div className="video-button-container">
                    {showVideo ? <div className="video-close-text">{languageSE ? "Stäng" : "Close"}</div> : null}
                </div>

            </div>
            <div className={showReviews ? "signup-container-review-fullscreen" : "hide-video"} onClick={() => { setShowReviews(false) }} >
                {showReviews ? reviews() : null}
                {showReviews ? reviewDots() : null}
                <div className="review-button-container">
                    {showReviews ? <div className="video-close-text">{languageSE ? "Stäng" : "Close"}</div> : null}
                </div>

            </div>
        </div>
    )
}

export default CoachPromter