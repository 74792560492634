import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "./AppContext";
import "./WebForm.css";
import logo from "../images/starkaresultat-logo.png";
import logoEN from "../images/strongresults-logo.png";
import FormMeter from "./FormMeter";
import Loading from "./Loading";

function WebForm() {
  const { db, setOnAdminPage } = useContext(AppContext);
  const { form } = useParams();
  const [formContent, setFormContent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [formAnswers, setFormAnswers] = useState([]);
  const [quizDone, setQuizDone] = useState(false);
  const [quizResults, setQuizResults] = useState(0);
  useEffect(() => {
    //window.scrollTo(0, 0);
    setOnAdminPage(true);
  },[]);

  //Load in data
  useEffect(() => {
    var id = form;

    //console.log(id);
    db.collection("forms")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log(doc.data().questions);
          //console.log("type:", doc.data().type);
          var tempAnswersArray = Array(doc.data().questions.length).fill(0);

          setFormContent(doc.data());
          setIsLoading(false);
          setFormAnswers(tempAnswersArray);
        } else {
          console.log("No such form");
        }
      });
  },[]);

  useEffect(() => {
    console.log("FormContent: ", formContent);
  }, [formContent]);

  //Checkes if quiz is done and if so sets quizDone = true
  useEffect(() => {
    if (!formAnswers.includes(0) && !isLoading) {
      var points = getResults();
      setQuizDone(true);
      setQuizResults(points);
    }
  }, [formAnswers]);

  function getResults() {
    var temp = [...formAnswers];
    var sum = temp.reduce((a, b) => a + b, 0);
    var points = Math.round(100 * (sum / (5 * formAnswers.length)));
    return points;
  }

  function CheckBoxes(index) {
    return (
      <div className="form-checkbox-container">
        {CheckBox(1, formAnswers[index] === 1, index)}
        {CheckBox(2, formAnswers[index] === 2, index)}
        {CheckBox(3, formAnswers[index] === 3, index)}
        {CheckBox(4, formAnswers[index] === 4, index)}
        {CheckBox(5, formAnswers[index] === 5, index)}
      </div>
    );
  }

  function handleCheck(e) {
    var temp = [...formAnswers];
    temp[e.currentTarget.dataset.questionindex] = parseInt(
      e.currentTarget.dataset.value
    );
    setFormAnswers(temp);
  }

  function CheckBox(questionNumber, value, questionIndex = 0) {
    return (
      <div className="form-checkbox">
        <div
          className="form-circle"
          onClick={(e) => {
            //console.log("funk: ", questionNumber, questionIndex);
            handleCheck(e);
          }}
          data-questionindex={questionIndex}
          data-value={questionNumber}
        >
          <div
            className={value ? "form-checked" : "form-checked form-unchecked"}
          />
        </div>
        <div>{questionNumber}</div>
      </div>
    );
  }

  /*
För varje av de {formContent.questions.length} delarna under, lägg
            till din självskattningspoäng (lägst 1, högst 5). Det kommer ge dig
            en poäng utifrån 100%. Oavsett din poäng, försök att förbättra dig
            själv på dessa områden för de har en stor påverkan på din framtida
            framgång och leverans av starka resultat. (Handen på hjärtat).
  */

  function ContentTypeOne() {
    return (
      <div className="form-container">
        <div className="form-page">
          <div className="logo-container">
            <img src={formContent.language == "se" ? logo : logoEN} alt="Starka Resultat" />
          </div>
          <div className="title-container">{formContent.title}</div>
          <div className="form-instructions-container">
            {formContent.instructions}
          </div>

          <div className="form-all-questions-container">
            {isLoading
              ? "loading..."
              : formContent.questions.map((value, index) => {
                  return (
                    <div className="form-question-container">
                      <div className="form-question-title">
                        {formContent.titles[index]}
                      </div>
                      <div className="form-question-text">{value}</div>
                      {CheckBoxes(index)}
                    </div>
                  );
                })}
          </div>
          <div className="form-meter-container">
            <div className="form-meter-size">
              <FormMeter points={quizResults} quizDone={quizDone} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ContentTypeTwo() {
    return (
      <div className="form-container">
        <div className="form-page">
          <div className="logo-container">
            <img src={formContent.language == "se" ? logo : logoEN} alt="Starka Resultat" />
          </div>
          <div className="title-container">{formContent.title}</div>
          <div className="form-instructions-container">
            {formContent.instructions}
          </div>

          <div className="form-all-questions-container">
            {isLoading
              ? "loading..."
              : formContent.questions.map((value, index) => {
                  return (
                    <div className="form-question-container">
                      <div className="form-question-text">{value}</div>
                      <form className="form-answers">
                        <textarea className="form-textarea">Svar...</textarea>
                      </form>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    );
  }

  function returnFormType() {
    if (formContent.type === "one") {
      //console.log("typeOne:", formContent.type);
      return <ContentTypeOne />;
    }
    if (formContent.type === "two") {
      return <ContentTypeTwo />;
    } else {
      return "error";
    }
  }

  return <div>{isLoading ? <Loading /> : returnFormType()}</div>;
}

export default WebForm;

//<FormQuestion formContent={formContent} />
// For print <div className="form-print-button">Print</div>
