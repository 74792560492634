import "./Services.css"
import ContentOne from "./ContentOne";
import ContentTwo from "./ContentTwo";
import ContentThree from "./ContentThree";

import serviceOne from "../images/service-one.png";
import serviceTwo from "../images/service-two.png";
import serviceThree from "../images/service-three.png";
import strategiSession from "./documents/Strategi Session - Frågeformulär.pdf";
import strategiSessionEN from "./documents/Strategy-Session-Questionnaire.pdf";
import ettCoachandeLedarskap from "./documents/Ett Coachande Ledarskap.pdf";
import vinnandeFörsäljning from "./documents/Vinnande försäljning ws.pdf";
import ettCoachandeLedarskapEN from "./documents/Coaching in Leadership.pdf";
import vinnandeFörsäljningEN from "./documents/Winning sales-2.pdf";
import värdebaseradförsäljning from "./documents/Värde-Baserad Försäljning.pdf"
import värdebaseradförsäljningEN from "./documents/Value-Based Selling.pdf"
import värdebaseradledarskap from "./documents/Värde-Baserat Ledarskap.pdf"
import värdebaseradledarskapEN from "./documents/Value-Based Leadership Performance.pdf"

import värdebaseradkultur from "./documents/Värde-Baserad Kultur - 3h workshop.pdf"
import värdebaseradkulturEN from "./documents/Value-Based Culture Workshop.pdf"

import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "./AppContext";
import "./ContentOne.css";

import Footer from "./Footer";

function Services() {

  const [currentFilter, setCurrentFilter] = useState("all")
  const [inFocus, setInFocus] = useState(0)
  const [active, setActive] = useState(false)
  let history = useHistory();

  const vbsText = [[`Detta program är designat för att utveckla individens egna inre driv. Programmet är byggt för att skapa och utnyttja teamets gemensamma styrkor på bästa sätt. Vad är ”menyn” som de primärt bör jobba utifrån? Teamet kommer att få jobba tillsammans i workshop form och ”promota” hållbara best practice modeller på respektive område`],
  [`This program is designed to develop the individual's own inner drive. The program is built to create and utilize the team's common strengths in the best way. What is the "menu" that they should primarily work from? The team will get to work together in workshop form and "promote" sustainable best practice models in each area.`],
[`Säljutvecklingsprogram`],[`Sales Development Program`]]

  const vblText = [
    [`Detta program är designat för att utveckla ledare oavsett affärsområdesansvar. Programmet bygger på att skapa din egen tydliga ledarskapskultur och ge dig effektiva verktyg som supportar dig att driva verksamheten ur ett ledarskapsperspektiv. Vi jobbar med det coachande förhållningssättet och ger dig en stabil plattform för att jobba med såväl kultur - målstyrning och professionell coaching.`],
    [`This program is designed to develop leaders regardless of business area or responsibility. The program is based on creating your own clear leadership culture and giving you effective tools that support you in running the business from a leadership perspective. We work with the coaching approach and give you a stable platform to work with both culture - goal steering and professional coaching.`],
  [`Ledarutvecklingsprogram – Management program`],[`Leadership development – Management program`]]


    const vbcText = [
      [`Den här workshopen är designad för att utveckla team oavsett affärsområde. Workshopen bygger på att skapa en högre nivå av samsyn och ge konkreta verktyg som supportar i att driva verksamheten ur ett värdeskapande perspektiv. Vi jobbar med det coachande förhållningssättet och en stabil plattform för att jobba för att stärka er organisations kultur.`],
    [`This workshop is designed to develop teams regardless of business area. The workshop is based on creating a higher level of consensus and providing concrete tools that support running the business from a value - based perspective. We work with the coaching approach and a stable platform to strengthen your organization's culture.`],
    [`Vinnande Team - Kultur utveckling – Workshop`],
    [`Winning Team – Culture development – Workshop`]]

  const courseContet = [
    {
      title: "HIGH PERFORMANCE COACHING",
      intro: "High-performance coaching kommer ge dig insikter, fokus och metoder som du behöver för att leverera just starkare resultat som du längtar efter. Vi genomför varje år ca 250 h högpresterande resultatdriven träning inom coaching, som ger dig en försäkran om att vi vet vad vi pratar om. Om du har en önskan om att vässa dig till att leverera starka resultat, då är det här programmet för dig.",
      filterWord: "coaching"
    },
    {
      title: "CHEF- OCH LEDARSKAPSCOACHING",
      intro: "Chef- och ledarskapscoaching är ett ultimat verktyg för att uppnå starka resultat – faktum är att några av de mest framgångsrika företagen och entreprenörerna på Jorden, lutar sig mot vassa coacher. Undersökningar visar att 6 av 10 chefer idag känner sig osäkra på sin roll. Hur de leder sig själv och hur de leder andra? Vid partnerskap med Starka Resultat så får du 1:1 möten varje vecka och expert guidning för att hålla linjen över tid.",
      filterWord: "coaching"
    },
    {
      title: "Fly dina undanflykter – omvandla de till action",
      intro: "I den här föreläsningen, med mycket skratt och igenkänning, berättar Henrik om 10 saker som orsakar undanflykter och och vad vi kan lära oss av dem. Om hur vi kan omvandla undanflykterna till action. En rolig och inspirerande föreläsning om källorna som oftast är i spel, tillsammans med lösningar!",
      filterWord: "inspiration"

    },
    {
      title: "Vinnande Team - De vinnandes 5 kännetecken",
      intro: "Spelssystem är ett bra ord för Henriks inspirationsföreläsning, som tar tag i det som är avgörande för att kunna skapa verklig motivation och arbetslust. Henrik Fokuserar på ämnena förändring, eget ansvar, hantera press, komma bättre överens med varandras olikheter, och hur vi skapar mer flyt. En inspirerande och lärorik föreläsning fylld med energi.",
      filterWord: "inspiration"
    },
    {
      title: "Vinnande Försäljning - 3 vassa kännetecken",
      intro: "Henrik inspirerar och utmanar morgonrutinen för att sätta starkt fokus på dagen idag. ”Lillfinger” fokus ger starka resultat. Att hitta kraften till action i sin egen plattform och värdegrund. Piggsvin är oftast inget vi vill ha i handen – men är en kraftfull teknik i de flesta sammanhang. Välkommen på en motiverande säljboost!",
      filterWord: "inspiration"
    },
    {
      title: "Coachande Ledarskap – Ledarutvecklings - Management program",
      intro: "Detta program är designat för att utveckla ledarna på företaget oavsett affärsområdesansvar. Programmet bygger på att gemensamt skapa er sälj & ledarskapskultur och får ett samsyn hur ni skall driva ur ett ledarskapsperspektiv. Vi jobbar med det coachande förhållningssättet och ger er verktyg för att jobba med såväl säljkultur - målstyrning och personlig uppföljning.",
      filterWord: "education"
    },
    {
      title: "Vinnande försäljning",
      intro: "Det här programmet är designat för att utveckla säljteamets egna driv med utgångspunkt i bolagets värdegrund och kultur. Programmet är byggt så att teamet skapar och förstärker sin egen säljkultur och tillsammans skapar en enad bild över deras ”spelregler”. Säljteamet tränar tillsammans i workshop form och lyfter upp best practise som är hållbar.",
      filterWord: "education"
    },
    {
      title: "Vinnande försäljning",
      intro: "Det här programmet är designat för att utveckla säljteamets egna driv med utgångspunkt i bolagets värdegrund och kultur. Programmet är byggt så att teamet skapar och förstärker sin egen säljkultur och tillsammans skapar en enad bild över deras ”spelregler”. Säljteamet tränar tillsammans i workshop form och lyfter upp best practise som är hållbar.",
      filterWord: "education"
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (inFocus !== 0 && window.innerWidth < 900) {
      window.scrollTo({ top: window.innerHeight * 0.4 })
    }
  }, [inFocus])
  var { pageContent, languageSE } = useContext(AppContext);

  const smallCourse = (intro) => {
    return (
      <div className="services-info-text">{intro}</div>
    )
  }

  const contactButton = () => {
    return (
      <div className="services-contact-button" onClick={() => {
        history.push("/contact");
      }}>{pageContent.sectionTwoContactBtn}</div>
    )
  }

  const largeHPC = () => {

    return (
      <div className="services-content-large">
        <div className="content-sub-title">{pageContent.contentOne001}</div>
        <div className="content-sub-title">{pageContent.contentOne002}</div>
        <div className="content-running-text">{pageContent.contentOne003}</div>
        <br />
        <div className="content-running-text">{pageContent.contentOne004}</div>
        <br />
        <div className="content-running-text">{pageContent.contentOne005}</div>
        <ul>
          <li>{pageContent.contentOne006}</li>
          <li>{pageContent.contentOne007}</li>
          <li>{pageContent.contentOne008}</li>
          <li>{pageContent.contentOne009}</li>
          <li>{pageContent.contentOne010}</li>
        </ul>
        <br />
        <div className="content-sub-title">{pageContent.contentOne011}</div>
        <div>{pageContent.contentOne012}</div>
        <br />
        <div className="content-bold">{pageContent.contentOne013}</div>
        <br />
        <div className="content-running-text">{pageContent.contentOne014}</div>
        <br />
        <div className="content-bold">{pageContent.contentOne015}</div>

        <div>
          <span className="content-bold">{pageContent.contentOne016}</span>{" "}
          {pageContent.contentOne017}
        </div>
        <br />
        <div className="content-sub-information">
          {pageContent.contentOne023}
        </div>
        <a
          href={languageSE ? strategiSession : strategiSessionEN}
          download
          rel="noopener noreferrer"
        >
          <div className="button-download button-download-coach">
            {pageContent.contentOne024}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.803"
              height="17.991"
              viewBox="0 0 13.803 17.991"
              id="svg-download"
            >
              <g
                id="Group_179"
                data-name="Group 179"
                transform="translate(23981.501 13339.522)"
              >
                <line
                  id="Line_14"
                  data-name="Line 14"
                  y2="10.914"
                  transform="translate(-23974.598 -13338.522)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_15"
                  data-name="Line 15"
                  x1="11.803"
                  transform="translate(-23980.5 -13322.531)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_93"
                  data-name="Path 93"
                  d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                  transform="translate(-1.042 -6.535)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </div>
          <div className="services-video-container">
          {languageSE ? <iframe  src="https://www.youtube-nocookie.com/embed/45Snx6FAOi8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> : 
          <iframe  src="https://www.youtube-nocookie.com/embed/Zzibld-4_8E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> }
        </div>
        </a>
        {contactButton()}
      </div>
    )
  }

  const largeCLC = () => {
    return (
      <div className="services-content-large">

        <div className="content-sub-title">{pageContent.contentOne019}</div>
        <div className="content-running-text">{pageContent.contentOne020}</div>
        <br />
        <div className="content-quote">{pageContent.contentOne021}</div>
        <div className="content-quote-auth">{pageContent.contentOne022}</div>
        <br />
        <div className="content-sub-information">
          {pageContent.contentOne023}
        </div>
        <a
          href={languageSE ? strategiSession : strategiSessionEN}
          download
          rel="noopener noreferrer"
        >
          <div className="button-download button-download-coach">
            {pageContent.contentOne024}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.803"
              height="17.991"
              viewBox="0 0 13.803 17.991"
              id="svg-download"
            >
              <g
                id="Group_179"
                data-name="Group 179"
                transform="translate(23981.501 13339.522)"
              >
                <line
                  id="Line_14"
                  data-name="Line 14"
                  y2="10.914"
                  transform="translate(-23974.598 -13338.522)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_15"
                  data-name="Line 15"
                  x1="11.803"
                  transform="translate(-23980.5 -13322.531)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_93"
                  data-name="Path 93"
                  d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                  transform="translate(-1.042 -6.535)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </div>
          <div className="services-video-container">
          {languageSE ? <iframe src="https://www.youtube-nocookie.com/embed/45Snx6FAOi8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> : 
          <iframe  src="https://www.youtube-nocookie.com/embed/Zzibld-4_8E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> }
        </div>
        </a>
        {contactButton()}
      </div>
    )
  }

  const largeEYE = () => {
    return (
      <div className="services-content-large">
        <div className="content-running-text">{pageContent.service01text01}</div>
        <br />
        <div className="content-sub-title">{pageContent.service01text02}</div>
        <div className="content-running-text">{pageContent.service01text03}</div>
        <br />
        <div className="content-sub-title">{pageContent.service01text04}</div>
        <div className="content-running-text">{pageContent.service01text05}</div>
        <br />
        <div className="content-sub-title">{pageContent.service01text06}</div>
        <ul>
          <li>{pageContent.service01text07}</li>
          <li>{pageContent.service01text08}</li>
          <li>{pageContent.service01text09}</li>
          <li>{pageContent.service01text10}</li>
          <li>{pageContent.service01text11}</li>
        </ul>
        <br />
        <div className="content-sub-title">{pageContent.service01text12}</div>
        <div className="content-running-text">{pageContent.service01text13}</div>
        {contactButton()}
      </div>
    )
  }

  const largeWT = () => {
    return (
      <div className="services-content-large">
        <div className="content-running-text">{pageContent.service03text01}</div>
        <br />
        <div className="content-sub-title">{pageContent.service03text02}</div>
        <div className="content-running-text">{pageContent.service03text03}</div>
        <br />
        <div className="content-sub-title">{pageContent.service03text04}</div>
        <div className="content-running-text">{pageContent.service03text05}</div>
        <br />
        <div className="content-sub-title">{pageContent.service03text06}</div>
        <ul>
          <li>{pageContent.service03text07}</li>
          <li>{pageContent.service03text08}</li>
          <li>{pageContent.service03text09}</li>
          {languageSE ? <li>{pageContent.service03text10}</li> : null}
        </ul>
        <br />
        <div className="content-sub-title">{pageContent.service03text11}</div>
        <div className="content-running-text">{pageContent.service03text12}</div>
        <br />
        <div className="content-list-extra">{pageContent.service03text13}</div>
        <div className="content-running-text">{pageContent.service03text14}</div>
        <div className="content-running-text">{pageContent.service03text15}</div>
        {contactButton()}
      </div>
    )
  }

  const largeWS = () => {
    return (
      <div className="services-content-large">
        <div className="content-running-text">{pageContent.service02text01}</div>
        <br />
        <div className="content-sub-title">{pageContent.service02text02}</div>
        <div className="content-running-text">{pageContent.service02text03}</div>
        <br />
        <div className="content-sub-title">{pageContent.service02text04}</div>
        <div className="content-running-text">{pageContent.service02text05}</div>
        <br />
        <div className="content-sub-title">{pageContent.service02text06}</div>
        <ul>
          <li>{pageContent.service02text07}</li>
          <li>{pageContent.service02text08}</li>
          <li>{pageContent.service02text09}</li>
          <li>{pageContent.service02text10}</li>
          {languageSE ? null : <li>{pageContent.service02text10extra}</li>}
        </ul>
        <br />
        <div className="content-sub-title">{pageContent.service02text11}</div>
        <div className="content-running-text">{pageContent.service02text12}</div>
        <br />
        <div className="content-list-extra">{pageContent.service02text13}</div>
        <div className="content-running-text">{pageContent.service02text14}</div>
        <div className="content-running-text">{pageContent.service02text15}</div>
        {contactButton()}
      </div>
    )
  }

  const largeCLW = () => {
    return (
      <div className="services-content-large">

        <div className="content-sub-title">{pageContent.contentThree002}</div>
        <br />
        <div className="content-sub-title">{pageContent.contentThree003}</div>

        <div class-name="content-quote">{pageContent.contentThree004}</div>
        <br />
        <div className="content-sub-title">{pageContent.contentThree005}</div>

        <div className="content-runnig-text">{pageContent.contentThree006}</div>
        <br />
        <div className="content-running-text">{pageContent.service04text01}</div>
        <br />
        <div className="services-ws-container">

          <div className="services-ws-day-container">
            <div className="content-main-title">{pageContent.service04text02}</div>
            <div className="content-sub-title">{pageContent.service04text03}</div>
            <ul>
              <li>{pageContent.service04text04}</li>
              <li>{pageContent.service04text05}</li>
              <li>{pageContent.service04text06}</li>
              <li>{pageContent.service04text07}</li>
            </ul>
          </div>
          <div className="services-ws-day-container">
            <div className="content-main-title">{pageContent.service04text08}</div>
            <div className="content-sub-title">{pageContent.service04text09}</div>
            <ul>
              <li>{pageContent.service04text10}</li>
              <li>{pageContent.service04text11}</li>
              <li>{pageContent.service04text12}</li>
              <li>{pageContent.service04text13}</li>
            </ul>
            <ul>
              <li>{pageContent.service04text14}</li>
              <li>{pageContent.service04text15}</li>
            </ul>
            <ul>
              <li>{pageContent.service04text16}</li>
              <li>{pageContent.service04text17}</li>
            </ul>
          </div>
          <div className="services-ws-day-container">
            <div className="content-main-title">{pageContent.service04text18}</div>
            <div className="content-sub-title">{pageContent.service04text19}</div>
            <div className="content-sub-title">{pageContent.service04text20}</div>
            <br />
            <ul>
              <li>{pageContent.service04text21}</li>
              <ul>
                <li>{pageContent.service04text22}</li>
                <li>{pageContent.service04text23}</li>
              </ul>
            </ul>
          </div>
          <div className="services-ws-day-container">
            <div className="content-main-title">{pageContent.service04text24}</div>
            <div className="content-sub-title">{pageContent.service04text25}</div>
            <br />
            <div className="content-running-text">{pageContent.service04text26}</div>
          </div>
          <div className="services-ws-day-container">
            <div className="content-main-title">{pageContent.service04text27}</div>
            <div className="content-sub-title">{pageContent.service04text28}</div>
            <br />
            <ul>
              <li>{pageContent.service04text29}</li>
              <li>{pageContent.service04text30}</li>
            </ul>
          </div>
          <div className="services-ws-day-container">
            <div className="content-main-title">{pageContent.service04text31}</div>
            <div className="content-sub-title">{pageContent.service04text32}</div>
            <ul>
              <li>{pageContent.service04text33}</li>
            </ul>
            <ul>
              <li>{pageContent.service04text34}</li>
              <ul>
                <li>{pageContent.service04text35}</li>
                <li>{pageContent.service04text36}</li>
              </ul>
              <li>{pageContent.service04text37}</li>
            </ul>
          </div>
        </div>
        <a
          href={languageSE ? ettCoachandeLedarskap : ettCoachandeLedarskapEN}
          download
          rel="noopener noreferrer"
        >
          <div className="button-download-three services-download-button">
            {pageContent.contentTwoDownloadPdf}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.803"
              height="17.991"
              viewBox="0 0 13.803 17.991"
              className="svg-download-three"
            >
              <g
                id="Group_179"
                data-name="Group 179"
                transform="translate(23981.501 13339.522)"
              >
                <line
                  id="Line_14"
                  data-name="Line 14"
                  y2="10.914"
                  transform="translate(-23974.598 -13338.522)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_15"
                  data-name="Line 15"
                  x1="11.803"
                  transform="translate(-23980.5 -13322.531)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_93"
                  data-name="Path 93"
                  d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                  transform="translate(-1.042 -6.535)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </div>
        </a>

        <br />
        <div className="content-bold">{pageContent.contentThree009}</div>
        <br />
        {contactButton()}
      </div>
    )
  }

  const largeWSW = () => {
    return (
      <div className="services-content-large">

        <div className="content-sub-title">{pageContent.contentThree002}</div>
        <br />
        <div className="content-sub-title">{pageContent.contentThree003}</div>

        <div class-name="content-quote">{pageContent.contentThree004}</div>
        <br />
        <div className="content-sub-title">{pageContent.contentThree005}</div>

        <div className="content-runnig-text">{pageContent.contentThree006}</div>
        <br />
        <div className="content-running-text">{pageContent.service05text01}</div>
        <br />
        <div className="services-ws-container">

          <div className="services-ws-day-container">
            <div className="content-main-title">{pageContent.service05text02}</div>
            <div className="content-sub-title">{pageContent.service05text03}</div>
            <ul>
              <li>{pageContent.service05text04}</li>
              <li>{pageContent.service05text05}</li>
              <li>{pageContent.service05text06}</li>
              <li>{pageContent.service05text07}</li>
            </ul>
            <div className="services-ws-day-session-container">
              {pageContent.service05text08}
            </div>
          </div>

          <div className="services-ws-day-container">
            <div className="content-main-title">{pageContent.service05text09}</div>
            <div className="content-sub-title">{pageContent.service05text10}</div>
            <div className="content-sub-title">{pageContent.service05text11}</div>
            <br />
            <div className="content-running-text">{pageContent.service05text12}</div>
            <div className="services-ws-day-session-container">
              {pageContent.service05text13}
            </div>
          </div>
          <div className="services-ws-day-container">
            <div className="content-main-title">{pageContent.service05text14}</div>
            <div className="content-sub-title">{pageContent.service05text15}</div>
            <div className="content-sub-title">{pageContent.service05text16}</div>
            <br />
            <div className="content-running-text">{pageContent.service05text17}</div>
            <div className="services-ws-day-session-container">
              {pageContent.service05text18}
            </div>
          </div>
          <div className="services-ws-day-container">
            <div className="content-main-title">{pageContent.service05text19}</div>
            <div className="content-sub-title">{pageContent.service05text20}</div>
            <br />
            <div className="content-running-text">{pageContent.service05text21}</div>
            <ul>
              <li>{pageContent.service05text22}</li>
              <li>{pageContent.service05text23}</li>
              <li>{pageContent.service05text24}</li>
              <li>{pageContent.service05text25}</li>
            </ul>
            <div className="content-running-text">{pageContent.service05text26}</div>

          </div>
          <div className="services-ws-day-container">
            <div className="content-main-title">{pageContent.service05text27}</div>
            <div className="content-sub-title">{pageContent.service05text28}</div>
            <ul>
              <li>{pageContent.service05text28}</li>
            </ul>
            <ul>
              <li>{pageContent.service05text29}</li>
              <ul>
                <li>{pageContent.service05text30}</li>
                <li>{pageContent.service05text31}</li>
              </ul>
              <li>{pageContent.service05text32}</li>
              <li>{pageContent.service05text33}</li>
            </ul>
          </div>
        </div>
        <a
          href={languageSE ? vinnandeFörsäljning : vinnandeFörsäljningEN}
          download
          rel="noopener noreferrer"
        >
          <div className="button-download-three services-download-button">
            {pageContent.contentTwoDownloadPdf}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.803"
              height="17.991"
              viewBox="0 0 13.803 17.991"
              className="svg-download-three"
            >
              <g
                id="Group_179"
                data-name="Group 179"
                transform="translate(23981.501 13339.522)"
              >
                <line
                  id="Line_14"
                  data-name="Line 14"
                  y2="10.914"
                  transform="translate(-23974.598 -13338.522)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_15"
                  data-name="Line 15"
                  x1="11.803"
                  transform="translate(-23980.5 -13322.531)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_93"
                  data-name="Path 93"
                  d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                  transform="translate(-1.042 -6.535)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </div>
        </a>

        <br />
        {contactButton()}
      </div>
    )
  }

  const largeValueBasedSales = () => {
    const content = [
      [[`Värde-Baserad Försäljning`],[`Value-Based Selling`]],
    [[`Behov & Argumentation`],[`Questions & Argumentation`]],
    [[`Effektiv Säljplanering & Målstyrning`],[`Effective Sales planning & Goalsteering`]],
    [[`Presentationsteknik`],[`Presentations Technique`]],
    [[`Avslut & Förhandling`],[`Closure & Negotiation`]],
    [[`Diplomeringsdag`],[`Certification Day`]],]
    return (
      <div className="services-content-large">

        <div className="services-vbs-title">{languageSE ? vbsText[2] : vbsText[3]}</div>
        <div className="content-running-text">{languageSE ? vbsText[0] : vbsText[1]}</div>

        <div className="services-vbs-section-container">
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[0][0] : content[0][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 1`: `Day 1`}</div>
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[1][0] : content[1][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 2`: `Day 2`}</div>
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[2][0] : content[2][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 3`: `Day 3`}</div>
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[3][0] : content[3][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 4`: `Day 4`}</div>
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[4][0] : content[4][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 5`: `Day 5`}</div>
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[5][0] : content[5][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 6`: `Day 6`}</div>
          </div>
        </div>
        <div className="services-video-container">
          {languageSE ? <iframe   src="https://www.youtube-nocookie.com/embed/kovBg1cLcTI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> : null }
        </div>
        
        
        <a
          href={languageSE ? värdebaseradförsäljning : värdebaseradförsäljningEN}
          download
          rel="noopener noreferrer"
        >
          <div className="button-download-three services-download-button">
            {pageContent.contentTwoDownloadPdf}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.803"
              height="17.991"
              viewBox="0 0 13.803 17.991"
              className="svg-download-three"
            >
              <g
                id="Group_179"
                data-name="Group 179"
                transform="translate(23981.501 13339.522)"
              >
                <line
                  id="Line_14"
                  data-name="Line 14"
                  y2="10.914"
                  transform="translate(-23974.598 -13338.522)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_15"
                  data-name="Line 15"
                  x1="11.803"
                  transform="translate(-23980.5 -13322.531)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_93"
                  data-name="Path 93"
                  d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                  transform="translate(-1.042 -6.535)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </div>
        </a>

        <br />
        {contactButton()}
      </div>
    )
  }

  const largeValueBasedLeadership = () => {
    const content = [
      [[`Värde-Baserad Ledarskapskultur`],[`Value-Based Leadership culture`]],
    [[`Framgångsrikt Ledarfokus`],[`Successful Focalpoints`]],
    [[`Effektiv Coaching`],[`Effective Coaching`]],
    [[`Målstyrning & Feedback`],[`Goalsteering & Feedback`]],
    [[`Presentations- & Argumentationsteknik`],[`Presentations & Argumentation Techniques`]],
    [[`Diplomeringsdag`],[`Certification Day`]],]
    return (
      <div className="services-content-large">

        <div className="services-vbs-title">{languageSE ? vblText[2] : vblText[3]}</div>
        <div className="content-running-text">{languageSE ? vblText[0] : vblText[1]}</div>

        <div className="services-vbs-section-container">
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[0][0] : content[0][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 1`: `Day 1`}</div>
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[1][0] : content[1][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 2`: `Day 2`}</div>
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[2][0] : content[2][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 3`: `Day 3`}</div>
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[3][0] : content[3][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 4`: `Day 4`}</div>
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[4][0] : content[4][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 5`: `Day 5`}</div>
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[5][0] : content[5][1]}</div>
            <div className="services-vbs-section-day">{languageSE ? `Dag 6`: `Day 6`}</div>
          </div>
        </div>
        <div className="services-video-container">
          {languageSE ? 
          <iframe   src="https://www.youtube-nocookie.com/embed/0VGBaatSU6A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> 
          : null }
        </div>
        
        
        <a
          href={languageSE ? värdebaseradledarskap : värdebaseradledarskapEN}
          download
          rel="noopener noreferrer"
        >
          <div className="button-download-three services-download-button">
            {pageContent.contentTwoDownloadPdf}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.803"
              height="17.991"
              viewBox="0 0 13.803 17.991"
              className="svg-download-three"
            >
              <g
                id="Group_179"
                data-name="Group 179"
                transform="translate(23981.501 13339.522)"
              >
                <line
                  id="Line_14"
                  data-name="Line 14"
                  y2="10.914"
                  transform="translate(-23974.598 -13338.522)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_15"
                  data-name="Line 15"
                  x1="11.803"
                  transform="translate(-23980.5 -13322.531)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_93"
                  data-name="Path 93"
                  d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                  transform="translate(-1.042 -6.535)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </div>
        </a>

        <br />
        {contactButton()}
      </div>
    )
  }

  const largeValueBasedCulture = () => {
    const content = [
      [[`Organisationskultur`],[`Organizations Culture`]],
    [[`Karaktärsmatrisen`],[`Character Matrix`]],
    [[`Fyra S`],[`Four S's`]],
    [[`Samma färg på Tröjorna`],[`Same color of the shirts`]],
    [[`Attitydindikator`],[`Attitude Indicator`]],
    [[`Värdelinjen`],[`Value Line`]],]
    return (
      <div className="services-content-large">

        <div className="services-vbs-title">{languageSE ? vbcText[2] : vbcText[3]}</div>
        <div className="content-running-text">{languageSE ? vbcText[0] : vbcText[1]}</div>

        <div className="services-vbs-section-container">
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[0][0] : content[0][1]}</div>
            
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[1][0] : content[1][1]}</div>
            
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[2][0] : content[2][1]}</div>
            
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[3][0] : content[3][1]}</div>
            
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[4][0] : content[4][1]}</div>
            
          </div>
          <div className="services-vbs-arrow">{`⇨`}</div>
          <div className="services-vbs-section-content">
            <div>{languageSE ? content[5][0] : content[5][1]}</div>
            
          </div>
        </div>
        <div className="services-video-container">
          {languageSE ? 
          <iframe   src="https://www.youtube-nocookie.com/embed/63Kr7w9umjE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          : null }
        </div>
        
        
        <a
          href={languageSE ? värdebaseradkultur : värdebaseradkulturEN}
          download
          rel="noopener noreferrer"
        >
          <div className="button-download-three services-download-button">
            {pageContent.contentTwoDownloadPdf}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.803"
              height="17.991"
              viewBox="0 0 13.803 17.991"
              className="svg-download-three"
            >
              <g
                id="Group_179"
                data-name="Group 179"
                transform="translate(23981.501 13339.522)"
              >
                <line
                  id="Line_14"
                  data-name="Line 14"
                  y2="10.914"
                  transform="translate(-23974.598 -13338.522)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_15"
                  data-name="Line 15"
                  x1="11.803"
                  transform="translate(-23980.5 -13322.531)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_93"
                  data-name="Path 93"
                  d="M-23977.957-13323.91l4.291,4.291,4.291-4.291"
                  transform="translate(-1.042 -6.535)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </div>
        </a>

        <br />
        {contactButton()}
      </div>
    )
  }




  var coursesContent = (largContent, courseNumber, img, title, intro) => {
    return (
      (inFocus == 0 || inFocus == courseNumber) && (currentFilter == courseContet[courseNumber - 1].filterWord || currentFilter == "all") ?
        <div className={inFocus == courseNumber ? "services-content-container services-active" : "services-content-container"}>
          <div className={inFocus == courseNumber ? "services-image-container services-image-container-active" : "services-image-container"}>
            <img src={img} className="services-images" />
            <div className={inFocus == courseNumber ? "services-title services-title-active" : "services-title"}>{title}</div>
          </div>
          {inFocus == courseNumber ? largContent : smallCourse(intro)}
          {inFocus == courseNumber ? "" : <div className="services-info-text-fade" />}
          <div className={inFocus != 0 ? " services-read-more-button services-read-more-button-active" : "services-read-more-button"} onClick={() => { if (inFocus != courseNumber) { setInFocus(courseNumber) } else { setInFocus(0) } }}> {inFocus != 0 ? pageContent.servicesButton02 : pageContent.servicesButton01}</div>
        </div>
        : null
    )
  }



  //LINE 676  
  //<div className={currentFilter == "online" ? "services-category-text category-active" : "services-category-text"} onClick={()=>{setCurrentFilter("online"); setInFocus(0);}}>{pageContent.servicesFilter05}</div>

  return (
    <div>
      <div className="services-section-one">
        <div className="about-section-one-intro">
          <span className="about-first-word">{pageContent.servicesTitle01}</span>{" "}
          <span>{pageContent.servicesTitle02}</span>
        </div>
      </div>
      <div className="services-section-two">
        <div className="services-categories-container">
          <div className={currentFilter == "all" ? "services-category-text category-active" : "services-category-text"} onClick={() => { setCurrentFilter("all"); setInFocus(0); }}>{pageContent.servicesFilter01}</div>
          <div className={currentFilter == "education" ? "services-category-text category-active" : "services-category-text"} onClick={() => { setCurrentFilter("education"); setInFocus(0); }}>{languageSE ? `Utbildningar / Workshops`:`Education / Workshops`}</div>
          <div className={currentFilter == "inspiration" ? "services-category-text category-active" : "services-category-text"} onClick={() => { setCurrentFilter("inspiration"); setInFocus(0); }}>{pageContent.servicesFilter03}</div>
          <div className={currentFilter == "coaching" ? "services-category-text category-active" : "services-category-text"} onClick={() => { setCurrentFilter("coaching"); setInFocus(0); }}>{pageContent.servicesFilter02}</div>
          

        </div>
        <div className="services-container">
        {coursesContent(largeValueBasedSales(), 6, serviceThree, languageSE ? 'Värde-Baserad Försäljning':'Value-Based Selling' , languageSE ? vbsText[0] : vbsText[1])}
          {coursesContent(largeValueBasedLeadership(), 7, serviceThree, languageSE ? 'Värde-Baserat Ledarskap':'Value-Based Leadership' , languageSE ? vblText[0] : vblText[1])}
          {coursesContent(largeValueBasedCulture(), 8, serviceThree, languageSE ? 'Värde-Baserad Kultur':'Value-Based Culture' , languageSE ? vbcText[0] : vbcText[1])}
          {coursesContent(largeEYE(), 3, serviceTwo, pageContent.contentTwo003, pageContent.servicesIntro003)}
          {coursesContent(largeWT(), 4, serviceTwo, pageContent.contentTwo004, pageContent.servicesIntro004)}
          {coursesContent(largeWS(), 5, serviceTwo, pageContent.contentTwo006, pageContent.servicesIntro005)}
          {coursesContent(largeHPC(), 1, serviceOne, pageContent.contentOneMainTitle, pageContent.servicesIntro001)}
          {coursesContent(largeCLC(), 2, serviceOne, pageContent.contentOne018, pageContent.servicesIntro002)}

        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Services

/*
          //Old education
          {coursesContent(largeCLW(), 6, serviceThree, pageContent.contentThree008, pageContent.servicesIntro006)}
          {coursesContent(largeWSW(), 7, serviceThree, pageContent.contentThree010, pageContent.servicesIntro007)}

const [currentContent, setCurrentContet] = useState(0)

    useEffect(()=>{
        var triangle = document.getElementById("service-triangle")
        var b1 = document.getElementById("services-button-one")
        var b2 = document.getElementById("services-button-two")
        var b3 = document.getElementById("services-button-three")

        b1.classList.remove("services-button-one-active")
        b2.classList.remove("services-button-two-active")
        b3.classList.remove("services-button-three-active")

        if(currentContent == 0){
            if(window.innerWidth >= 900){triangle.style.gridColumn = "1/2"}
            triangle.style.borderBottomColor = "#d5dadd"
            b1.classList.add("services-button-one-active")
        }else if(currentContent == 1){
            if(window.innerWidth >= 900){triangle.style.gridColumn = "2/3"}
            triangle.style.borderBottomColor = "#dadde5"
            b2.classList.add("services-button-two-active")
        }else if(currentContent == 2){
            if(window.innerWidth >= 900){triangle.style.gridColumn = "3/-1"}
            triangle.style.borderBottomColor = "#e7dde9"
            b3.classList.add("services-button-three-active")
        }
    },[currentContent])


    function servicesContent(){
        if(currentContent == 2){
           return <div id="serviceContainerThree" className="service-three">
                    <ContentThree />
                </div>
        }
        else if(currentContent == 1){
            return <div id="serviceContainerTwo" className="service-two">
                        <ContentTwo />
                    </div>
        }else{
            return  <div id="serviceContainerOne" className="service-one">
                        <ContentOne />
                    </div>
        }
    }
    return (
        <div className="services-contanier">
            <div className="services-buttons-container">
                <div id="services-button-one" className="services-button-one services-button-default services-button-active-one" onClick={()=>{setCurrentContet(0)}}>Coaching</div>
                <div id="services-button-two" className="services-button-two services-button-default" onClick={()=>{setCurrentContet(1)}}>Inspirationsföreläsningar</div>
                <div id="services-button-three" className="services-button-three services-button-default" onClick={()=>{setCurrentContet(2)}}>Utbildning - workshop</div>

                <div id="service-triangle" className="services-triangle"/>
            </div>
            <div className="services-content-container">
                {servicesContent()}
            </div>

        </div>
    )


*/