import React, { useEffect, useContext } from "react";
import { AppContext } from "./AppContext";
import "./Contact.css";
import "./Contact-mobile.css";
import Footer from "./Footer";
import instagramIcon from "../images/instagramIcon.png"
import youtubeIcon from "../images/youtubeIcon.png"
import linkedinIcon from "../images/linkedinIcon.png"
function Contact() {
  var { languageSE } = useContext(AppContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="contact-section-one">
        
          <div className="contact-border">
            <div className="contact-name">Henrik Pettersson</div>
            <a href="mailto:henrik@starkaresultat.se">
              <div className="contact-email">henrik@starkaresultat.se</div>
            </a>
            <div className="contact-icon-container">
              <img src={instagramIcon} onClick={()=>{window.open("https://www.instagram.com/starkaresultatab")}}/>
              <img src={youtubeIcon} onClick={()=>{if(languageSE){window.open("https://www.youtube.com/playlist?list=PLt2EkjY5g3QXi_jizYRKMsXLwdJniNuFQ")}else{window.open("https://www.youtube.com/playlist?list=PLt2EkjY5g3QUJnHbAdA5HvJILTTz40TA1")}}}/>
              <img src={linkedinIcon} onClick={()=>{window.open("https://www.linkedin.com/in/henrik-pettersson-8ab8202")}}/>
            </div>
          </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
