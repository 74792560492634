import React from "react";

function IntroductionGPI(props) {
  return (
    <div className="quiz-intro-container">
      <div className="quiz-intro-title">
        Joy Preparation Indicator for Strong Results (JPI)
      </div>
      <div className="quiz-intro-instructions">
      The tool for identifying the parts that create joy, while finding out about your current situation and potential for improvement.

        For each area, add your self-assessment score (minimum 1, maximum 5). It will give you a score based on 100%.

      </div>
      <div
        className="quiz-intro-button"
        onClick={() => props.setQuizProgress(1)}
      >
        Start
      </div>
    </div>
  );
}

export default IntroductionGPI;
