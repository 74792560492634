import React, {useEffect, useState} from 'react'
import "./ScrollIndicator.css"

function ScrollIndicator() {

    const [removed, setRemoved] = useState(false)

    useEffect(()=>{
        document.addEventListener('scroll', scrollListener)
        //const scrollElement = document.getElementById("scroll-border")
        //document.getElementById("scroll-border").classList.add("fadeInBorder")
        
    })

    function scrollListener(){
        var scrollY = window.scrollY || window.pageYOffset;
        if(scrollY > 200 && !removed){
            setRemoved(true)
            document.removeEventListener('scroll', scrollListener)
            var element = document.getElementById("scroll-border")
            if(element !== null){
                element.classList.add("fadeOut")
            }
            
        }
    }
    return (
            <div id="scroll-border" className="scroll-border-class">
        <div id="scroll-dot"></div>
      </div>
    )
}

export default ScrollIndicator
