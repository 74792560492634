import React, { useEffect, useContext, useState } from "react";
import "./loading.css";
import { AppContext } from "./AppContext";

function Loading() {
  var { setOnAdminPage, loading } = useContext(AppContext);

  useEffect(() => {
    setOnAdminPage(true);
  }, []);

  useEffect(() => {
    if (loading) {
      setOnAdminPage(false);
    }
  }, [loading]);

  return (
    <div className="loading-container">
      <svg height="100" width="100" viewBox="0 0 250 250">
        <circle
          id="loading-circle-one"
          className="loading-circle"
          cx="125"
          cy="125"
          r="30"
        />
        <circle
          id="loading-circle-two"
          className="loading-circle"
          cx="125"
          cy="125"
          r="60"
        />
        <circle
          id="loading-circle-three"
          className="loading-circle"
          cx="125"
          cy="125"
          r="90"
        />
      </svg>
      <h3>Loading</h3>
    </div>
  );
}

export default Loading;
